import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useScreenContext, Option, OptionGridButton, relativePath, useStepNumberManager } from "../../Layout";
import { useNavigate, useParams } from "react-router-dom";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

function PriorityAreasQuestion() {
  const { t } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const navigate = useNavigate();
  const { offerType } = useParams();

  const isDirectSale = offerType === "direct";
  const isSlFunnel = offerType === "sl";

  type PriorityAreas = "emotions" | "motivation" | "nutrition" | "habits" | "self-confidence" | "mindset" | "self-care" | "exercise" | "empathy" | "love-and-relationships" | "personal-financy" | "creativity";

  const options = [
    {title: "emotions", value: 704},
    {title: "motivation", value: 214},
    {title: "nutrition", value: 94},
    {title: "habits", value: 388},
    {title: "self-confidence", value: 142},
    {title: "mindset", value: 158},
    {title: "self-care", value: 48},
    {title: "exercise", value: 88},
    {title: "empathy", value: 132},
    {title: "love-and-relationships", value: 475},
    {title: "personal-financy", value: 121},
    {title: "creativity", value: 182},
  ] as { title: string, value: number }[];

  const stepNumber = useStepNumberManager(isSlFunnel ? 11 : 13);

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: t("layout.cta_continue"),
      nextPath: relativePath("/protect-you", offerType),
      progress: stepNumber,
      step: stepNumber,
    }));
  }, []);

  
  const [selectedOptions, setSelectedOptions] = useState([] as { title: string, value: number }[]);
  const handleOptionSelection = (option: { title: string, value: number }) => {

    if (selectedOptions.some((item) => item.title === option.title)) {
      setSelectedOptions(selectedOptions.filter((item) => item.title !== option.title));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }

    // calc all values
    const totalValue = selectedOptions.reduce((acc, item) => acc + item.value, 0);

    setScreenData(() => ({
      ...screenData,
      userPreferences: {
        ...screenData.userPreferences,
        priorityAreas: selectedOptions.map((item) => item.title),
        priorityAreasTotal: totalValue,
      },
    }));
  };

  return (
    <div>
      <h1 className="mb-4 text-2xl font-bold tracking-tight text-gray-900  md:text-5xl">
        {t("priority_areas_question.headline")}
      </h1>
      <div className="mt-10 grid grid-cols-3 gap-2">
        {options.map((option) => (
          <OptionGridButton key={option.title} onClick={() => handleOptionSelection(option)}>
            {t(`priority_areas_question.options.${option.title}`)}
          </OptionGridButton>
        ))}
      </div>
    </div>
  );
}

export default PriorityAreasQuestion;
