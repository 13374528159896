import { useEffect, useState } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { useLocation } from "react-use";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import { useTranslation } from "react-i18next";

import "./index.css";
import * as rudderanalytics from "../lib/rudderstack";

import Layout from "./Layout";
import Start from "./steps/Start";
import Goals from "./steps/Goals";
import Age from "./steps/Age";
import Usage from "./steps/Usage";
import Time from "./steps/Time";
import ReadingStats from "./steps/ReadingStats";
import TimeSaved from "./steps/TimeSaved";
import Topics from "./steps/Topics";
import ThankYou from "./steps/ThankYou";
import BookSuggestion from "./steps/BookSuggestion";
import Info from "./steps/Info";
import Loading from "./steps/Loading";
import LoadingResult from "./steps/LoadingResult";
import BookList from "./steps/BookList";
import Form from "./steps/Form";
import data from "../data.json";
import SelectChallenge from "./steps/SelectChallenge";
import SelectPlan from "./steps/SelectPlan";
import SelectPlanB from "./steps/SelectPlanB";
import Landing from "./steps/Landing";
import TalkingAbout from "./steps/TalkingAbout";
import TalkingAbout2 from "./steps/TalkingAbout2";
import ReadingHabit from "./steps/ReadingHabit";
import Welcome from "./steps/Welcome";
import WelcomeExp from "./steps/WelcomeExp";
import ReadingList from "./steps/ReadingList";
import ReaderProfile from "./steps/ReaderProfile";
import DevelopmentPlan from "./steps/DevelopmentPlan";
import KnowledgeQuestion from "./steps/KnowledgeQuestion";
import KnowledgeInfo from "./steps/KnowledgeInfo";
import DescribesYouQuestion from "./steps/DescribesYouQuestion";
import DescribesYouInfo from "./steps/DescribesYouInfo";
import AppInstall from "./steps/AppInstall";

import GoalRequirementsQuestion from "./steps/additional-steps/GoalRequirementsQuestion";
import GoalRequirementsInfo from "./steps/additional-steps/GoalRequirementsInfo";
import PriorityAreasQuestion from "./steps/additional-steps/PriorityAreasQuestion";
import WeProtectYou from "./steps/additional-steps/WeProtectYou";

import SelectPlanExp15 from "./steps/SelectPlanExp15";
import SelectPlanV2 from "./steps/SelectPlanV2";
import GnederQuestion from "./steps/GenderQuestion";
import SelectPlanV3 from "./steps/SelectPlanV3";
import Product from "./steps/Product";
import SelectPlanExp30 from "./steps/SelectPlanExp30";
import SelectPlanPopup from "./steps/SelectPlanPopup";

// Super Leitura
import Format from "./steps/super-leitura/Format";
import ReadingStatsSl from "./steps/super-leitura/ReadingStats";
import TimeSavedSl from "./steps/super-leitura/TimeSaved";
import ReadingSpeed from "./steps/super-leitura/ReadingSpeed";
import BiggestDifficulty from "./steps/super-leitura/BiggestDifficulty";
import Target from "./steps/super-leitura/Target";
import LoadingResultSl from "./steps/super-leitura/LoadingResult";
import AboutSl from "./steps/super-leitura/About";
// import SelectPlanSl from "./steps/super-leitura/SelectPlanSl";
import SelectPlanSl from "./steps/super-leitura/SelectPlan";
import LandingB from "./steps/LandingB";
import LandingBRegular from "./steps/LandingBRegular";
import LandingBEmailCh from "./steps/LandingBEmailCh";
import LandingBEmailFr from "./steps/LandingBEmailFr";
import LandingTikTok from "./steps/landings/Tiktok";

// Create a GrowthBook instance
const gb = new GrowthBook({
  apiHost: "https://gb-api.12min.com",
  clientKey: "sdk-4j1rIv8De7MKa91",
  // Enable easier debugging during development
  enableDevMode: true,
  // Update the instance in realtime as features change in GrowthBook
  subscribeToChanges: true,
  // Only required for A/B testing
  // Called every time a user is put into an experiment
  trackingCallback: (experiment, result) => {
    rudderanalytics.experimentViewed(experiment.key, result.key);
  },
});

async function loader() {
  return data;
}

const router = createBrowserRouter([
  {
    path: "/:offerType?",
    element: <Layout />,
    loader: loader,
    children: [
      {
        index: true,
        element: <Navigate to="start" />,
      },
      {
        path: "start",
        element: <Start />,
      },
      {
        path: "reading-habit",
        element: <ReadingHabit />,
      },
      {
        path: "welcome",
        element: <Welcome />,
      },
      {
        path: "welcome-exp",
        element: <WelcomeExp />,
      },
      {
        path: "goals",
        element: <Goals />,
      },
      {
        path: "age",
        element: <Age />,
      },
      {
        path: "usage",
        element: <Usage />,
      },
      {
        path: "time",
        element: <Time />,
      },
      {
        path: "reading-stats",
        element: <ReadingStats />,
      },
      {
        path: "time-saved",
        element: <TimeSaved />,
      },
      {
        path: "topics",
        element: <Topics />,
      },
      {
        path: "thank-you",
        element: <ThankYou />,
      },
      {
        path: "book-suggestion/:bookId",
        element: <BookSuggestion />,
      },
      {
        path: "info",
        element: <Info />,
      },
      {
        path: "loading",
        element: <Loading />,
      },
      {
        path: "loading-result",
        element: <LoadingResult />,
      },
      {
        path: "book-list",
        element: <BookList />,
      },
      {
        path: "form",
        element: <Form />,
      },
      {
        path: "select-challenge",
        element: <SelectChallenge />,
      },
      {
        path: "select-plan",
        element: <SelectPlan />,
      },
      {
        path: "landing",
        element: <Landing />,
      },
      {
        path: "talking-about",
        element: <TalkingAbout />,
      },
      {
        path: "talking-about-2",
        element: <TalkingAbout2 />,
      },
      {
        path: "select-plan-b",
        element: <SelectPlanB />,
      },
      {
        path: "reading-list/:category",
        element: <ReadingList />,
      },
      {
        path: "reader-profile",
        element: <ReaderProfile />,
      },
      {
        path: "development-plan",
        element: <DevelopmentPlan />,
      },
      {
        path: "knowledge-question",
        element: <KnowledgeQuestion />,
      },
      {
        path: "knowledge-info",
        element: <KnowledgeInfo />,
      },
      {
        path: "describes-question",
        element: <DescribesYouQuestion />,
      },
      {
        path: "describes-info",
        element: <DescribesYouInfo />,
      },
      {
        path: "app-install",
        element: <AppInstall />,
      },

      // Additional steps
      {
        path: "goal-requirements",
        element: <GoalRequirementsQuestion />,
      },
      {
        path: "goal-requirements-info",
        element: <GoalRequirementsInfo />,
      },
      {
        path: "priority-areas",
        element: <PriorityAreasQuestion />,
      },
      {
        path: "protect-you",
        element: <WeProtectYou />,
      },
      {
        path: "landing-b",
        element: <LandingB />,
      },
      {
        path: "landing-b-regular",
        element: <LandingBRegular />,
      },
      {
        path: "landing-b-email-ch",
        element: <LandingBEmailCh />,
      },
      {
        path: "landing-b-email-fr",
        element: <LandingBEmailFr />,
      },
      {
        path: "tiktok",
        element: <LandingTikTok />,
      },

      // Only Experiments
      {
        path: "select-plan-vip",
        element: <SelectPlanExp15 />,
      },
      {
        path: "select-plan-v2",
        element: <SelectPlanV2 />,
      },
      {
        path: "gender",
        element: <GnederQuestion />,
      },
      {
        path: "select-plan-v3",
        element: <SelectPlanV3 />,
      },
      {
        path: "product",
        element: <Product />,
      },
      {
        path: "select-plan-semi",
        element: <SelectPlanExp30 />,
      },
      {
        path: "select-plan-popup",
        element: <SelectPlanPopup />,
      },

      // Super Leitura
      {
        path: "format",
        element: <Format />,
      },
      {
        path: "reading-stats-sl",
        element: <ReadingStatsSl />,
      },
      {
        path: "time-saved-sl",
        element: <TimeSavedSl />,
      },
      {
        path: "reading-speed",
        element: <ReadingSpeed />,
      },
      {
        path: "biggest-difficulty",
        element: <BiggestDifficulty />,
      },
      {
        path: "target",
        element: <Target />,
      },
      {
        path: "loading-result-sl",
        element: <LoadingResultSl />,
      },
      {
        path: "about-sl",
        element: <AboutSl />,
      },
      {
        path: "select-plan-sl",
        element: <SelectPlanSl />,
      },
    ]
  }
]);

const funnels = (href: string) => {
  if (href.includes("/direct")) return "wizard-direct";
  if (href.includes("/ret")) return "wizard-ret";
  if (href.includes("/app")) return "wizard-app";
  if (href.includes("/lt")) return "wizard-lt";
  if (href.includes("/ltf")) return "wizard-ltf";
  if (href.includes("/yt")) return "wizard-yt";
  if (href.includes("/tw")) return "wizard-tw";
  if (href.includes("/appf")) return "wizard-appf";
  if (href.includes("/sl")) return "wizard-sl";

  return "wizard-trial";
}

function App() {

  const { t, i18n } = useTranslation();
  const anonymousId = rudderanalytics.getAnonymousId();
  const currentUrl = useLocation().href;
  const currentFunnel = funnels(window.location.href);
  const wizardV2 = useFeatureIsOn("wizard-v2-feature");
  const [redirectExp, setRedirectExp] = useState(false);

  const appsTitleList: { [key: string]: string } = {
    "wizard-direct": t('app_title'),
    "wizard-trial": t('app_title'),
    "wizard-ret": t('app_title') + "-2",
    "wizard-app": t('app_title') + "-3",
    "wizard-sl": t('app_title') + " - Super Leitura",
  }

  const appTitle = appsTitleList[currentFunnel];

  useEffect(() => {
    const currentFunnel = new URLSearchParams(window.location.search).get("utm_funnel");
    if (currentFunnel) {
      sessionStorage.setItem("currentFunnel", currentFunnel);
    }

    // Load features from the GrowthBook API and initialize the SDK
    gb.loadFeatures();
    setInterval(() => {
      setRedirectExp(true);
    }, 2000);
  }, []);

  useEffect(() => {
    // Set user attributes for targeting (from cookie, auth system, etc.)
    gb.setAttributes({
      anonymousId: anonymousId,
      funnel: currentFunnel,
      language: i18n.language,
      currentUrl: currentUrl,
      utmSource: new URLSearchParams(window.location.search).get("utm_source"),
    });
  }, [anonymousId, currentFunnel, i18n.language, currentUrl]);

  if (wizardV2) {
    if (redirectExp) { window.location.href = "https://wizard-v2.12min.com/?lng=pt&direct=true" }
    return <></>;
  }

  return (
    <HelmetProvider>
      <GrowthBookProvider growthbook={gb}>
        <Helmet>
          <title>{appTitle}</title>
        </Helmet>
        <RouterProvider router={router} />
      </GrowthBookProvider>
    </HelmetProvider>
  );
}

export default App;
