import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import MascotReading from "../../assets/images/mascot-reading.png";
import MascotLeader from "../../assets/images/mascot-leader.png";
import MascotThinking from "../../assets/images/mascot-thinking.png";
import { useScreenContext, relativePath, useStepNumberManager } from "../Layout";
import { useNavigate, useParams } from "react-router-dom";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

type DescribesYouAnswer = "leader" | "follower" | "both";

function DescribesYouInfo() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [screenData, setScreenData] = useScreenContext();
  const { offerType } = useParams();

  const isDirectSale = offerType === "direct";
  const isRetSale = offerType === "ret";

  const answer: DescribesYouAnswer = screenData.userPreferences.describesYouAnswer;

  if (!answer) {
    const knowledgePath = relativePath("/knowledge-question", offerType);
    navigate(knowledgePath);
  }

  const images = {
    leader: MascotLeader,
    follower: MascotReading,
    both: MascotThinking,
  };

  const stepNumber = useStepNumberManager(10);
  
  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: t("layout.cta_continue"),
      nextPath: "goal-requirements",
      progress: stepNumber,
      step: stepNumber,
      votting: false,
    }));
  }, []);

  return (
    <main className="flex flex-col h-full justify-between gap-6">
      <header>
        <h1 className="mx-5 mb-4 mt-5 text-2xl font-bold tracking-tight text-gray-900  md:text-4xl">
        {t(`describes_you_info.headline.${answer}`)}
        </h1>
      </header>
      <img
        className="mx-auto w-full max-w-sm px-10"
        alt="Chart showing your progress"
        src={images[answer]}
      />
    </main>
  );
}

export default DescribesYouInfo;
