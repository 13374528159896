import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useSessionStorage } from "react-use";
import { useTimer } from "react-timer-hook";

import { useScreenContext, PlanData } from "../../Layout";
import * as rudderanalytics from "../../../lib/rudderstack";
import "./SelectPlan.css";

function TimerBox({text}: {text?: string}) {
  const time = new Date();
  time.setSeconds(time.getSeconds() + 833);

  const { seconds, minutes, hours } = useTimer({ expiryTimestamp: time });

  return (
    <div>
      <div className="inline-block bg-red-600 p-2 font-bold text-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="inline-block h-6 w-6 pr-1"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        {hours.toString().padStart(2, "0")}:
        {minutes.toString().padStart(2, "0")}:
        {seconds.toString().padStart(2, "0")}
      </div>
      {text ? <p>{text}</p> : <p>Oferta por tempo limitado</p>}
    </div>
  );
}

function TimeCounter() {
  const time = new Date();
  time.setSeconds(time.getSeconds() + 833);

  const { seconds, minutes, hours } = useTimer({ expiryTimestamp: time });

  return (
    <>
      {hours.toString().padStart(2, "0")}:
      {minutes.toString().padStart(2, "0")}:
      {seconds.toString().padStart(2, "0")}
    </>
  );
}

const OpenIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="h-6 w-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
    />
  </svg>
);

const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="h-6 w-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.5 15.75l7.5-7.5 7.5 7.5"
    />
  </svg>
);

const converObjectToParams = (object: any) => {
  return Object.keys(object)
    .filter((key) => object[key])
    .map((key) => {
      return `${key}=${encodeURIComponent(object[key])}`;
    })
    .join("&");
};

function SelectPlan() {
  const { t, i18n } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const [showOthersPlans, setShowOthersPlans] = useState<boolean>(false);
  const [selectedPlan, setselectedPlan] = useState<string>("superleitura");
  const [currentFaq, setCurrentFaq] = useState<number>(0);

  const bestPriceState = useState<boolean>(false);
  const [bestPrice, setBestPrice] = bestPriceState;
  const modalState = useState(false);
  const [showModal, setShowModal] = modalState;

  const { offerType } = useParams();
  const [flowStrategy, setFlowStrategy] = useSessionStorage(
    "flow-strategy",
    ""
  );

  const isPostLeadFlowStrategy = flowStrategy === "lead-post-plan-selection";
  const [variant, _] = useSessionStorage("variant");

  const isSlFunnel = offerType === "sl";

  const activePlan = selectedPlan === "superleitura";

  const checkoutIdListBR: Record<string, string> = {
    sl: "W72723439C",
  };

  const checkoutIdListMonthlyBR: Record<string, string> = {
    direct: "P5327674P",
    ret: "P5327674P",
    lt: "L49428651O",
    ltf: "L49428651O",
    yt: "P5327674P",
    tw: "P5327674P",
    trial: "P5327674P",
  };

  const checkoutId: any = {
    br: activePlan && checkoutIdListBR[offerType || ""],
    en: activePlan && checkoutIdListBR[offerType || ""],
    es: activePlan && checkoutIdListBR[offerType || ""],
  }[i18n.language];

  const offerCodeList: Record<string, string> = {
    sl: "y85591pw",
  };

  const offersTrial: any = {
    discounts: {
      base: "63OFF7D",
    },
    P5327674P: {
      yearly: { offerCode: "2zki1nl0" },
      monthly: { offerCode: "jx5nh72y" },
    },
  };

  const offersSlSale: any = {
    discounts: {
      base: "",
    },
    W72723439C: {
      superleitura: { offerCode: "y85591pw" },
    },
  };



  const offersOptions: any = () => {
    if (isSlFunnel) return offersSlSale;
    return offersTrial;
  };

  const offers: any = offersOptions();
  const currentCheckout = offers[checkoutId];

  interface CheckoutPopupLinks {
    default: string;
    bestPrice: string;
    monthly: string;
  }

  const buildCheckoutLink = () => {
    const baseUrl = `https://pay.hotmart.com/${checkoutId}`;
    const urlParams = !isPostLeadFlowStrategy ? screenData.urlParams : {};

    return (
      baseUrl +
      "?" +
      converObjectToParams({
        ...urlParams,
        off: currentCheckout[selectedPlan].offerCode,
        offDiscount: selectedPlan === "superleitura" ? offers.discounts.base : false,
        sck: rudderanalytics.getAnonymousId(),
        checkoutMode: "10",
        hidePix: "0",
        hideBillet: "0"
      })
    );
  };

  const buildNextPath = () => {
    const checkoutLink = buildCheckoutLink();
    return !isPostLeadFlowStrategy
      ? checkoutLink
      : "form?redirectUrl=" + encodeURIComponent(checkoutLink);
  };

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: t("layout.cta_continue"),
      disabledButton: false,
      nextPath: buildNextPath(),
      progress: 18,
      step: 18,
      bestPrice: bestPrice
    }));
  }, [selectedPlan, bestPrice]);

  return (
    <>
      <div>

        <h1 className="mb-4 mt-5 text-3xl font-bold tracking-tight text-gray-900  md:text-5xl lg:text-6xl">
          {t("super_leitura.headline")}
        </h1>

        <p>{t("super_leitura.subheadline")}</p>

        <div className="flex flex-col gap-1 mt-4 grow-0 justify-center items-center">
          <div className="text-red-600 flex rounded-full justify-center items-center py-1 text-2xl font-bold">
            <TimeCounter />
          </div>

          <h2 className="font-semibold text-xl">{t("super_leitura.limited_time")}</h2>
        </div>

        <div>
          <div
            onClick={() => setselectedPlan("superleitura")}
            className="mt-10 cursor-pointer border-4 text-gray-900 border-green-400"
          >
            <div className="flex bg-gray-100 p-4 py-6 text-left">
              <div className="pr-4">
                <div className="mt-1.5 rounded-full border-2 border-gray-900 bg-white p-0.5">
                  <div
                    className={
                      "h-2 w-2 rounded-full  bg-gray-900 " +
                      (selectedPlan === "superleitura" ? "bg-gray-900" : "bg-white")
                    }
                  ></div>
                </div>
              </div>
              <div>
                <div className="-ml-11 -mt-10 mb-2 w-28  bg-red-600 py-1 text-center text-xs text-white">
                  70% OFF
                </div>
                <strong className="mt-5 block text-sm">
                  {t("super_leitura.title_plan")}
                </strong>
                <div className="py-2">
                  <strong className="block text-xs font-normal text-red-600 line-through">
                    R$499
                  </strong>

                  <b className="block">
                    R$8,98/mês
                  </b>
                </div>
                <strong className="block text-sm text-blue-700">
                  7 dias de satisfação ou seu dinheiro de volta
                </strong>
              </div>
            </div>
            <div className="ml-8 p-4 text-left">
              <strong className="text-sm">
                Conheça as 3 Fases do método Super Leitura:
              </strong>

              <div className="mt-2 text-left text-sm">

                <div className="mb-3">
                  <h2 className="text-blue-700 font-bold flex gap-2">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3 9.27778L5.72222 12L11.9444 5" stroke="#5668B5" stroke-width="2.33333" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    Velocidade de Leitura
                  </h2>
                  <p className="font-light ml-6">
                    Aumente sua velocidade de leitura e compreensão
                  </p>
                </div>

                <div className="mb-3">
                  <h2 className="text-blue-700 font-bold flex gap-2">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3 9.27778L5.72222 12L11.9444 5" stroke="#5668B5" stroke-width="2.33333" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    Estrutura e Interpretação
                  </h2>
                  <p className="font-light ml-6">
                    Compreenda com clareza como o livro está organizado, suas partes e o todo, e como manter sua mente estimulada e ativa
                  </p>
                </div>

                <div className="mb-3">
                  <h2 className="text-blue-700 font-bold flex gap-2">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3 9.27778L5.72222 12L11.9444 5" stroke="#5668B5" stroke-width="2.33333" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    Retenção do Conhecimento
                  </h2>
                  <p className="font-light ml-6">
                    Aprenda técnicas para reter todo o conhecimento que você considerar relevante.
                  </p>
                </div>
                
              </div>
            </div>
          </div>

        </div>
      </div>

      {/* FAQ */}
      <div>
        <h2 className="mb-4 mt-5 text-2xl font-bold tracking-tight text-gray-900  md:text-3xl lg:text-4xl">
          {t("super_leitura.faq.headline")}
        </h2>

        <div className="text-left">
          <div className="border-b border-gray-500">
            <div
              className="flex cursor-pointer"
              onClick={() => setCurrentFaq(1)}
            >
              <div className="mt-3 pr-3">
                {currentFaq === 1 ? <CloseIcon /> : <OpenIcon />}
              </div>
              <div>
                <h3 className="py-3 font-bold">
                  {t("super_leitura.faq.q1.question")}
                </h3>
              </div>
            </div>
            {currentFaq === 1 && (
              <div className="pb-3">
                <p 
                  className="text-sm font-light text-gray-700 faq-answer"
                  dangerouslySetInnerHTML={{ __html: t('super_leitura.faq.q1.answer') }}
                />
              </div>
            )}
          </div>

          <div className="border-b border-gray-500">
            <div
              className="flex cursor-pointer"
              onClick={() => setCurrentFaq(2)}
            >
              <div className="mt-3 pr-3">
                {currentFaq === 2 ? <CloseIcon /> : <OpenIcon />}
              </div>
              <div>
                <h3 className="py-3 font-bold">
                  {t("super_leitura.faq.q2.question")}
                </h3>
              </div>
            </div>
            {currentFaq === 2 && (
              <div className="pb-3">
                <p 
                  className="text-sm font-light text-gray-700 faq-answer"
                  dangerouslySetInnerHTML={{ __html: t('super_leitura.faq.q2.answer') }}
                />
              </div>
            )}
          </div>

          <div className="border-b border-gray-500">
            <div
              className="flex cursor-pointer"
              onClick={() => setCurrentFaq(3)}
            >
              <div className="mt-3 pr-3">
                {currentFaq === 3 ? <CloseIcon /> : <OpenIcon />}
              </div>
              <div>
                <h3 className="py-3 font-bold">
                  {t("super_leitura.faq.q3.question")}
                </h3>
              </div>
            </div>
            {currentFaq === 3 && (
              <div className="pb-3">
                <p 
                  className="text-sm font-light text-gray-700 faq-answer"
                  dangerouslySetInnerHTML={{ __html: t('super_leitura.faq.q3.answer') }}
                />
              </div>
            )}
          </div>

          <div className="border-b border-gray-500">
            <div
              className="flex cursor-pointer"
              onClick={() => setCurrentFaq(4)}
            >
              <div className="mt-3 pr-3">
                {currentFaq === 4 ? <CloseIcon /> : <OpenIcon />}
              </div>
              <div>
                <h3 className="py-3 font-bold">
                  {t("super_leitura.faq.q4.question")}
                </h3>
              </div>
            </div>
            {currentFaq === 4 && (
              <div className="pb-3">
                <p 
                  className="text-sm font-light text-gray-700 faq-answer"
                  dangerouslySetInnerHTML={{ __html: t('super_leitura.faq.q4.answer') }}
                />
              </div>
            )}
          </div>

          <div className="border-b border-gray-500">
            <div
              className="flex cursor-pointer"
              onClick={() => setCurrentFaq(5)}
            >
              <div className="mt-3 pr-3">
                {currentFaq === 5 ? <CloseIcon /> : <OpenIcon />}
              </div>
              <div>
                <h3 className="py-3 font-bold">
                  {t("super_leitura.faq.q5.question")}
                </h3>
              </div>
            </div>
            {currentFaq === 5 && (
              <div className="pb-3">
                <p 
                  className="text-sm font-light text-gray-700 faq-answer"
                  dangerouslySetInnerHTML={{ __html: t('super_leitura.faq.q5.answer') }}
                />
              </div>
            )}
          </div>

          <div className="border-gray-500 pb-20">
            <div
              className="flex cursor-pointer"
              onClick={() => setCurrentFaq(6)}
            >
              <div className="mt-3 pr-3">
                {currentFaq === 6 ? <CloseIcon /> : <OpenIcon />}
              </div>
              <div>
                <h3 className="py-3 font-bold">
                  {t("super_leitura.faq.q6.question")}
                </h3>
              </div>
            </div>
            {currentFaq === 6 && (
              <div className="pb-3">
                <p 
                  className="text-sm font-light text-gray-700 faq-answer"
                  dangerouslySetInnerHTML={{ __html: t('super_leitura.faq.q6.answer') }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SelectPlan;
