import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useScreenContext, OptionRadio, relativePath, useStepNumberManager } from "../Layout";
import { useNavigate, useParams } from "react-router-dom";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

function DescribesYouQuestion() {
  const { t } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const navigate = useNavigate();
  const { offerType } = useParams();
  const currentFunnel = sessionStorage.getItem("currentFunnel");

  const isDirectSale = offerType === "direct";

  const stepNumber = useStepNumberManager(9);

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: undefined,
      nextPath: relativePath("/describes-info", offerType),
      progress: stepNumber,
      step: stepNumber,
    }));
  }, []);

  const handleOptionSelection = (option: string) => {
    setScreenData(() => ({
      ...screenData,
      userPreferences: {
        ...screenData.userPreferences,
        describesYouAnswer: option,
      },
    }));

    navigate(screenData.nextPath);
  };

  return (
    <div>
      <h1 className="mb-4 text-2xl font-bold tracking-tight text-gray-900  md:text-5xl">
        {t(`describes_you_question${ currentFunnel ? '.'+currentFunnel : '' }.headline`)}
      </h1>
      <div className="mt-10">
        <OptionRadio onClick={() => handleOptionSelection("leader")} name={"DescribesYou"} value={"leader"}>
          {t(`describes_you_question${ currentFunnel ? '.'+currentFunnel : '' }.option_1`)}
        </OptionRadio>
        <OptionRadio onClick={() => handleOptionSelection("follower")} name={"DescribesYou"} value={"follower"}>
          {t(`describes_you_question${ currentFunnel ? '.'+currentFunnel : '' }.option_2`)}
        </OptionRadio>
        <OptionRadio onClick={() => handleOptionSelection("both")} name={"DescribesYou"} value={"both"}>
          {t(`describes_you_question${ currentFunnel ? '.'+currentFunnel : '' }.option_3`)}
        </OptionRadio>
      </div>
    </div>
  );
}

export default DescribesYouQuestion;
