import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useScreenContext, Option, relativePath, OptionRadio, useStepNumberManager } from "../Layout";
import { useNavigate, useParams } from "react-router-dom";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

function KnowledgeQuestion() {
  const { t } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const navigate = useNavigate();
  const { offerType } = useParams();
  const [selected, setSelected] = useState("");
  const currentFunnel = sessionStorage.getItem("currentFunnel");

  const isDirectSale = offerType === "direct";

  const stepNumber = useStepNumberManager(7);

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: undefined,
      nextPath: relativePath("/knowledge-info", offerType),
      progress: stepNumber,
      step: stepNumber,
    }));
  }, []);

  const handleOptionSelection = (option: string) => {
    setScreenData(() => ({
      ...screenData,
      userPreferences: {
        ...screenData.userPreferences,
        knowledgeAnswer: option,
      },
    }));

    navigate(screenData.nextPath);
  };

  return (
    <div>
      <h1 className="mb-4 text-2xl font-bold tracking-tight text-gray-900  md:text-5xl">
        {t(`knowledge_question${ currentFunnel ? '.'+currentFunnel : '' }.headline`)}
      </h1>
      <div className="mt-10">
        <OptionRadio onClick={() => handleOptionSelection("daily")} name={"KnowledgeQuestion"} value={"daily"} >
          {t("knowledge_question.option_1")}
        </OptionRadio>
        <OptionRadio onClick={() => handleOptionSelection("weekly")} name={"KnowledgeQuestion"} value={"weekly"}>
          {t("knowledge_question.option_2")}
        </OptionRadio>
        <OptionRadio onClick={() => handleOptionSelection("infrequently")} name={"KnowledgeQuestion"} value={"infrequently"}>
          {t("knowledge_question.option_3")}
        </OptionRadio>
        <OptionRadio onClick={() => handleOptionSelection("no")} name={"KnowledgeQuestion"} value={"no"}>
          {t("knowledge_question.option_4")}
        </OptionRadio>
        {/* <OptionRadioGroup
          options={["daily", "weekly", "infrequently", "no"]}
          selected={selected}
          setSelected={setSelected}
        /> */}
      </div>
    </div>
  );
}

export default KnowledgeQuestion;
