import { useEffect } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { useScreenContext, useStepNumberManager } from "../Layout";
import resultInage from "../../assets/images/result.png";
import { useTranslation } from "react-i18next";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { useParams } from "react-router-dom";

function LoadingResult() {
  const { t } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const { offerType } = useParams();

  const isExp45Microbooks = useFeatureIsOn("exp-45-copy-test-microbooks");
  
  type TextType = {
    [key: string]: {
      headline: {
        part_1: string
        part_2: string
        part_3: string
      };
      subheadline: {
        part_1: string
        part_2: string
      };
    };
  };
  
  type StepType = {
    [key: string]: number;
  };

  const text: TextType = {
    sl: {
      headline: {
        part_1: t("result_sl.headline.part_1"),
        part_2: t("result_sl.headline.part_2"),
        part_3: t("result_sl.headline.part_3"),
      },
      subheadline: {
        part_1: t("result_sl.subheadline.part_1"),
        part_2: t("result_sl.subheadline.part_2")
      },
    },
    default: {
      headline: {
        part_1: t("result.headline.part_1"),
        part_2: isExp45Microbooks ? "titulos" : t("result.headline.part_2"),
        part_3: t("result.headline.part_3"),
      },
      subheadline: {
        part_1: isExp45Microbooks ? "O 12min oferece 2.500+ titulos em 27 categorias para você sempre descobrir algo novo" : t("result.subheadline.part_1"),
        part_2: t("result.subheadline.part_2")
      },
    },
  };
  
  const step: StepType = {
    sl: 15,
    default: 19,
  };

  function getText(funnel: string) {
    return text[funnel] || text.default;
  }

  function getStep(funnel: string) {
    return step[funnel] || step.default;
  }

  const stepNumber = useStepNumberManager(getStep(offerType ?? ""))

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: t("layout.cta_continue"),
      nextPath: "book-list",
      progress: stepNumber,
      step: stepNumber,
    }));
  }, [screenData]);

  return (
    <main className="flex flex-col h-full justify-between gap-10">
      <header>
        <ConfettiExplosion />
        <h1 className="mb-4 mt-5 text-3xl font-semibold leading-tight tracking-normal text-gray-900 md:text-4xl">
          {t("result.headline.part_1")}{" "}
          <span className="font-bold text-blue-600">
            {screenData.userPreferences.priorityAreasTotal || "1.307"} {text.default.headline.part_2}
          </span>{" "}
          {t("result.headline.part_3")}
        </h1>
        <p className="font-light">
          {text.default.subheadline.part_1}
        </p>
        <div className="px-10 py-5 ">
          <hr />
        </div>
        <p className="font-light">
          <span className="text-lg">🎧</span> {t("result.subheadline.part_2")}
        </p>
      </header>
      <img
        className="mx-auto w-full max-w-sm px-10"
        alt="Chart showing your progress"
        src={resultInage}
      />
    </main>
  );
}

export default LoadingResult;
