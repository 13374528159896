import { useEffect } from "react";
import { relativePath, useScreenContext, useNextLinkBuild, useStepNumberManager, totalStepsByFunnel } from "../Layout";
import { useTranslation } from "react-i18next";
import { useSessionStorage } from "react-use";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

import Icon from "../../assets/images/icon.svg";
import { t } from "i18next";

const startImage: any = {
  br: require("../../assets/images/start.br.png"),
  en: require("../../assets/images/start.en.png"),
  es: require("../../assets/images/start.es.png"),
};

type Book = {
  title: string;
  image: string;
};

const books: Record<"br" | "en" | "es", Book[]> = {
  br: [
    {
      title: "Dia 01",
      image: "https://cdn.12min.com/uploads/img-20230302/antifragil.png"
    },
    {
      title: "Dia 02",
      image: "https://cdn.12min.com/uploads/img-20230302/o-poder-do-habito.png"
    },
    {
      title: "Dia 03",
      image: "https://cdn.12min.com/uploads/img-20230302/o-investidor-inteligente.png"
    },
    {
      title: "Dia 04",
      image: "https://storage.googleapis.com/12minapp/books/books_custom_cover/110_Pai_Rico__Pai_Pobre-PT.original.png"
    },
    {
      title: "Dia 05",
      image: "https://storage.googleapis.com/12minapp/books/books_custom_cover/69_Os_Segredos_da_Mente_Milionária-PT.original.png"
    }
  ],
  en: [
    {
      title: "Day 01",
      image: "https://cdn.12min.com/uploads/wizard/en/Antifragile-EN.png"
    },
    {
      title: "Day 02",
      image: "https://cdn.12min.com/uploads/wizard/en/The%20Power%20of%20Habit-EN.png"
    },
    {
      title: "Day 03",
      image: "https://cdn.12min.com/uploads/wizard/en/The%20Intelligent%20Investor-EN.png"
    },
    {
      title: "Day 04",
      image: require("../../assets/images/books/Rich Dad Poor Dad-EN.png")
    },
    {
      title: "Day 05",
      image: "https://cdn.12min.com/uploads/wizard/en/The%20Secrets%20of%20the%20Millionaire%20Mind-EN.png"
    }
  ],
  es: [
    {
      title: "Día 01",
      image: "https://cdn.12min.com/uploads/wizard/es/Antifrágil-ES.png"
    },
    {
      title: "Día 02",
      image: "https://cdn.12min.com/uploads/wizard/es/El%20Poder%20de%20Los%20Hábitos-ES.png"
    },
    {
      title: "Día 03",
      image: "https://cdn.12min.com/uploads/wizard/es/El%20inversor%20inteligente-ES.png"
    },
    {
      title: "Día 04",
      image: require("../../assets/images/books/Padre Rico Padre Pobre-ES.png")
    },
    {
      title: "Día 05",
      image: "https://cdn.12min.com/uploads/wizard/es/Los%20Secretos%20de%20la%20Mente%20Millonaria-ES.png"
    }
  ]
};

function Welcome() {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const { offerType } = useParams();
  const [screenData, setScreenData] = useScreenContext();
  const [sessionStorageOffer, setSessionStorageOffer] = useSessionStorage(
    "offer",
    "foo"
  );

  const currentFunnel = sessionStorage.getItem("currentFunnel");
  
  const isSlFunnel = offerType === "sl"; // Super Leitura

  const exp27Headline = useFeatureIsOn("exp-27-first-screen-headline");
  const exp35copy = useFeatureIsOn("exp-35-copy-first-page");
  const isExp46 = useFeatureIsOn("exp-46-social-proof");

  const [_, setSessionStorageVariant] = useSessionStorage("variant");

  const headline = searchParams.get("headline");
  const image = searchParams.get("image");

  const variant = searchParams.get("variant");

  const stepNumber = useStepNumberManager(1);

  let path = isSlFunnel ? relativePath("/usage", offerType) : relativePath("/gender", offerType)
  const nextPath = screenData.utmSource ? path + '?utm_source=' + screenData.utmSource : path
  const nextPathExp = isExp46 ? relativePath("/talking-about-2", offerType) : nextPath

  useEffect(() => {
    if (searchParams.get("offer")) {
      setSessionStorageOffer(searchParams.get("offer") || "");
    } else {
      setSessionStorageOffer("");
    }

    if (variant) {
      setSessionStorageVariant(variant);
    }

    

    // console.log("screenData.utmSource", screenData.utmSource);

    setScreenData(() => ({
      ...screenData,
      submitLabel: exp35copy ? "Iniciar o Quiz" : t("layout.cta_continue", {
        interpolation: { escapeValue: false },
      }),
      nextPath: nextPathExp,
      progress: stepNumber,
      disabledButton: true,
      step: stepNumber,
    }));
  }, [exp35copy, nextPathExp]);

 useEffect(() => {
    const updateScreenData = setTimeout(() => {
      setScreenData(() => ({
        ...screenData,
        submitLabel: exp35copy ? "Iniciar o Quiz" : t("layout.cta_continue", {
          interpolation: { escapeValue: false },
        }),
        nextPath: nextPathExp,
        totalSteps: isExp46 ? 26 : totalStepsByFunnel[offerType ?? "direct"] || 25,
        progress: stepNumber,
        disabledButton: false,
        step: stepNumber,
      }));
    }, 1000);

    return () => clearTimeout(updateScreenData);
  }, [nextPathExp]);

  type HeadlineText = {
    default: string;
    a: string;
    b: string;
    c: string;
    [key: string]: string; // Add index signature
  };

  const headlineText: HeadlineText = {
    default: t("start.headline"),
    a: "O maior app de resumos de livros da América Latina",
    b: "Junte-se a mais de 5 milhões de leitores em todo o mundo",
    c: "Construa sua rotina de leitura"
  }

  return (
    <main className="flex flex-col h-full justify-between gap-2 mb-[100px]">
      <header>
        <h1 className="mb-4 mt-5 text-3xl font-bold tracking-tight text-gray-900 md:text-5xl">
          {exp27Headline ? headlineText["c" || "default"] : t(`start${ currentFunnel ? '.'+currentFunnel : '' }.headline`)}
        </h1>
        <h2>{t(`start${ currentFunnel ? '.'+currentFunnel : '' }.subheadline`)}</h2>
      </header>

      <div className="container bg-[#F5F6FB] rounded-3xl p-2 py-6 md:p-6 mt-4">
        <div className="flex justify-center items-center mb-6 gap-3">
          <img className="h-8 w-8 rounded-full" src={Icon} alt="12min Logo" />
          <h2 className="text-xl font-bold text-[#4357AD]">
            {t("start.title_box")}
          </h2>
        </div>

        <div className="grid grid-cols-6 gap-2 justify-center justify-items-center md:gap-4 md:grid-cols-5">
          {books[i18n.language as "br" | "en" | "es"].map((book, index) => {
            if (index < 3) {
              // for to 3 first images
              return (
                <div
                  key={index}
                  className="flex flex-col gap-3 col-span-2 md:col-span-1 items-center justify-center w-[100px]"
                >
                  <img
                    className="mx-auto rounded-xl shadow-xl min-w-[90px] object-cover"
                    width="90"
                    alt={book.title}
                    src={book.image}
                  />
                  <span className="text-sm font-bold">{book.title}</span>
                </div>
              );
            } else {
              // for to last images
              return (
                <div
                  key={index}
                  className={`flex flex-col gap-3 items-center justify-center w-[100px] ${index === 3 ? 'col-span-2 col-start-2 md:col-span-1 md:col-start-auto' : 'col-span-2 col-start-4 md:col-span-1 md:col-start-auto'}`}
                >
                  <img
                    className="mx-auto rounded-xl shadow-xl min-w-[90px] object-cover"
                    width="90"
                    alt={book.title}
                    src={book.image}
                  />
                  <span className="text-sm font-bold">{book.title}</span>
                </div>
              );
            }
          })}
        </div>
      </div>
    </main>
  );
}

export default Welcome;
