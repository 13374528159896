import axios from "axios";
import { FormEvent, useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useScreenContext, relativePath, useStepNumberManager } from "../Layout";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as rudderanalytics from "../../lib/rudderstack";
import * as amplitude from "@amplitude/analytics-browser";
import { useInterval } from "react-use";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

amplitude.init("fa4b0a4e06cd1e585aa882324575e119");

const trackAmplitudeLead = (email: string) => {
  amplitude.setUserId(email);
  amplitude.logEvent("Lead");
};

const converObjectToParams = (object: any) => {
  return Object.keys(object)
    .map((key) => {
      return `${key}=${encodeURIComponent(object[key])}`;
    })
    .join("&");
};

type LeadForm = {
  name: string;
  email: string;
  phone: string;
};

function Form() {
  const [totalUsers, setTotalUsers] = useState(5274333);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [form, setForm] = useState<LeadForm>({
    name: "",
    email: "",
    phone: "",
  });
  const [screenData, setScreenData] = useScreenContext();
  const { offerType } = useParams();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirectUrl");
  const isDirectSale = offerType === "direct";
  const isRetSale = offerType === "ret";
  const isAppFunnel = offerType === "app";
  const isSlFunnel = offerType === "sl";

  const socialProofFeature = useFeatureIsOn("lead-social-proof");
  const newPostLeadExperiment = useFeatureIsOn("new-post-lead-experiment");
  const exp18feature = useFeatureIsOn("exp-18-feature"); // awards badges
  const isExp48 = useFeatureIsOn("exp-48-product-taste");

  
  type TextType = {
    [key: string]: {
      headline: string;
      info_number_users: string;
    };
  };
  
  type StepType = {
    [key: string]: number;
  };

  const text: TextType = {
    sl: {
      headline: t("form_sl.headline"),
      info_number_users: t("form_sl.info_number_users"),
    },
    default: {
      headline: t("form.headline"),
      info_number_users: t("form.info_number_users"),
    },
  };
  
  const step: StepType = {
    sl: 17,
    default: 21,
  };

  function getText(funnel: string) {
    return text[funnel] || text.default;
  }

  function getStep(funnel: string) {
    return step[funnel] || step.default;
  }

  const funnels = (href: string) => {
    if (href.includes("/direct")) return "wizard-direct";
    if (href.includes("/ret")) return "wizard-ret";
    if (href.includes("/app")) return "wizard-app";
  
    return "wizard-trial";
  }

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();

    const languages: { [key: string]: string } = {
      es: "3",
      en: "2",
      br: "1",
    };

    axios
      .post("https://api.12min.com/api/v1/leads/track", {
        name: form.name,
        email: form.email,
        phone: form.phone ? "+55" + form.phone.match(/\d/g)!.join("") : "",
        user_url: window.location.href,
        list_id: "104",
        interface_language: languages[i18n.language],
        userPreferences: screenData.userPreferences,
        funnel: funnels(window.location.href),
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          rudderanalytics.identify(form.email);
          rudderanalytics.lead();
          trackAmplitudeLead(form.email);
        }
      });

    const params = {
      hidePix: (isDirectSale || isRetSale || isAppFunnel) ? 0 : 1,
      hideBillet: (isDirectSale || isRetSale || isAppFunnel) ? 0 : 1,
      checkoutMode: 10,
      split: 12,
      name: form.name,
      email: form.email,
      phone: form.phone ? "+55" + form.phone.match(/\d/g)!.join("") : "",
    };

    setScreenData(() => ({
      ...screenData,
      urlParams: params,
    }));

    if (redirectUrl) {
      const url =
        decodeURIComponent(redirectUrl) + "&" + converObjectToParams(params);
      window.location.href = url;
      return;
    }

    
    console.log("isExp48", isExp48);

    const nextPathScreen = () => {
      if (isExp48) {
        return relativePath("/product", offerType);
      }

      if (isSlFunnel) {
        return relativePath("/select-plan-sl", offerType);
      }

      return offerType ? relativePath("/select-challenge", offerType) : "/select-challenge";
    }

    const nextPath = nextPathScreen();

    console.log("nextPath", nextPath);

    navigate(nextPath);
  };

  const stepNumber = useStepNumberManager(getStep(offerType ?? ""));

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: false,
      nextPath: "book-suggestion/1",
      progress: stepNumber,
      step: stepNumber,
    }));
  }, []);

  useInterval(() => {
    const numbersToIncrement = [0, 1, 2];
    const increment =
      numbersToIncrement[Math.floor(Math.random() * numbersToIncrement.length)];
    setTotalUsers(totalUsers + increment);
  }, 1000);

  return (
    <div>
      {socialProofFeature && (
        <div className="mt-10">
          <img
            className="mr-5 inline-block max-w-[40%]"
            src={require("../../assets/images/appstore.png")}
          />
          <img
            className="inline-block max-w-[40%]"
            src={require("../../assets/images/google_play.png")}
          />
        </div>
      )}

      <h1 className="mt-5 text-2xl font-semibold leading-tight tracking-normal text-gray-900  md:text-4xl">
        {getText(offerType ?? "").headline}
      </h1>

      <div className="mt-10 text-left">
        <form
          onSubmit={handleFormSubmit}
          id="lead"
          encType="multipart/form-data"
          method="GET"
          action="/"
        >
          <div className="mb-2">
            <label className=" text-gray-700">{t("form.name_label")}</label>
            <input
              name="name"
              required
              type="text"
              className="form-input w-full"
              placeholder={t("form.name_placeholder")}
              autoFocus={true}
              onChange={(e) => setForm({ ...form, name: e.target.value })}
            />
          </div>
          <div className="mb-2">
            <label className=" text-gray-700">{t("form.email_label")}</label>
            <input
              required
              name="email"
              type="email"
              className="form-input w-full"
              placeholder={t("form.email_placeholder")}
              onChange={(e) => setForm({ ...form, email: e.target.value })}
            />
          </div>
          <div className="mb-2">
            <label className=" text-gray-700">{t("form.phone_label")}</label>
            <input
              name="phone"
              type="phone"
              className="form-input w-full"
              placeholder={t("form.phone_placeholder")}
              onChange={(e) => setForm({ ...form, phone: e.target.value })}
            />
          </div>
          <input
            type="submit"
            style={{ visibility: "hidden", position: "absolute" }}
          />
          <button
            type="submit"
            className="text-md my-6 w-full rounded-md bg-blue-700 px-5 py-2.5 font-normal text-white hover:bg-blue-800 focus:ring-blue-300"
          >
            {t("layout.cta_continue")}
          </button>
        </form>
      </div>

      <p>
        {isSlFunnel ? (
          getText(offerType ?? "").info_number_users
        ) : (
          <Trans
            i18nKey="form.info_number_users"
            values={{ totalUsers: totalUsers.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
          >
            Mais de <strong>5.274.333</strong> pessoas já usam o 12min
          </Trans>
        )}
      </p>

      <span className="text-xs text-gray-500">{t("form.info")}</span>

      {exp18feature && (
        <div className="awards-badges py-8 px-4">
          <img
            className="mr-5"
            src={require("../../assets/images/12min-awards.webp")}
          />
        </div>
      )}
    </div>
  );
}

export default Form;
