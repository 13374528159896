import { ReactNode, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSessionStorage } from "react-use";
import { useParams, useSearchParams } from "react-router-dom";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { HiPlay, HiPause , HiChevronLeft, HiChevronRight, HiChevronDown, HiChevronUp } from "react-icons/hi2";

import { relativePath, useScreenContext, useStepNumberManager } from "../Layout";

import "./Product.css";
import Icon from "../../assets/images/icon.svg";
import { t } from "i18next";

interface Book {
  id: number;
  title: string;
  reads: number;
  cover: string;
  audio: string;
  audioLength?: string;
}

type BookCategory = keyof typeof books;
type Books = Record<string, Book[]>;

function reqAudio(fileName?: string) {
  if (!fileName) {
    return require("../../assets/product/audio/cut_IDEIAS_QUE_COLAM.mp3");
  }
  return require(`../../assets/product/audio/${fileName}.mp3`);
}

function reqCover(fileName?: string) {
  if (!fileName) {
    return require("../../assets/product/cover/default.png");
  }
  return require(`../../assets/product/cover/${fileName}.png`);
}

const books: Books = {
  "Biografias e Memórias": [
    {
      id: 147,
      title: "Elon Musk",
      reads: 11119,
      cover: reqCover("Elon Musk"),
      audio: reqAudio("cut_ELON_MUSK"),
      audioLength: "21:21",
    },
    {
      id: 170,
      title: "A Marca da Vitória",
      reads: 8198,
      cover: reqCover("A Marca da Vitória-PT"),
      audio: reqAudio("cut_A_MARCA_DA_VITORIA"),
      audioLength: "16:28",
    },
    {
      id: 60276,
      title: "Minha História",
      reads: 8018,
      cover: reqCover("Minha História-PT"),
      audio: reqAudio("cut_minha_historia_-_michelle_obama"),
      audioLength: "14:19",
    }
  ],
  "Carreira e Negócios": [
    {
      id: 110,
      title: "Pai Rico Pai Pobre",
      reads: 108624,
      cover: reqCover("Pai Rico, Pai Pobre-PT"),
      audio: reqAudio("cut_pai_rico_pai_pobre"),
      audioLength: "12:22",
    },
    {
      id: 94,
      title: "As Armas da Persuasão",
      reads: 68184,
      cover: reqCover("As Armas da Persuasão-PT"),
      audio: reqAudio("cut_AS_ARMAS_DA_PERSUASAO"),
      audioLength: "16:21",
    },
    {
      id: 39,
      title: "Sonho Grande",
      reads: 21652,
      cover: reqCover("Sonho Grande-PT"),
      audio: reqAudio("cut_12min_SonhoGrande"),
      audioLength: "14:14",
    }
  ],
  "Cultura empresarial e comunicação": [
    {
      id: 60560,
      title: "Gatilhos Mentais",
      reads: 19167,
      cover: reqCover("Gatilhos Mentais-PT"),
      audio: reqAudio("cut_gatilhos_mentais"),
      audioLength: "12:39",
    },
    {
      id: 62074,
      title: "A linguagem secreta do carisma",
      reads: 13598,
      cover: reqCover("A linguagem secreta do carisma"),
      audio: reqAudio("cut_a_linguagem_secreta_do_carisma"),
      audioLength: "13:41",
    },
    {
      id: 161,
      title: "A Loja De Tudo",
      reads: 7662,
      cover: reqCover("A Loja De Tudo PT"),
      audio: reqAudio("cut_A_LOJA_DE_TUDO"),
      audioLength: "16:47",
    }
  ],
  "Economia": [
    {
      id: 131,
      title: "Freakonomics",
      reads: 8646,
      cover: reqCover("Freakonomics-PT"),
      audio: reqAudio("cut_FREAKONOMICS"),
      audioLength: "13:30",
    },
    {
      id: 111,
      title: "Pense Como um Freak",
      reads: 6645,
      cover: reqCover("Pense Como um Freak-PT"),
      audio: reqAudio("cut_PENSE_COMO_UM_FREAK"),
      audioLength: "14:55",
    },
    {
      id: 33330,
      title: "Os Axiomas de Zurique",
      reads: 6104,
      cover: reqCover("Os Axiomas de Zurique-PT"),
      audio: reqAudio("cut_OS_AXIOMAS_DE_ZURIQUE"),
      audioLength: "24:12",
    }
  ],
  "Inteligência emocional": [
    {
      id: 60012,
      title: "O Segredo para Vencer o Medo",
      reads: 25852,
      cover: reqCover("O Segredo Para Vencer o Medo-PT"),
      audio: reqAudio("cut_O_SEGREDO_PARA_VENCER_O_MEDO"),
      audioLength: "15:14",
    },
    {
      id: 61102,
      title: "O sucesso é treinável",
      reads: 21491,
      cover: reqCover("O Sucesso é Treinável-PT"),
      audio: reqAudio("cut_o_sucesso_eh_treinavel"),
      audioLength: "11:08",
    },
    {
      id: 60335,
      title: "As Cinco Linguagens do Amor",
      reads: 18475,
      cover: reqCover("As 5 linguages do Amor para Crianças-PT"),
      audio: reqAudio("cut_As_Cinco_Linguagens_do_Amor"),
      audioLength: "06:14",
    }
  ],
  "Empreendedorismo": [
    {
      id: 34,
      title: "A Estratégia do Oceano Azul",
      reads: 29783,
      cover: reqCover("A Estratégia do Oceano Azul-PT"),
      audio: reqAudio("cut_a_estrategia_do_oceano_azul"),
      audioLength: "12:25",
    },
    {
      id: 158,
      title: "Pense Simples",
      reads: 14087,
      cover: reqCover("Pense Simples-PT"),
      audio: reqAudio("cut_PENSE_SIMPLES"),
      audioLength: "16:47",
    },
    {
      id: 79,
      title: "De Zero a Um",
      reads: 9128,
      cover: reqCover("De Zero a Um-PT"),
      audio: reqAudio("cut_DE_ZERO_A_UM"),
      audioLength: "12:55",
    }
  ],
  "Saúde & Alimentação": [
    {
      id: 18645,
      title: "A Revolução do Sono",
      reads: 11869,
      cover: reqCover("A Revolução do Sono PT"),
      audio: reqAudio("cut_A_REVOLUCAO_DO_SONO"),
      audioLength: "15:04",
    },
    {
      id: 62134,
      title: "Como aumentar a capacidade do seu cérebro",
      reads: 11680,
      cover: reqCover("Como aumentar a capacidade do seu cérebro"),
      audio: reqAudio("cut_como_aumentar_a_capacidade_do_seu_cerebro"),
      audioLength: "12:29",
    },
    {
      id: 127,
      title: "4 Horas Para o Corpo",
      reads: 10517,
      cover: reqCover("4 Horas Para o Corpo PT"),
      audio: reqAudio("cut_4_HORAS_PARA_O_CORPO"),
      audioLength: "24:34",
    }
  ],
  "História & Filosofia": [
    {
      id: 33036,
      title: "O Príncipe",
      reads: 14898,
      cover: reqCover("O Príncipe-PT"),
      audio: reqAudio("cut_O_PRINCIPE"),
      audioLength: "20:37",
    },
    {
      id: 37778,
      title: "Filosofia Para Corajosos",
      reads: 7960,
      cover: reqCover("Filosofia Para Corajosos-PT"),
      audio: reqAudio("cut_FILOSOFIA_PARA_CORAJOSOS"),
      audioLength: "26:39",
    },
    {
      id: 60802,
      title: "Sobre a Brevidade da Vida",
      reads: 5953,
      cover: reqCover("Sobre a Brevidade da Vida-PT"),
      audio: reqAudio("cut_sobre_a_brevidade_da_vida"),
      audioLength: "06:41",
    }
  ],
  "Investimento & Finanças": [
    {
      id: 69,
      title: "Os Segredos da Mente Milionária",
      reads: 161413,
      cover: reqCover("Os Segredos da Mente Milionária-PT"),
      audio: reqAudio("cut_os_segredos_das_mentes_milionarias"),
      audioLength: "11:46",
    },
    {
      id: 60234,
      title: "Me Poupe!",
      reads: 37034,
      cover: reqCover("Me Poupe-PT"),
      audio: reqAudio("cut_12min_MePoupe"),
      audioLength: "13:54",
    },
    {
      id: 132,
      title: "Quem Pensa Enriquece",
      reads: 20845,
      cover: reqCover("Quem Pensa Enriquece-PT"),
      audio: reqAudio("cut_QUEM_PENSA_ENRIQUECE"),
      audioLength: "21:24",
    }
  ],
  "Gestão & Liderança": [
    {
      id: 48,
      title: "A Arte da Guerra",
      reads: 45162,
      cover: reqCover("A arte da Guerra-PT"),
      audio: reqAudio("cut_a_arte_da_guerra"),
      audioLength: "12:00",
    },
    {
      id: 85,
      title: "As 48 Leis do Poder",
      reads: 29490,
      cover: reqCover("As 48 Leis do Poder-PT"),
      audio: reqAudio("cut_AS_48_LEIS_DO_PODER"),
      audioLength: "18:01",
    },
    {
      id: 35,
      title: "Líderes Se Servem Por Último",
      reads: 18385,
      cover: reqCover("Líderes se Servem por Último-PT"),
      audio: reqAudio("cut_lideres_se_servem_por_ultimo"),
      audioLength: "12:02",
    }
  ],
  "Marketing e vendas": [
    {
      id: 37,
      title: "A Arte da Negociação",
      reads: 23004,
      cover: reqCover("A Arte da Negociação-PT"),
      audio: reqAudio("cut_a_arte_da_negociacao"),
      audioLength: "11:31",
    },
    {
      id: 18713,
      title: "Como Convencer Alguém Em 90 Segundos",
      reads: 14615,
      cover: reqCover("Como Convencer Alguém Em 90 Segundos-PT"),
      audio: reqAudio("cut_COMO_CONVENCER_ALGUEM_EM_90_SEGUNDOS"),
      audioLength: "21:29",
    },
    {
      id: 62,
      title: "Ideias Que Colam",
      reads: 9402,
      cover: reqCover("Ideias Que Colam-PT"),
      audio: reqAudio("cut_IDEIAS_QUE_COLAM"),
      audioLength: "11:59",
    }
  ],
  "Parentalidade": [
    {
      id: 61672,
      title: "Crianças francesas não fazem manha",
      reads: 2960,
      cover: reqCover("Crianças francesas não fazem manha-PT"),
      audio: reqAudio("cut_12min_CriancasFrancesasNaoFazemManha"),
      audioLength: "10:51",
    },
    {
      id: 61203,
      title: "Se vira, moleque",
      reads: 1861,
      cover: reqCover("Se vira, moleque-PT"),
      audio: reqAudio("cut_se_vira_moleque"),
      audioLength: "12:56",
    },
    {
      id: 62016,
      title: "A raiva não educa: A calma educa",
      reads: 1735,
      cover: reqCover("A raiva não educa. A calma educa"),
      audio: reqAudio("cut_a_raiva_nao_educa"),
      audioLength: "13:10",
    }
  ],
  "Desenvolvimento pessoal": [
    {
      id: 96,
      title: "Como Fazer Amigos e Influenciar Pessoas",
      reads: 109063,
      cover: reqCover("Como Fazer Amigos e Influenciar Pessoas-PT"),
      audio: reqAudio("cut_como_fazer_amigos_e_influenciar_pessoas"),
      audioLength: "11:58",
    },
    {
      id: 18721,
      title: "Quem Mexeu no Meu Queijo?",
      reads: 40574,
      cover: reqCover("Quem Mexeu no Meu Queijo_-PT"),
      audio: reqAudio("cut_quem_mexeu_no_meu_queijo"),
      audioLength: "12:02",
    },
    {
      id: 32973,
      title: "O Poder da Ação",
      reads: 17464,
      cover: reqCover("O Poder da Ação-PT"),
      audio: reqAudio("cut_O_PODER_DA_AÇÃO_LAUDAS_AUDIO_COMPLETO"),
      audioLength: "23:12",
    }
  ],
  "Produtividade e gestão do tempo": [
    {
      id: 86,
      title: "O Poder do Hábito",
      reads: 206361,
      cover: reqCover("O Poder do Hábito-PT"),
      audio: reqAudio("cut_o_poder_do_habito"),
      audioLength: "12:06",
    },
    {
      id: 101,
      title: "Mindset",
      reads: 64969,
      cover: reqCover("Mindset"),
      audio: reqAudio("cut_mindset"),
      audioLength: "11:56",
    },
    {
      id: 60280,
      title: "Manhãs Poderosas",
      reads: 40205,
      cover: reqCover("Manhãs Poderosas-PT"),
      audio: reqAudio("cut_Manhãs_Poderosas"),
      audioLength: "11:31",
    }
  ],
  "Psicologia": [
    {
      id: 61693,
      title: "Inteligência Emocional",
      reads: 7900,
      cover: reqCover("Inteligência Emocional-PT"),
      audio: reqAudio("cut_12min_InteligenciaEmocional"),
      audioLength: "16:21",
    },
    {
      id: 61177,
      title: "Como pensar e viver melhor",
      reads: 4993,
      cover: reqCover("Como pensar e viver melhor-PT"),
      audio: reqAudio("cut_como_pensar_e_viver_melhor"),
      audioLength: "11:17",
    },
    {
      id: 61914,
      title: "A voz na sua cabeça",
      reads: 4934,
      cover: reqCover("A voz na sua cabeça-PT"),
      audio: reqAudio("cut_a_voz_na_sua_cabeca"),
      audioLength: "09:03",
    }
  ],
  "Ciência": [
    {
      id: 61059,
      title: "O andar do bêbado",
      reads: 4094,
      cover: reqCover("O andar do bêbado-PT"),
      audio: reqAudio("cut_12min_OAndarDoBebado"),
      audioLength: "08:44",
    },
    {
      id: 60734,
      title: "The Brain That Changes Itself",
      reads: 3072,
      cover: reqCover("The Brain That Changes Itself-PT"),
      audio: reqAudio("cut_the_brain_that_changes_itself"),
      audioLength: "12:51",
    },
    {
      id: 60997,
      title: "A estranha ordem das coisas",
      reads: 2924,
      cover: reqCover("A estranha ordem das coisas-PT"),
      audio: reqAudio("cut_12min_A_Estranha_Ordem_das_Coisas"),
      audioLength: "08:04",
    }
  ],
  "Autoajuda & Motivação": [
    {
      id: 18763,
      title: "O Milagre Da Manhã",
      reads: 31119,
      cover: reqCover("O Milagre da Manhã-PT"),
      audio: reqAudio("cut_o_milagre_da_manha"),
      audioLength: "10:16",
    },
    {
      id: 60328,
      title: "12 Regras Para a Vida",
      reads: 15625,
      cover: reqCover("12 Regras Para a Vida PT"),
      audio: reqAudio("cut_12_Regras_para_a_Vida"),
      audioLength: "10:46",
    },
    {
      id: 33363,
      title: "A Arte de Ler Mentes",
      reads: 11172,
      cover: reqCover("A Arte de Ler Mentes-PT"),
      audio: reqAudio("cut_A_ARTE_DE_LER_MENTES_AUDIO_COMPLETO"),
      audioLength: "19:40",
    }
  ],
  "Sexo & Relacionamentos": [
    {
      id: 130,
      title: "Homens São de Marte; Mulheres São de Vênus",
      reads: 10788,
      cover: reqCover("Homens São de Marte, Mulheres São de Vênus-PT"),
      audio: reqAudio("cut_HOMENS_SAO_DE_MARTE_MULHERES_SAO_DE"),
      audioLength: "14:27",
    },
    {
      id: 60462,
      title: "O Milagre da Manhã para Transformar o seu Relacionamento",
      reads: 7191,
      cover: reqCover("O Milagre da Manhã para Transformar o seu Relacionamento-PT"),
      audio: reqAudio("cut_O_Milagre_da_Manhã_para_Transformar_seu_Relacionamento"),
      audioLength: "11:51",
    },
    {
      id: 33783,
      title: "Inteligência Relacional",
      reads: 7000,
      cover: reqCover("Inteligência Relacional-PT"),
      audio: reqAudio("cut_inteligencia_relacional"),
      audioLength: "13:45",
    }
  ],
  "Sociedade e Política": [
    {
      id: 61420,
      title: "Os homens explicam tudo para mim",
      reads: 5098,
      cover: reqCover("Os Homens Explicam Tudo Para Mim-PT"),
      audio: reqAudio("cut_12min_OsHomensExplicamTudoParaMim"),
      audioLength: "10:30",
    },
    {
      id: 60350,
      title: "Verdade?",
      reads: 4572,
      cover: reqCover("Verdade_-PT"),
      audio: reqAudio("cut_Verdade_Porque_nem_tudo_o_que_ouvimos_ou_falamos_é_verdadeiro"),
      audioLength: "09:50",
    },
    {
      id: 59976,
      title: "Amor Líquido",
      reads: 3367,
      cover: reqCover("Amor Líquido-PT"),
      audio: reqAudio("cut_AMOR_LIQUIDO"),
      audioLength: "17:51",
    }
  ],
  "Espiritualidade e Mindfulness": [
    {
      id: 18643,
      title: "As sete leis espirituais do sucesso",
      reads: 10662,
      cover: reqCover("As Sete Leis Espirituais do Sucesso-PT"),
      audio: reqAudio("cut_AS_SETE_LEIS_ESPIRITUAIS_DO_SUCESSO"),
      audioLength: "17:00",
    },
    {
      id: 60366,
      title: "Meditação Para Céticos Ansiosos",
      reads: 9303,
      cover: reqCover("Meditação Para Céticos Ansiosos-PT"),
      audio: reqAudio("cut_Meditação_Para_Céticos_Ansiosos"),
      audioLength: "11:15",
    },
    {
      id: 62078,
      title: "Esvazie a negatividade",
      reads: 7543,
      cover: reqCover("Esvazie a negatividade"),
      audio: reqAudio("cut_esvazie_a_negatividade"),
      audioLength: "13:01",
    }
  ],
  "Desporto": [
    {
      id: 60812,
      title: "A Mentalidade Mamba",
      reads: 4863,
      cover: reqCover("A Mentalidade Mamba-PT"),
      audio: reqAudio("cut_the_mamba_mentality"),
      audioLength: "08:32",
    },
    {
      id: 60788,
      title: "Transformando Suor em Ouro",
      reads: 3464,
      cover: reqCover("Transformando Suor em Ouro-PT"),
      audio: reqAudio("cut_transformando_suor_em_ouro"),
      audioLength: "07:33",
    },
    {
      id: 33101,
      title: "Vitor Belfort",
      reads: 2227,
      cover: reqCover("Vitor Belfort-PT"),
      audio: reqAudio("cut_VITOR_BELFORT_AUDIO_COMPLETO"),
      audioLength: "23:35",
    }
  ],
  "Tecnologia e inovação": [
    {
      id: 82,
      title: "Hackers & Painters",
      reads: 7096,
      cover: reqCover("Hackers & Painters_ PT"),
      audio: reqAudio("cut_HACKERS___PAINTERS"),
      audioLength: "11:24",
    },
    {
      id: 139,
      title: "Big Data",
      reads: 5366,
      cover: reqCover("Big Data PT"),
      audio: reqAudio("cut_BIG_DATA"),
      audioLength: "12:11",
    },
    {
      id: 61219,
      title: "A arte das redes sociais",
      reads: 3962,
      cover: reqCover("A arte das redes sociais-PT"),
      audio: reqAudio("cut_12min_AArteDasRedesSociais"),
      audioLength: "12:51",
    }
  ]
};

const startImage: any = {
  br: require("../../assets/images/start.br.png"),
  en: require("../../assets/images/start.en.png"),
  es: require("../../assets/images/start.es.png"),
};

function TopicBadge({ children }: { children: string }) {
  return (
    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
      {children}
    </span>
  );
}

interface TopicButtonProps {
  children: string;
  onClick: () => void;
  icon?: ReactNode;
  selected?: boolean;
}

function TopicButton({ children, onClick, icon, selected }: TopicButtonProps) {
  const selectedStyle = selected ? "bg-amber-200" : "bg-teal-100";
  return (
    <button
      type="button"
      className={`px-3 py-2 text-xs font-medium text-center inline-flex gap-2 items-center text-blue-800 rounded-lg hover:bg-amber-200 focus:ring-1 focus:outline-none focus:bg-amber-200 ${selectedStyle}`}
      onClick={onClick}
    >
      {icon}
      <span>{children}</span>
    </button>
  );
}

interface TopicsComponentProps {
  topics: Books;
  onClick: (topic: string) => void;
  active: string;
}

function TopicsComponent({ topics, onClick, active }: TopicsComponentProps) {
  return (
    <div className="flex flex-wrap gap-2 mb-4 p-1">
      {Object.keys(topics).map((topic) => (
        <TopicButton
          onClick={() => onClick(topic)}
          selected={topic === active}
          key={topic}
        >
          {topic}
        </TopicButton>
      ))}
    </div>
  );
}

function convertDurationToSeconds(duration: string) {
  const [minutes, seconds] = duration.split(":").map(Number);
  return minutes * 60 + seconds;
}

function formatDuration(duration: number, currentProgress: number) {
  // decount currentProgress from duration
  duration -= currentProgress;
  const minutes = Math.floor(duration / 60);
  const seconds = Math.floor(duration % 60);
  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
}

function BookCard({ book, active }: { book: Book, active?: boolean}) {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [duration, setDuration] = useState(0);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [buffered, setBuffered] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [ended, setEnded] = useState(false);


  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current?.pause();
      setIsPlaying(false);
    } else {
      audioRef.current?.play();
      setIsPlaying(true);
    }
  };

  // interface ProgressCSSProps extends React.CSSProperties {
  //   "--progress-width": number;
  //   "--buffered-width": number;
  // }

  // const progressBarWidth = isNaN(currentProgress / duration)
  //   ? 0
  //   : currentProgress / duration;
  // const bufferedWidth = isNaN(buffered / duration) ? 0 : buffered / duration;
  // const progressStyles: ProgressCSSProps = {
  //   "--progress-width": progressBarWidth,
  //   "--buffered-width": bufferedWidth,
  // };

  function ProgressBar({
    currentProgress,
    duration,
    ended
  }: {
    currentProgress: number,
    duration: number,
    ended: (event: boolean) => void
  }) {
    let progress = currentProgress / duration;

    useEffect(() => {
      progress = currentProgress / duration;
    }, [duration]);

    useEffect(() => {
      if (audioRef.current?.ended) {
        setIsPlaying(false);
        ended(true);
      }
    }, [audioRef]);

    return (
      <span className="rounded-sm bg-blue-400 h-1 w-full overflow-hidden">
        <span className="block bg-white h-1" style={{ width: `${progress * 100}%` }} />
      </span>
    );
  }

  return (
    <>
      {active ? (
      <div className="flex flex-col items-center justify-center grow w-[220px]">
        <div className="flex w-full grow bg-blue-50 rounded-t-xl p-4">
          <img
            className="mx-auto rounded-lg shadow-sm min-w-[90px] object-cover"
            width="110"
            alt={book.title}
            src={book.cover}
          />
        </div>
        <div className="player flex w-full grow bg-blue-800 rounded-b-xl gap-2 p-2">
          <button
            type="button"
            className="rounded-full text-xl bg-white text-blue-800 p-2"
            onClick={togglePlayPause}
            aria-label={isPlaying ? "Pause" : "Play"}
          >
            {isPlaying ? <HiPause /> : <HiPlay />}
            
          </button>
          <div className="flex flex-col text-white gap-1 justify-start w-full">
            <span className="text-xs text-left">
              Ouvir amostra
            </span>
            <div className="flex gap-2 text-left items-center">
              <ProgressBar
                currentProgress={currentProgress}
                duration={convertDurationToSeconds(book.audioLength || "12:00")}
                ended={(event) => setEnded(event)}
              />
              <span className="text-xs">
                {formatDuration(
                  convertDurationToSeconds(book.audioLength || "12:00"), 
                  currentProgress
                )}
              </span>
              <audio
                ref={audioRef}
                preload="metadata"
                src={book.audio}
                onDurationChange={(e) => setDuration(e.currentTarget.duration)}
                onTimeUpdate={(e) => {
                  setCurrentProgress(e.currentTarget.currentTime);
                }}
              >
                Your browser does not support the
                <code>audio</code> element.
              </audio>
            </div>
          </div>
        </div>
        <div className={`flex text-xs p-2 mt-2 bg-blue-200 text-blue-900 font-semibold rounded-xl transition-all duration-150 ease-in-out ${ended ? "opacity-1": "opacity-0"}`}>
        Ouça o microbook completo desbloqueando a biblioteca
        </div>
      </div>
      ) : (
      <div className="flex flex-col items-center justify-center grow w-[120px]">
        <div className="flex w-full grow bg-blue-50 rounded-xl p-4">
          <img
            className="mx-auto rounded-lg shadow-sm min-w-[90px] object-cover"
            width="90"
            alt={book.title}
            src={book.cover}
          />
        </div>
      </div>
      )}
    </>
  );
}

function BookCardList({ books }: { books: Book[] }) {
  const [items, setItems] = useState(books);
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % books.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + books.length) % books.length);
  };

  return (
    <div className="carousel-container">
      <button onClick={prevSlide} className="carousel-button rounded-full">
        <HiChevronLeft />
      </button>
      <div className="carousel">
        {[currentIndex - 1, currentIndex, currentIndex + 1].map((index) => {
          const adjustedIndex = (index + books.length) % books.length;
          return (
            <div
              key={adjustedIndex}
              className={`carousel-item ${index === currentIndex ? 'active' : ''}`}
            >
              <BookCard book={books[adjustedIndex]} active={index === currentIndex} />
            </div>
          );
        })}
      </div>
      <button onClick={nextSlide} className="carousel-button rounded-full">
        <HiChevronRight />
      </button>
    </div>
  );
}

function Product() {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const { offerType } = useParams();
  const [screenData, setScreenData] = useScreenContext();
  const [getSessionStorageOffer, setSessionStorageOffer] = useSessionStorage(
    "offer",
    "foo"
  );

  const [activeTopic, setActiveTopic] = useState<string>("Biografias e Memórias");
  const [showAllTopics, setShowAllTopics] = useState(false);
  
  const [_, setSessionStorageVariant] = useSessionStorage("variant");

  const variant = searchParams.get("variant");

  const exp30feature = useFeatureIsOn("exp-30-feature");

  const stepNumber = useStepNumberManager(22);

  useEffect(() => {
    if (searchParams.get("offer")) {
      setSessionStorageOffer(searchParams.get("offer") || "");
    } else {
      setSessionStorageOffer("");
    }

    if (variant) {
      setSessionStorageVariant(variant);
    }

    setScreenData(() => ({
      ...screenData,
      submitLabel: "Desbloqueie toda biblioteca",
      nextPath: relativePath("/select-plan", offerType),
      progress: stepNumber,
      step: stepNumber,
    }));
  }, []);

  return (
    <main className="flex flex-col h-full justify-between gap-2 mb-[100px]">
      <header>
        <h1 className="mb-4 mt-5 text-lg font-bold tracking-tight text-gray-900">
          Aprenda algo novo todos os dias
        </h1>
        <h2>Acesse as principais ideias de +3000 bestsellers em aproximadamente 12 minutos com o 12min App.</h2>
      </header>

      <div className="container p-2 py-6 md:p-6 mt-4">
        <div
          className={`relative flex flex-wrap gap-2 mb-4 ${
            showAllTopics ? "pb-14" : "overflow-hidden h-[138px]"
          }`}
        >
          <TopicsComponent topics={books} onClick={(topic) => setActiveTopic(topic)} active={activeTopic} />
          <button
            className="topics-toggle__button absolute flex justify-center items-center bottom-0 h-16 text-blue-800 text-3xl w-full"
            onClick={() => setShowAllTopics((prev) => !prev)}
          >
            {showAllTopics ? <HiChevronUp /> : <HiChevronDown className="animate-bounce" />}
          </button>
        </div>

        <div className="flex gap-2 justify-center justify-items-center">          
          <BookCardList books={books[activeTopic]} />
        </div>
      </div>
    </main>
  );
}

export default Product;
