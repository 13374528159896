import { useEffect } from "react";
import { relativePath, useScreenContext, useStepNumberManager } from "../../Layout";
import MascotLike from "../../../assets/images/mascot-like.png";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function WeProtectYou() {
  const { t } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const { offerType } = useParams();
  
  const isSlFunnel = offerType === "sl";

  const stepNumber = useStepNumberManager(isSlFunnel ? 12 : 14);

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: t("layout.cta_continue"),
      nextPath: isSlFunnel ? relativePath("/info", offerType) : "thank-you",
      progress: stepNumber,
      step: stepNumber,
      votting: false,
    }));
  }, []);

  return (
    <main className="flex flex-col h-full justify-between gap-6">
      <header>
        <h1 className="mx-0 mb-4 mt-5 text-xl font-bold tracking-tight text-gray-900  md:text-4xl">
          {t("we_protect_you.headline")}
        </h1>
      </header>
      <img
        className="mx-auto w-full max-w-sm px-10"
        alt="Mascot thumbs up"
        src={MascotLike}
      />
    </main>
  );
}

export default WeProtectYou;
