import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useScreenContext, relativePath, useStepNumberManager } from "../../Layout";
import { useNavigate, useParams } from "react-router-dom";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

interface ImageOptions {
  focus: string;
  motivation: string;
  effort: string;
  other: string;
}

interface StatsImages {
  br: ImageOptions;
  en: ImageOptions;
  es: ImageOptions;
}

type GoalRequirements = "focus" | "motivation" | "effort" | "other";

function GoalRequirementsInfo() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [screenData, setScreenData] = useScreenContext();
  const { offerType } = useParams();

  const isDirectSale = offerType === "direct";
  const isRetSale = offerType === "ret";
  const isSlFunnel = offerType === "sl";

  const answer: GoalRequirements = screenData.userPreferences.goalRequirements;
  
  const statsImage: StatsImages = {
    br: {
      focus: require("../../../assets/images/chart/goal_req/goal_req_a_br.png"),
      motivation: require("../../../assets/images/chart/goal_req/goal_req_b_br.png"),
      effort: require("../../../assets/images/chart/goal_req/goal_req_c_br.png"),
      other: require("../../../assets/images/chart/goal_req/goal_req_b_br.png"),
    },
    en: {
      focus: require("../../../assets/images/chart/goal_req/goal_req_a_en.png"),
      motivation: require("../../../assets/images/chart/goal_req/goal_req_b_en.png"),
      effort: require("../../../assets/images/chart/goal_req/goal_req_c_en.png"),
      other: require("../../../assets/images/chart/goal_req/goal_req_b_en.png"),
    },
    es: {
      focus: require("../../../assets/images/chart/goal_req/goal_req_a_es.png"),
      motivation: require("../../../assets/images/chart/goal_req/goal_req_b_es.png"),
      effort: require("../../../assets/images/chart/goal_req/goal_req_c_es.png"),
      other: require("../../../assets/images/chart/goal_req/goal_req_b_es.png"),
    }
  };

  const getStatsImage =  (
    language: "br" | "en" | "es",
    option: "focus" | "motivation" | "effort" | "other"
  ) => {
    const languageDefault = "br";
    const languagePrefix = language || languageDefault;
    const options = ["focus", "motivation", "effort", "other"];

    // Verify if the language is valid
    const languageKey = statsImage[languagePrefix] ? languagePrefix : languageDefault;

    // verify if the option is valid
    const optionKey = options.includes(option) ? option : "other";

    return statsImage[languageKey][optionKey];
  }

  if (!answer) {
    const knowledgePath = relativePath("/goal-requirements", offerType);
    navigate(knowledgePath);
  }

  const stepNumber = useStepNumberManager(isSlFunnel ? 10 : 12);

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: t("layout.cta_continue"),
      nextPath: isSlFunnel ? relativePath("/info", offerType) : "priority-areas",
      progress: stepNumber,
      step: stepNumber,
      votting: false,
    }));
  }, []);

  return (
    <main className="flex flex-col h-full justify-between gap-6">
      <header>
        <h1 className="mx-5 mb-4 mt-5 text-2xl font-bold tracking-tight text-gray-900  md:text-4xl">
          {t(`goal_requirements_info.headline.${answer}`)}
        </h1>
      </header>
      <img
        className="mx-auto w-full max-w-sm px-10"
        alt="Chart showing your progress"
        src={getStatsImage(i18n.language as "br" | "en" | "es", answer)}
      />
    </main>
  );
}

export default GoalRequirementsInfo;
