import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useScreenContext, Option } from "../Layout";

function ReadingHabit() {
  const { t } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: t("layout.cta_continue"),
      nextPath: "age",
      progress: 7,
    }));
  }, []);

  const handleOptionSelection = (option: string) => {
    console.log(option);
    setScreenData(() => ({
      ...screenData,
      userPreferences: {
        ...screenData.userPreferences,
        time: option,
      },
    }));
  };

  return (
    <div>
      <h1 className="mb-4 text-2xl font-bold tracking-tight text-gray-900  md:text-5xl">
        Qual sua meta de leitura semanal?
      </h1>
      <p
        className="text-md mb-0 px-7 py-4 font-normal text-gray-900 lg:text-xl"
        dangerouslySetInnerHTML={{
          __html: t("start.subheadline", {
            interpolation: { escapeValue: false },
          }),
        }}
      ></p>
      <div className="mt-10">
        <Option onClick={() => null}>12 minutos</Option>
        <Option onClick={() => null}>12-30 minutos</Option>
        <Option onClick={() => null}>31-60 minutos</Option>
        <Option onClick={() => null}>60+ minutos</Option>
      </div>
    </div>
  );
}

export default ReadingHabit;
