import { useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useScreenContext } from "../../Layout";
import { useParams } from "react-router-dom";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

interface ImageOptions {
  option_1: string;
  option_2: string;
  option_3: string;
}

interface StatsImages {
  br: ImageOptions;
  en: ImageOptions;
  es: ImageOptions;
}

const statsImage: StatsImages = {
  br: {
    option_1: require("../../../assets/images/chart/br/option_1.png"),
    option_2: require("../../../assets/images/chart/br/option_2.png"),
    option_3: require("../../../assets/images/chart/br/option_3.png"),
  },
  en: {
    option_1: require("../../../assets/images/chart/en/option_1.png"),
    option_2: require("../../../assets/images/chart/en/option_2.png"),
    option_3: require("../../../assets/images/chart/en/option_3.png"),
  },
  es: {
    option_1: require("../../../assets/images/chart/es/option_1.png"),
    option_2: require("../../../assets/images/chart/es/option_2.png"),
    option_3: require("../../../assets/images/chart/es/option_3.png"),
  },
};

const getStatsImage = (
    language: 'br' | 'en' | 'es', 
    option: 'option_1' | 'option_2' | 'option_3'
  ) => {
  const languageDefault = 'br';
  const languagePrefix = language || languageDefault;
  const options = ['option_1', 'option_2', 'option_3'];

  // Verify if the language is valid
  const languageKey = statsImage[languagePrefix] ? languagePrefix : languageDefault;

  // verify if the option is valid
  const optionKey = 'option_3'; //options.includes(option) ? option : 'option_3';

  return statsImage[languageKey][optionKey];
};


function ReadingStats() {
  const { t, i18n } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const format = screenData.userPreferences?.format || "audiobook";

  const { offerType } = useParams();

  const isDirectSale = offerType === "direct";

  interface Options {
    [key: string]: 'option_1' | 'option_2' | 'option_3';
  }
  
  const options: Options = {
    "fisical": "option_1",
    "ebook": "option_2",
    "audiobook": "option_3",
  };
  

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: t("layout.cta_continue"),
      nextPath: "time-saved-sl",
      progress: 4,
      step: 4,
    }));
  }, []);

  return (
    <div>
      <h1 className="mb-4 mt-5 text-4xl text-left font-bold tracking-tight text-gray-900  md:text-5xl lg:text-6xl">
        <Trans i18nKey={"reading_stats_sl.headline"}>
          Parabéns por começar sua jornada para <span className="text-blue-600">ler mais rápido e melhor</span>.
        </Trans>
      </h1>
      <h2 className="text-left ">
        {t("reading_stats_sl.subheadline")}
      </h2>
      <img
        className="mx-auto py-10"
        alt="Chart showing your progress"
        src={getStatsImage(i18n.language as 'br' | 'en' | 'es', options[format])}
      />
    </div>
  );
}

export default ReadingStats;
