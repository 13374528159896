import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useScreenContext, relativePath, useStepNumberManager } from "../Layout";
import { useParams } from "react-router-dom";
// import { useFeatureIsOn } from "@growthbook/growthbook-react";

function TimeSaved() {
  const { t } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const { offerType } = useParams();

  const isDirectSale = offerType === "direct";

  const stepNumber = useStepNumberManager(6);

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: t("layout.cta_continue"),
      nextPath: relativePath("/knowledge-question", offerType),
      progress: stepNumber,
      step: stepNumber,
    }));
  }, []);

  return (
    <main className="flex flex-col h-full justify-between gap-10">
      <header>
        <h1 className="mb-4 mt-5 text-3xl font-semibold leading-tight tracking-normal text-gray-900  md:text-5xl lg:text-6xl">
          {t("time_saved.headline.part_1")}{" "}
          <span className="font-bold text-blue-600">
            {t("time_saved.headline.part_2")}
          </span>{" "}
          {t("time_saved.headline.part_3")}
        </h1>
      </header>
    </main>
  );
}

export default TimeSaved;
