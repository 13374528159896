import Welcome from "./Welcome";
import WelcomeExp from "./WelcomeExp";
import ReadingList from "./ReadingList";

import { useFeatureIsOn } from "@growthbook/growthbook-react";

function Start() {
  const isExp47 = useFeatureIsOn("exp-47-first-page");
  return isExp47 ? <WelcomeExp /> : <Welcome />;
}

export default Start;
