import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useScreenContext, Option, relativePath } from "../Layout";
import { useNavigate, useParams } from "react-router-dom";

function Topics() {
  const { t } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const navigate = useNavigate();
  const { offerType } = useParams();

  const isDirectSale = offerType === "direct";
  const redirectPath = relativePath("/thank-you", offerType);

  const clusters = [
    {
      name: "entrepreneurship",
      categoryIds: [49, 50],
    },
    {
      name: "emotional_intelligence",
      categoryIds: [57, 44, 40, 47],
    },
    {
      name: "corporate_ladder",
      categoryIds: [30, 37, 41, 31],
    },
    {
      name: "new_facts",
      categoryIds: [35, 38, 32, 36, 42, 34, 48, 33, 39, 43, 29, 46, 45],
    },
  ];

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: false,
      nextPath: redirectPath,
      progress: 56,
    }));
  }, []);

  const handleOptionSelection = (cluster: any) => {
    console.log("cluster", cluster);

    setScreenData(() => ({
      ...screenData,
      userPreferences: {
        ...screenData.userPreferences,
        topics: cluster.categoryIds,
        currentCluster: cluster.name,
      },
      submitLabel: false,
    }));

    navigate(redirectPath);
  };

  return (
    <div>
      <h1 className="mb-4 text-2xl font-bold tracking-tight text-gray-900  md:text-5xl">
        {t("topics.headline")}
      </h1>
      <div className="mt-10 mb-2 text-right text-sm"></div>
      <div className="mt-0 mb-20">
        {clusters.map((category: any, index: number) => (
          <Option key={index} onClick={() => handleOptionSelection(category)}>
            <div className="flex h-14 text-center">
              <span className="m-auto">
                {t(`topics.clusters.${category.name}`)}
              </span>
            </div>
          </Option>
        ))}
      </div>
    </div>
  );
}

export default Topics;
