import { Progress } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { Link, Outlet, useLocation, useOutletContext, useParams, useSearchParams } from "react-router-dom";
import Icon from "../assets/images/icon.svg";
import { useTranslation } from "react-i18next";
import * as rudderanalytics from "../lib/rudderstack";
import { useSessionStorage } from "react-use";
import { useTimer } from "react-timer-hook";

import { getCategories } from "../lib/data";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { use } from "i18next";

type ScreenContext = {
  submitLabel?: string | boolean;
  nextPath: string;
  progress: number;
  progressVariation: number;
  votting?: boolean;
  topics?: Array<any>;
  urlParams?: Object;
  userPreferences?: Object;
  booksUnliked?: Array<number>;
  totalSteps: number;
  step?: number;
  disabledButton?: boolean;
  co?: string | null; // accomodation user i18n 
  bestPrice?: boolean;
  offDiscount?: string | null;
  utmSource?: string | object | null;
  searchParams?: Record<string, string> | null;
};

export const totalStepsByFunnel: { [key: string]: number } = {
  direct: 25,
  ret: 25,
  app: 25,
  lt: 25,
  ltf: 25,
  yt: 25,
  tw: 25,
  appf: 25,
  sl: 19
};

export const useStepNumberManager = (step: number) => {
  const url = window.location.href;
  const isExp46 = useFeatureIsOn("exp-46-social-proof");

  const stepNumber = step;
  
  const jump = url.includes("/start")
    || url.includes("/talking-about-2")

  if (isExp46 && !jump) {
    return stepNumber + 1;
  }

  return stepNumber;
};

export const PlanData = (variationActive?: boolean) => {
  const exp38ptTwoOffers = useFeatureIsOn("exp-38-pt-two-offers");

  if (exp38ptTwoOffers) {
    const main = {
      yearly: {
        plan_name: "Premium Anual",
        plan_price_per_month: "R$16,88/mês",
        full_pricing: "",
        save_offer: "-53% OFF",
        plan_price_per_month_without_discount: "R$35,92/mês",
        old_price: "",
        free_trial: "Período de garantia incondicional por 7 dias",
      },
      monthly: {
        plan_name: "",
        plan_price_per_month: "R$39,77/mês",
        full_pricing: "",
        save_offer: "",
        plan_price_per_month_without_discount: "R$39,77/mês",
        free_trial: "Período de garantia incondicional por 7 dias",
      },
    }

    const variation = {
      yearly: {
        plan_name: "Premium Anual",
        plan_price_per_month: "R$13,29/mês",
        full_pricing: "",
        save_offer: "-63% OFF",
        plan_price_per_month_without_discount: "R$35,92/mês",
        old_price: "R$16,88/mês",
        free_trial: "Período de garantia incondicional por 7 dias",
      },
      monthly: {
        plan_name: "",
        plan_price_per_month: "R$39,77/mês",
        full_pricing: "",
        save_offer: "",
        plan_price_per_month_without_discount: "R$39,77/mês",
        free_trial: "Período de garantia incondicional por 7 dias",
      },
    }

    return variationActive ? variation : main;
  }
};

export const progressValue = (progress: number, steps?: number) => {
  const totalSteps = steps || 25;
  return progress / totalSteps * 100;
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const funnelData = (funnel: string) => {
  const funnels = () => {
    const url = window.location.href;
    if (url.includes("/direct")) return "wizard-direct";
    if (url.includes("/ret")) return "wizard-ret";
    if (url.includes("/app")) return "wizard-app";
    if (url.includes("/lt")) return "wizard-lt";
    if (url.includes("/ltf")) return "wizard-ltf";
    if (url.includes("/yt")) return "wizard-yt";
    if (url.includes("/tw")) return "wizard-tw";
    if (url.includes("/appf")) return "wizard-appf";
    if (url.includes("/sl")) return "wizard-sl";
  }

  const offerCodeList: Record<string, string> = {
    twitter: "xrnblfqt",
    default: "7d18zm3s",
    exp16: "6reyasj1",
    exp17: "6reyasj1",
  };

  const discount = (funnel: string) => {
    const discountCodeList: Record<string, string> = {
      default: "promo63off",
      "wizard-yt": "promo63off",
    };
    const discountCode = discountCodeList[funnel] || discountCodeList.default;
    return discountCode;
  }
}

/**
 * Returns the relative path based on the provided parameters.
 * If the offerType is "direct", the path will be prefixed with "/direct".
 * If the offerType is "ret", the path will be prefixed with "/ret".
 * If the offerType is not provided or is not "direct" or "ret", the path will be returned as is.
 * If nullDefault is provided, it will be returned instead of the path when offerType is not "direct" or "ret".
 * @param path - The path to be modified.
 * @param offerType - The type of offer.
 * @param nullDefault - The default value to be returned when offerType is not "direct" or "ret".
 * @returns The modified relative path.
 */
export const relativePath = (path: string, offerType?: string, nullDefault?: string) => {
  const paths: { [key: string]: string } = {
    direct: `/direct${path}`,
    ret: `/ret${path}`,
    app: `/app${path}`,
    lt: `/lt${path}`,
    ltf: `/ltf${path}`,
    yt: `/yt${path}`,
    tw: `/tw${path}`,
    appf: `/appf${path}`,
    sl: `/sl${path}`,
  };

  if (offerType && offerType in paths) {
    return paths[offerType];
  }

  return nullDefault ? nullDefault : path;
}

export const useNextLinkBuild = (linkIn: string) => {
  const [screenData, setScreenData] = useScreenContext();
  const { utmSource } = screenData;

  const path = `${linkIn}?utm_source=welcome`;

  return path;
}

export const Option = ({
  children,
  onClick,
}: {
  children: any;
  onClick: () => void;
}) => {
  const ref = useRef() as any;

  const handleClick = () => {
    onClick();
    ref.current.classList.toggle("bg-amber-200");
  };

  return (
    <button
      ref={ref}
      type="button"
      className="text-md font-md my-2 w-full rounded-md bg-amber-100 px-5 py-2.5 text-gray-900 hover:bg-amber-300 transition duration-300 ease-in-out cursor-pointer"
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

// Option radio group
export const OptionRadio = ({
  children,
  onClick,
  name,
  value,
  selected,
}: {
  children?: any;
  onClick: () => void;
  name: string;
  value: string;
  selected?: boolean;
}) => {
  const ref = useRef() as any;

  const handleClick = () => {
    onClick();
  };

  return (
    <div className="flex w-full">
      <input type="radio" id={value} name={name} value={value} className="hidden peer" required />
      <label
        htmlFor={value}
        onClick={handleClick}
        className="text-md font-md my-2 w-full rounded-md bg-amber-100 px-5 py-2.5 text-gray-900 cursor-pointer peer-checked:bg-amber-200 hover:bg-amber-300 transition duration-300 ease-in-out"
      >                           
        <div className="block">
          {children}
        </div>
      </label>
    </div>
  );
};

export const OptionGridButton = ({
  children,
  onClick,
}: {
  children: any;
  onClick: () => void;
}) => {
  const ref = useRef() as any;

  const handleClick = () => {
    onClick();
    ref.current.classList.toggle("bg-amber-200");
  };

  return (
    <button
      ref={ref}
      type="button"
      className="text-sm font-sm my-2 rounded-md bg-amber-100 px-1 py-2.5 text-gray-900"
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

const HelloBarStar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 pb-1 inline-block align-middle">
    <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
  </svg>
)


const HelloBar = ({ text, variant }: {  text?: string, variant?: boolean }) => {
  const { t } = useTranslation();
  const { offerType } = useParams();
  // const newPricing = useFeatureIsOn("yearly-plan-pricing-feature");
  const newPricing = useFeatureIsOn("exp-38-pt-two-offers");

  const isDirectSale = offerType === "direct";
  const isRetSale = offerType === "ret";
  const isAppFunnel = offerType === "app";
  const isLtFunnel = offerType === "lt" || offerType === "ltf";
  const isYtFunnel = offerType === "yt";

  const helloBarText = () => {
    if (isDirectSale) return 'hellobar';
    if (isAppFunnel) return 'hellobar';
    if (isRetSale) return 'hellobar_ret';
    if (isLtFunnel) return 'hellobar_lt';
    if (isYtFunnel) return 'hellobar_yt';
    return 'trial_hellobar';
  }

  return (
    <div className="text-center bg-red-700 text-white py-5 font-bold" >
      <div className="px-2">
        {t(newPricing ? 'hellobar_variant' : helloBarText())}
      </div>
    </div>
  );
}

/**
 * Renders a component that displays the current step and total number of steps in a wizard.
 * @param steps - The total number of steps in the wizard.
 * @param currentStep - The current step in the wizard (optional).
 * @returns The StepsComponent.
 */
export const StepsComponent = (
  { steps, currentStep }: 
  { steps: number, currentStep?: number }
): JSX.Element => {
  return (
    <div className="font-bold text-sm text-right my-2 tracking-wide">
      <span className="text-blue-600">
        { currentStep || 0 }
        <span className="mx-1">/</span>
      </span>
      { steps }
    </div>
  );
}

/**
 * Renders the UserProgressComponent based on the screen data.
 * If the current location is in the blockListLocations, it renders a progress bar.
 * Otherwise, it renders a StepsComponent and a progress bar.
 *
 * @param {Object} props - The component props.
 * @param {ScreenContext} props.screenData - The screen data containing information about the current step and total steps.
 * @returns {JSX.Element} - The rendered LayoutHeader.
 */
const LayoutHeader = ({
  screenData,
}: {
  screenData: ScreenContext;
}): JSX.Element => {
  const location = useLocation();
  const { i18n } = useTranslation();

  const { offerType } = useParams();
  const isRetSale = offerType === "ret";
  const isTwFunnel = offerType === "tw";
  const isYtFunnel = offerType === "yt";
  const isDirectFunnel = offerType === "direct";

  const exp38ptTwoOffers = useFeatureIsOn("exp-38-pt-two-offers");
  const exp43ptTwoOffersYt = useFeatureIsOn("exp-43-two-offers-yt");
  const expTwoOffers = exp38ptTwoOffers || exp43ptTwoOffersYt;

  const isMidYeah70Offer = isTwFunnel;
  const isMidYeah75Offer = isRetSale || isYtFunnel;
  const isOfferBanner = isMidYeah70Offer || isMidYeah75Offer;

  const topBannerOffer: { [key: number]: any } = {
    20: require("../assets/images/offer/top-banner-20-pt.webp"),
    30: {
      br: require("../assets/images/offer/top-banner-30-pt.webp"),
      en: require("../assets/images/offer/top-banner-30-en.webp"),
      es: require("../assets/images/offer/top-banner-30-es.webp"),
    },
    40: require("../assets/images/offer/top-banner-40.webp"),
    60: require("../assets/images/offer/top-banner-60.webp"),
    63: {
      br: require("../assets/images/offer/top-banner-63-pt.webp"),
      en: require("../assets/images/offer/top-banner-63-en.webp"),
      es: require("../assets/images/offer/top-banner-63-es.webp"),
    },
    70: require("../assets/images/offer/top-banner-70.webp"),
    75: {
      br: require("../assets/images/offer/top-banner-75-pt.webp"),
      en: require("../assets/images/offer/top-banner-75-en.webp"),
      es: require("../assets/images/offer/top-banner-75-es.webp"),
    },
  }

  interface OfferBanner {
    [key: string]: any;
  }

  const topBannerOfferTrial: OfferBanner = {
    30: {
      en: require("../assets/images/offer/top-banner-30-trial-en.webp"),
      es: require("../assets/images/offer/top-banner-30-trial-es.webp"),
    },
    70: {
      br: require("../assets/images/offer/top-banner-70-trial-pt.webp"),
      en: require("../assets/images/offer/top-banner-70-trial-en.webp"),
      es: require("../assets/images/offer/top-banner-70-trial-es.webp"),
    }
  }
  
  const bannerOfferImg = () => {
    if (isYtFunnel) return topBannerOffer[20];
    if (isTwFunnel) return topBannerOffer[30];
    if (isDirectFunnel && i18n.language === "br") return topBannerOffer[40];
    if (isDirectFunnel && i18n.language === "en") return topBannerOffer[30]["en"];
    if (isRetSale) return topBannerOffer[60];
    if (isMidYeah70Offer) return topBannerOffer[70];
    if (isMidYeah75Offer) return topBannerOffer[75][i18n.language || "br"];
    if (
      i18n.language === "es" && (
        screenData.co === "sp" || 
        screenData.co === "mx"
      )) return topBannerOffer[75][i18n.language || "es"];
    if (i18n.language === "es") return topBannerOffer[30]["es"];
    if (expTwoOffers && screenData.bestPrice) return topBannerOffer[75]["br"]
    return topBannerOffer[63][i18n.language || "br"];
  };

  const banner = bannerOfferImg();

  const bannerOfferTrialImg = (): string => {
    if (i18n.language === "en") return topBannerOfferTrial[30]["en"];
    if (i18n.language === "es") return topBannerOfferTrial[30]["es"];
    if (isMidYeah70Offer) return topBannerOfferTrial[70][i18n.language || "br"];
    return topBannerOfferTrial[70][i18n.language || "br"];
  }

  const trialBanner = bannerOfferTrialImg();

  const bannerImage = isOfferBanner || isDirectFunnel ? banner : trialBanner;

  const notShowHeader = [
    "/direct/landing-b",
    "/ret/landing-b",
    "/app/landing-b",
    "/landing-b"
  ]

  if (notShowHeader.includes(location.pathname)) {
    return <></>;
  }
  
  const blockListLocations = [
    "/direct/landing",
    "/ret/landing",
    "/app/landing",
    "/landing"
  ]

  if (expTwoOffers && !screenData.bestPrice) {
    return (
      <>
        <div className="px-4 mt-4">
          <StepsComponent steps={screenData.totalSteps} currentStep={screenData.step} />
          <Progress progress={progressValue(screenData.progress)} color="blue" size="sm" />
          <div className="py-4">
            <img className="h-8 w-8 rounded-full" src={Icon} alt="12min Logo" />
          </div>
        </div>
      </>
    );
  }

  if (blockListLocations.includes(window.location.pathname) && (!expTwoOffers)) {
    return (
      <>
        <div className="px-4">
          <Progress progress={progressValue(screenData.totalSteps / 2)} color="blue" size="sm" />
          <div className="py-4">
            <img className="h-8 w-8 rounded-full" src={Icon} alt="12min Logo" />
          </div>
        </div>
      </>
    );
  }

  return (
    <header>
      {(location.pathname.includes("/select-plan-v2")) ? (
        <>
          <div className="p-4 pb-2">
            <TimerSelectPlanV2Component />
          </div>
          <div className="px-4">
            <div className="flex justify-between py-4 mt-3">
              <img className="h-8 w-8 rounded-full" src={Icon} alt="12min Logo" />
              <StepsComponent steps={screenData.totalSteps} currentStep={screenData.step} />
            </div>
          </div>
        </>
      ) : (
        (
          (location.pathname.includes("/select-plan") && !expTwoOffers) ||
          (location.pathname.includes("/select-plan") && expTwoOffers && screenData.bestPrice)
        ) ? (
          <>
            <div className="flex justify-center bg-[#4357ae] text-white gap-4">
              <img src={bannerImage} alt="Esqueceu do que prometeu para 2024?" />
            </div>
            <div className="px-4">
            <StepsComponent steps={screenData.totalSteps} currentStep={screenData.step} />
              <Progress progress={progressValue(screenData.progress, undefined)} color="blue" size="sm" />
              <div className="py-4">
                <img className="h-8 w-8 rounded-full" src={Icon} alt="12min Logo" />
              </div>
            </div>
          </>
        ) : (
          <>
            { 
              ((location.pathname.includes("/select-plan") && true)
              || location.pathname.includes("/select-plan-v2") )
              && !location.pathname.includes("/sl/select-plan-sl")
              && <HelloBar variant={screenData.bestPrice} />
            }
            <div className="px-4">
              <StepsComponent steps={screenData.totalSteps} currentStep={screenData.step} />
              <Progress progress={progressValue(screenData.progress, undefined)} color="blue" size="sm" />
              <div className="py-4">
                <img className="h-8 w-8 rounded-full" src={Icon} alt="12min Logo" />
              </div>
            </div>
          </>
        )
      )}
    </header>
  );
};

export const TimerSelectPlanV2Component = () => {
  const time = new Date();
  time.setSeconds(time.getSeconds() + 833);

  const { seconds, minutes, hours } = useTimer({ expiryTimestamp: time });

  return (
    <div className="flex justify-between items-center text-center text-sm text-gray-700 font-light">
      <div className="flex flex-col">
        <span className="font-semibold">Oferta expira em</span>
        <div className="flex flex-col">
          <div className="inline-block text-3xl font-bold text-black">
            {minutes.toString().padStart(2, "0")}
            <span className="px-2">:</span>
            {seconds.toString().padStart(2, "0")}
          </div>
          <div className="flex justify-around font-semibold">
            <span>min</span>
            <span>seg</span>
          </div>
        </div>
      </div>
      <div className="">
        <a
          href="#select-plan-a"
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold p-4 rounded-xl relative overflow-hidden before:animate-button-ping button-get-plan before:rounded-xl before:bg-sky-400 before:opacity-75"
        >
          Obter Meu Plano
        </a>
      </div>
    </div>
  );

}

function redirect(url: string) {
  window.location.href = url;
}

function Layout() {
  const { t } = useTranslation();
  const { offerType } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const isLandingPage = useLocation().pathname.includes("landing");
  const submitButtonRef = useRef<HTMLAnchorElement>(null);
  const [newWelcome, setNewWelcome] = useState(false);
  const [allSearchParams, setAllSearchParams] = useState({});

  const wizardV2 = useFeatureIsOn("wizard-v2-feature");
  const newSelectPlan = useFeatureIsOn("new-select-plan-screen-feature");
  const exp19feature = useFeatureIsOn("exp-19-feature");
  const exp17feature = useFeatureIsOn("exp-17-feature");
  const exp25fb = useFeatureIsOn("exp-25-fb-redirect");
  const exp26yt = useFeatureIsOn("exp-26-yt-redirect");
  const exp29feature = useFeatureIsOn("exp-29-product");
  const isExp46 = useFeatureIsOn("exp-46-social-proof");

  const isDirectSale = offerType === "direct";
  const isRetSale = offerType === "ret";
  const isAppFunnel = offerType === "app";
  const isLtFunnel = offerType === "lt";
  const isSlFunnel = offerType === "sl";

  const query = useQuery();

  // if(window.location.href.includes("ret")) {
  //   sessionStorage.setItem("utm_source", "ret");
  // }

  useEffect(() => {
    if (!location.pathname.includes("/landing") && location.pathname.includes("/start")) {
      if (exp25fb && !isRetSale) {
        console.log("exp25fb", exp25fb);
        redirect("/ret/start?offDiscount=PROMO75OFFFB");
      }

      if (exp26yt && !isRetSale) {
        console.log("exp26yt", exp26yt);
        redirect("/ret/start?offDiscount=PROMO75OFFYT");
      }
      
    }
  }, [exp25fb, exp26yt]);


  if(window.location.href.includes("twitter")) {
    sessionStorage.setItem("utm_source", "twitter");
  }

  /**
   * Retrieves all search parameters and returns them as an object.
   * @returns An object containing all search parameters.
   */
  function getAllSearchParams() {
    const allSearchParams: Record<string, string> = {};
    Array.from(searchParams.entries()).forEach(([key, value]) => {
      allSearchParams[key] = value;
    });

    return allSearchParams;
  }

  useEffect(() => {
    const allSearchParamsRoot = getAllSearchParams();
    setAllSearchParams(allSearchParamsRoot);
  }, [searchParams]);

  useEffect(() => {
    setNewWelcome(window.location.href.includes("reading-list"))
  }, [window.location.href])

  if (wizardV2) {
    window.location.href = "https://wizard-v2.12min.com";
  }

  /**
   * Checks if the current URL includes any of the specified conditions.
   * @returns {boolean} Returns true if the URL includes any of the conditions, otherwise returns false.
   */
  const layoutClearConditions = ():boolean => {
    const url = window.location.href;
    const list = [
      "/tiktok",
    ];

    // need to check if the URL includes like for the example: https://12min.com/tiktok and not https://12min.com/?other=/tiktok
    return list.some((condition) => url.includes(condition));
  }

  const blockListButtonNext = [
    "/direct/landing",
    "/ret/landing",
    "/app/landing",
    "/landing",
    "/direct/book-suggestion/1",
    "/direct/book-suggestion/2",
    "/direct/book-suggestion/3",
    "/direct/book-suggestion/4",
    "/direct/book-suggestion/5",
    "/direct/select-plan-v2",
    "/landing-b"
  ]

  const byPassPages = blockListButtonNext.includes(location.pathname);

  const [screenData, setScreenData] = useState<ScreenContext>({
    submitLabel: !byPassPages && t("layout.cta_continue"),
    nextPath: isSlFunnel ? "usage" : "gender",
    progress: 7,
    progressVariation: 1,
    votting: false,
    urlParams: {},
    userPreferences: {},
    booksUnliked: [],
    totalSteps: totalStepsByFunnel[offerType ?? "direct"] || 25,
    step: 1,
    co: query.get("co"),
    bestPrice: false,
    offDiscount: query.get("offDiscount"),
    utmSource: query.get("utm_source"),
    searchParams: getAllSearchParams(),
  });

  useEffect(() => {
    const { pathname } = location;
    console.log("Location changed!", pathname);
    console.log("offerType:", offerType);
    console.log("isExp46", isExp46);

    rudderanalytics.page();
    if (pathname.includes("/form")) {
      rudderanalytics.openLeadForm();
    }
    if (pathname.includes("/age")) {
      rudderanalytics.quizStarted();
    }
    if (pathname.includes("/loading-result")) {
      rudderanalytics.quizStarted();
    }

    if (pathname && screenData.searchParams && screenData.searchParams !== null) {
      const storedSearchParams = new URLSearchParams(screenData.searchParams);
      if (storedSearchParams.toString() !== searchParams.toString()) {
        setSearchParams(storedSearchParams);
      }
    }
  }, [location]);

  useEffect(() => {
    getCategories()
      .then((response) => response.json())
      .then((response) =>
        setScreenData({
          ...screenData,
          topics: response.data,
          nextPath: isSlFunnel ? "usage" : "gender",
          totalSteps: isExp46 ? 26 : totalStepsByFunnel[offerType ?? "direct"] || 25,
          progress: 1,
        })
      );
  }, [isSlFunnel, isExp46]);

  const handleSubmit = async (e: any) => {
    screenData.nextPath.includes("pay.hotmart.com") || screenData.nextPath.includes("checkout-wizard.12min.com")
      ? rudderanalytics.checkoutStarted()
      : rudderanalytics.continueButtonClicked();
  };

  return (
    <>
      {/* { 
        ((location.pathname.includes("/select-plan") && !exp17feature)
        || location.pathname.includes("/select-plan-v2"))
        && !location.pathname.includes("/sl/select-plan-sl")
        && <HelloBar />
      } */}
      {layoutClearConditions() ? 
        <Outlet context={[screenData, setScreenData]} /> 
        :
        <div className="flex flex-col container mx-auto max-w-screen-sm text-center">
          <LayoutHeader screenData={screenData} />
          <div className="px-4">
            <Outlet context={[screenData, setScreenData]} />
          </div>
          {(screenData.submitLabel && !screenData.disabledButton) && (
            <div className="fixed inset-x-0 bottom-0 mx-auto max-w-screen-sm bg-gradient-to-t from-white via-white">
              <div className="mx-4">
                {!screenData.votting && (
                  <Link
                    ref={submitButtonRef}
                    to={screenData.nextPath}
                    onClick={handleSubmit}
                  >
                    <button
                      type="button"
                      className="text-md my-6 w-full rounded-md bg-blue-700 px-5 py-2.5 font-normal text-white hover:bg-blue-800 focus:ring-blue-300"
                    >
                      {screenData.submitLabel}
                    </button>
                  </Link>
                )}
              </div>
            </div>
          )}
        </div>
      }
    </>
  );
}

export function useScreenContext(): any {
  return useOutletContext<ScreenContext>();
}

export default Layout;
