import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useScreenContext, relativePath, OptionRadio } from "../../Layout";
import { useNavigate, useParams } from "react-router-dom";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

function Format() {
  const { t } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const navigate = useNavigate();
  const { offerType } = useParams();

  const isDirectSale = offerType === "direct";
  const isRetSale = offerType === "ret";

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: undefined,
      nextPath: relativePath("/reading-stats-sl", offerType),
      progress: 3,
      step: 3,
    }));
  }, []);

  const handleOptionSelection = (option: string) => {
    setScreenData(() => ({
      ...screenData,
      userPreferences: {
        ...screenData.userPreferences,
        format: option,
      },
    }));

    navigate(screenData.nextPath);
  };

  return (
    <div>
      <h1 className="mb-4 text-2xl font-bold tracking-tight text-gray-900  md:text-5xl">
        {t("format.headline")}
      </h1>
      <div className="mt-10">
        <OptionRadio onClick={() => handleOptionSelection("fisical")} name={"format"} value={"fisical"}>
          {t("format.option_1")}
        </OptionRadio>
        <OptionRadio onClick={() => handleOptionSelection("ebook")} name={"format"} value={"ebook"}>
          {t("format.option_2")}
        </OptionRadio>
        <OptionRadio onClick={() => handleOptionSelection("audiobook")} name={"format"} value={"audiobook"}>
          {t("format.option_3")}
        </OptionRadio>
      </div>
    </div>
  );
}

export default Format;
