import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useScreenContext, Option, relativePath, OptionRadio, useStepNumberManager } from "../Layout";
import { useNavigate, useParams } from "react-router-dom";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

function GenderQuestion() {
  const { t } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const navigate = useNavigate();
  const { offerType } = useParams();
  const [selected, setSelected] = useState("");

  const isDirectSale = offerType === "direct";

  const stepNumber = useStepNumberManager(2);

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: false,
      progress: stepNumber,
      step: stepNumber,
    }));
  }, []);

  const handleOptionSelection = (option: string) => {
    setScreenData(() => ({
      ...screenData,
      userPreferences: {
        ...screenData.userPreferences,
        genderAnswer: option,
      },
    }));

    navigate(relativePath("/usage", offerType));
  };

  return (
    <div>
      <h1 className="mb-4 text-2xl font-bold tracking-tight text-gray-900 md:text-5xl">
        {t("gender.headline")}
      </h1>
      <div className="mt-10">
        <OptionRadio name="gender" value="man" onClick={() => handleOptionSelection("man")}>
          {t("gender.man")}
        </OptionRadio>
        <OptionRadio name="gender" value="woman" onClick={() => handleOptionSelection("woman")}>
          {t("gender.woman")}
        </OptionRadio>
        <OptionRadio name="gender" value="no" onClick={() => handleOptionSelection("no")}>
          {t("gender.other")}
        </OptionRadio>
      </div>
    </div>
  );
}

export default GenderQuestion;
