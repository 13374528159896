import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./translations/en-US.json";
import br from "./translations/pt-BR.json";
import es from "./translations/es-MX.json";

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: { order: ["querystring"] },
    debug: process.env.NODE_ENV === "development",
    fallbackLng: "br",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: { translation: en },
      br: { translation: br },
      es: { translation: es },
    },
  });

export default i18n;
