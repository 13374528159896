import { JSXElementConstructor, useEffect, FC } from "react";
import { useTranslation } from "react-i18next";
import { useScreenContext, Option, relativePath, useStepNumberManager } from "../Layout";
import { useNavigate, useParams } from "react-router-dom";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

type OptionsComponentProps = {
  t: any;
  handleOptionSelection: (option: string) => void;
  currentFunnel: string|null;
};

const OptionsComponent: FC<OptionsComponentProps> = ({ t, handleOptionSelection, currentFunnel }) => (
  <div className="mt-10 grid grid-cols-2 gap-5">
    <Option onClick={() => handleOptionSelection("After waking up")}>
      {t(`usage.option_1`)}
      <div className="text-4xl">{t(`usage${ currentFunnel ? '.'+currentFunnel : '' }.option_1_emoji`)}</div>
    </Option>
    <Option onClick={() => handleOptionSelection("While working out")}>
      {t(`usage${ currentFunnel ? '.'+currentFunnel : '' }.option_2`)}
      <div className="text-4xl">{t(`usage${ currentFunnel ? '.'+currentFunnel : '' }.option_2_emoji`)}</div>
    </Option>
    <Option onClick={() => handleOptionSelection("On your daily commute")}>
      {t(`usage${ currentFunnel ? '.'+currentFunnel : '' }.option_3`)}
      <div className="text-4xl">{t(`usage${ currentFunnel ? '.'+currentFunnel : '' }.option_3_emoji`)}</div>
    </Option>
    <Option onClick={() => handleOptionSelection("While taking a break")}>
      {t(`usage${ currentFunnel ? '.'+currentFunnel : '' }.option_4`)}
      <div className="text-4xl">{t(`usage${ currentFunnel ? '.'+currentFunnel : '' }.option_4_emoji`)}</div>
    </Option>
  </div>
);

const OptionsSLComponent: FC<OptionsComponentProps> = ({ t, handleOptionSelection, currentFunnel }) => (
  <div className="mt-10 grid grid-cols-2 gap-5">
    <Option onClick={() => handleOptionSelection("morning")}>
      {t("usage_sl.option_1")}
      <div className="text-4xl">🌞</div>
    </Option>
    <Option onClick={() => handleOptionSelection("afternoon")}>
      {t("usage_sl.option_2")}
      <div className="text-4xl">🌤️</div>
    </Option>
    <Option onClick={() => handleOptionSelection("night")}>
      {t("usage_sl.option_3")}
      <div className="text-4xl">🌙</div>
    </Option>
    <Option onClick={() => handleOptionSelection("While taking a break")}>
      {t("usage_sl.option_4")}
      <div className="text-4xl">🛋️</div>
    </Option>
  </div>
);

function Usage() {
  const { t } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const navigate = useNavigate();
  const { offerType } = useParams();

  const isDirectSale = offerType === "direct";
  const isRetSale = offerType === "ret";
  const isAppFunnel = offerType === "app";
  const isSlFunnel = offerType === "sl"; // Super Leitura

  const isExp45Microbooks = useFeatureIsOn("exp-45-copy-test-microbooks");

  const currentFunnel = sessionStorage.getItem("currentFunnel");

  const stepNumber = useStepNumberManager(3);

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: t("layout.cta_continue"),
      nextPath: isSlFunnel ? relativePath("/format", offerType) : relativePath("/time", offerType),
      progress: stepNumber,
      step: stepNumber,
    }));
  }, []);

  const handleOptionSelection = (option: string) => {
    setScreenData(() => ({
      ...screenData,
      userPreferences: {
        ...screenData.userPreferences,
        usage: option,
      },
    }));
  };

  return (
    <div>
      <h1 className="mb-4 text-2xl font-bold tracking-tight text-gray-900  md:text-5xl">
        { isExp45Microbooks ? 
          <>Quando você costuma ler ebooks ou escutar podcasts?</> :
          t(`usage${ currentFunnel ? '.'+currentFunnel : '' }.headline`)
        }
      </h1>
      {isSlFunnel ? (
        <OptionsSLComponent t={t} handleOptionSelection={handleOptionSelection} currentFunnel={currentFunnel} />
      ) : (
        <OptionsComponent t={t} handleOptionSelection={handleOptionSelection} currentFunnel={currentFunnel} />
      )}
    </div>
  );
}

export default Usage;
