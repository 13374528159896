import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import MascotReading from "../../assets/images/mascot-reading.png";
import MascotSad from "../../assets/images/mascot-sad.png";
import MascotCrying from "../../assets/images/mascot-crying.png";
import { useScreenContext, relativePath, useStepNumberManager } from "../Layout";
import { useNavigate, useParams } from "react-router-dom";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

type KnowledgeAnswer = "daily" | "weekly" | "infrequently" | "no";

function KnowledgeInfo() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [screenData, setScreenData] = useScreenContext();
  const { offerType } = useParams();

  const isDirectSale = offerType === "direct";

  const answer: KnowledgeAnswer = screenData.userPreferences.knowledgeAnswer;

  if (!answer) {
    const knowledgePath = relativePath("/knowledge-question", offerType);
    navigate(knowledgePath);
  }

  const images = {
    daily: MascotReading,
    weekly: MascotReading,
    infrequently: MascotSad,
    no: MascotCrying,
  };

  const stepNumber = useStepNumberManager(8);
  
  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: t("layout.cta_continue"),
      nextPath: "describes-question",
      progress: stepNumber,
      step: stepNumber,
      votting: false,
    }));
  }, []);

  return (
    <main className="flex flex-col h-full justify-between gap-10">
      <header>
        <h1 className="mx-5 mb-4 mt-5 text-2xl font-bold tracking-tight text-gray-900  md:text-4xl">
          {t(`knowledge_info.headline.${answer}`)}
        </h1>
      </header>
      <img
        className="mx-auto w-full max-w-sm px-10"
        alt="Chart showing your progress"
        src={images[answer]}
      />
    </main>
  );
}

export default KnowledgeInfo;
