import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useScreenContext } from "../Layout";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useSessionStorage } from "react-use";
import * as rudderanalytics from "../../lib/rudderstack";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { useTimer } from "react-timer-hook";
import { Button, Modal, Flowbite } from "flowbite-react";
import type { CustomFlowbiteTheme } from "flowbite-react";
import "./SelectPlanV3.css";

import image7DaysMoneyBack from "../../assets/images/7dayslabel.png";

function TimeCounter() {
  const time = new Date();
  time.setSeconds(time.getSeconds() + 833);

  const { seconds, minutes, hours } = useTimer({ expiryTimestamp: time });

  return (
    <>
      {hours.toString().padStart(2, "0")}:
      {minutes.toString().padStart(2, "0")}:
      {seconds.toString().padStart(2, "0")}
    </>
  );
}

const OpenIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.293 8.29688L12 12.5899L7.70697 8.29688L6.29297 9.71087L12 15.4179L17.707 9.71087L16.293 8.29688Z" fill="black"/>
  </svg>
);

const CloseIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.293 15.7031L12 11.4101L7.70697 15.7031L6.29297 14.2891L12 8.58213L17.707 14.2891L16.293 15.7031Z" fill="black"/>
  </svg>
);

const PaymentFlags = () => (
  <div className="flex gap-1 justify-center mt-2 mb-4">
    <span className="payment-flag credit-card border rounded border-gray-900 p-1 bg-white">
      <svg width="22px" height="16px" viewBox="0 0 22 16" fill="var(--blue-darker)" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-v-4e2639a2="">
        <path d="M3 12.5C3 12.2239 3.22386 12 3.5 12H7.5C7.77614 12 8 12.2239 8 12.5C8 12.7761 7.77614 13 7.5 13H3.5C3.22386 13 3 12.7761 3 12.5Z" fillRule="evenodd" clipRule="evenodd"></path>
        <path d="M0 2.5C0 1.11929 1.11929 0 2.5 0H17.5C18.8807 0 20 1.11929 20 2.5V13.5C20 14.8807 18.8807 16 17.5 16H2.5C1.11929 16 0 14.8807 0 13.5V2.5ZM1 7V13.5C1 14.3284 1.67157 15 2.5 15H17.5C18.3284 15 19 14.3284 19 13.5V7H1ZM19 4H1V2.5C1 1.67157 1.67157 1 2.5 1H17.5C18.3284 1 19 1.67157 19 2.5V4Z" fillRule="evenodd" clipRule="evenodd"></path>
      </svg>
    </span>
    <span className="payment-flag credit-card border rounded border-gray-900 p-1 bg-white">
      <svg width="22px" height="16px" viewBox="0 0 22 23" fill="var(--blue-darker)" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-v-4e2639a2="">
        <path d="M5.19223 5.24323C6.06969 5.24323 6.89487 5.58498 7.51525 6.20516L10.8818 9.57225C11.1243 9.8147 11.5202 9.81575 11.7633 9.57193L15.1175 6.21736C15.738 5.59718 16.5632 5.25554 17.4407 5.25554H17.8447L13.5842 0.995107C12.2574 -0.331702 10.1063 -0.331702 8.77948 0.995107L4.53135 5.24323H5.19223ZM17.4406 17.108C16.5632 17.108 15.738 16.7664 15.1176 16.1462L11.7632 12.792C11.5278 12.5558 11.1173 12.5565 10.8819 12.792L7.51531 16.1585C6.89482 16.7786 6.06964 17.1202 5.19219 17.1202H4.5312L8.77943 21.3686C10.1062 22.6953 12.2574 22.6953 13.5842 21.3686L17.8447 17.108H17.4406ZM18.794 6.20484L21.3686 8.77947C22.6954 10.1062 22.6954 12.2573 21.3686 13.5842L18.7941 16.1587C18.7373 16.1359 18.6761 16.1218 18.6112 16.1218H17.4407C16.8354 16.1218 16.243 15.8764 15.8154 15.4484L12.4611 12.0945C11.8532 11.4859 10.7925 11.4862 10.184 12.0942L6.81744 15.4607C6.38976 15.8886 5.79746 16.134 5.19222 16.134H3.75286C3.69154 16.134 3.634 16.1486 3.57983 16.169L0.995108 13.5842C-0.331703 12.2573 -0.331703 10.1062 0.995108 8.77947L3.57994 6.19464C3.63411 6.21504 3.69154 6.22956 3.75286 6.22956H5.19222C5.79746 6.22956 6.38976 6.47496 6.81744 6.90285L10.1843 10.2697C10.4982 10.5833 10.9103 10.7404 11.3227 10.7404C11.7349 10.7404 12.1473 10.5833 12.4611 10.2694L15.8154 6.91505C16.243 6.48716 16.8354 6.24176 17.4407 6.24176H18.6112C18.676 6.24176 18.7373 6.22756 18.794 6.20484Z" fillRule="evenodd" clipRule="evenodd"></path>
      </svg>
    </span>
    <span className="payment-flag credit-card border rounded border-gray-900 p-1 bg-white">
      <svg width="22px" height="16px" viewBox="0 0 109 150" fill="var(--blue-darker)" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-v-4e2639a2="">
        <path d="M108.268,95.232c0-13.826-4.389-34.675-15.362-52.452c0,0-0.658,0-0.658,0.439  c0.878,2.634,1.097,7.681-3.292,7.242c-7.681-0.439,0-16.679-16.46-27.872c-0.219,0-0.439,0.219-0.439,0.439l0,0  c1.097,2.195,1.756,8.778-0.878,10.973c-2.195,2.195-5.706,1.536-9.437-4.389c-5.267-8.559-5.267-19.313,0-27.872 c0.658-0.658,0-0.878,0-0.658C38.04,8.544,33.651,35.538,28.823,45.414c-1.097,1.756-1.756,2.634-3.292,2.195 c-4.389,0-1.097-8.778,0.439-12.07c-0.219-0.219-0.439-0.219-0.658,0l0,0C12.363,50.242,0.732,73.285,0.732,93.257  C0.732,123.323,22.02,149,54.281,149C83.908,149,108.268,125.298,108.268,95.232z M54.061,120.25 c-13.607-0.439-24.36-11.632-24.141-25.458c0.439-13.607,11.632-24.36,25.458-24.141c13.387,0.439,23.921,11.193,24.141,24.58 c0.219,13.607-10.754,24.799-24.58,25.019C54.719,120.25,54.5,120.25,54.061,120.25L54.061,120.25z" fillRule="evenodd" clipRule="evenodd"></path>
      </svg>
    </span>
    <span className="payment-flag credit-card border rounded border-gray-900 p-1 bg-white">
      <svg width="22px" height="16px" viewBox="0 0 22 16" fill="var(--blue-darker)" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-v-4e2639a2="">
        <path d="M1.5 1C1.22386 1 1 1.22386 1 1.5V4.5C1 4.77614 0.776142 5 0.5 5C0.223858 5 0 4.77614 0 4.5V1.5C0 0.671573 0.671573 0 1.5 0H4.5C4.77614 0 5 0.223858 5 0.5C5 0.776142 4.77614 1 4.5 1H1.5ZM17 0.5C17 0.223858 17.2239 0 17.5 0H20.5C21.3284 0 22 0.671573 22 1.5V4.5C22 4.77614 21.7761 5 21.5 5C21.2239 5 21 4.77614 21 4.5V1.5C21 1.22386 20.7761 1 20.5 1H17.5C17.2239 1 17 0.776142 17 0.5ZM3.5 3C3.77614 3 4 3.22386 4 3.5V12.5C4 12.7761 3.77614 13 3.5 13C3.22386 13 3 12.7761 3 12.5V3.5C3 3.22386 3.22386 3 3.5 3ZM6.5 3C6.77614 3 7 3.22386 7 3.5V12.5C7 12.7761 6.77614 13 6.5 13C6.22386 13 6 12.7761 6 12.5V3.5C6 3.22386 6.22386 3 6.5 3ZM9.5 3C9.77614 3 10 3.22386 10 3.5V12.5C10 12.7761 9.77614 13 9.5 13C9.22386 13 9 12.7761 9 12.5V3.5C9 3.22386 9.22386 3 9.5 3ZM12.5 3C12.7761 3 13 3.22386 13 3.5V12.5C13 12.7761 12.7761 13 12.5 13C12.2239 13 12 12.7761 12 12.5V3.5C12 3.22386 12.2239 3 12.5 3ZM15.5 3C15.7761 3 16 3.22386 16 3.5V12.5C16 12.7761 15.7761 13 15.5 13C15.2239 13 15 12.7761 15 12.5V3.5C15 3.22386 15.2239 3 15.5 3ZM0.5 11C0.776142 11 1 11.2239 1 11.5V14.5C1 14.7761 1.22386 15 1.5 15H4.5C4.77614 15 5 15.2239 5 15.5C5 15.7761 4.77614 16 4.5 16H1.5C0.671573 16 0 15.3284 0 14.5V11.5C0 11.2239 0.223858 11 0.5 11ZM21.5 11C21.7761 11 22 11.2239 22 11.5V14.5C22 15.3284 21.3284 16 20.5 16H17.5C17.2239 16 17 15.7761 17 15.5C17 15.2239 17.2239 15 17.5 15H20.5C20.7761 15 21 14.7761 21 14.5V11.5C21 11.2239 21.2239 11 21.5 11Z" fillRule="evenodd" clipRule="evenodd"></path>
      </svg>
    </span>
    <span className="payment-flag credit-card border rounded border-gray-900 p-1 bg-white">
      <svg width="22px" height="16px" viewBox="0 0 84 98.179" fill="var(--blue-darker)" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-v-4e2639a2="">
        <g>
          <path opacity="0.68" fill="#707780" d="M74.981,24.944c1.198-7.638-0.008-12.834-4.139-17.542C66.294,2.22,58.078,0,47.566,0 H17.052c-2.148,0-3.978,1.563-4.312,3.686L0.033,84.258c-0.25,1.59,0.979,3.027,2.587,3.027h18.838l-1.3,8.245 c-0.219,1.391,0.855,2.648,2.263,2.648H38.3c1.879,0,3.479-1.367,3.772-3.224l0.156-0.808l2.991-18.968l0.193-1.048 c0.293-1.856,1.893-3.225,3.772-3.225h2.375c15.383,0,27.428-6.249,30.947-24.323c1.471-7.552,0.71-13.856-3.179-18.288 C78.152,26.956,76.688,25.848,74.981,24.944L74.981,24.944"></path>
          <path opacity="0.7" fill="#707780" d="M74.981,24.944c1.198-7.638-0.008-12.834-4.139-17.542C66.294,2.22,58.078,0,47.566,0H17.052 c-2.148,0-3.978,1.563-4.312,3.686L0.033,84.258c-0.25,1.59,0.979,3.027,2.587,3.027h18.838l4.732-30.007l-0.147,0.941 c0.335-2.122,2.148-3.686,4.297-3.686h8.954c17.584,0,31.353-7.144,35.375-27.804C74.789,26.119,74.89,25.526,74.981,24.944"></path>
          <path fill="#707780" d="M31.274,25.045c0.201-1.276,1.021-2.322,2.124-2.85c0.501-0.24,1.062-0.374,1.649-0.374h23.919 c2.834,0,5.476,0.186,7.891,0.575c0.69,0.111,1.362,0.239,2.015,0.384c0.652,0.145,1.286,0.306,1.899,0.486 c0.307,0.089,0.608,0.184,0.905,0.282c1.186,0.394,2.291,0.858,3.307,1.397c1.198-7.638-0.008-12.834-4.139-17.542 C66.294,2.22,58.078,0,47.566,0H17.052c-2.148,0-3.978,1.563-4.312,3.686L0.033,84.258c-0.25,1.59,0.979,3.027,2.587,3.027h18.838 l4.732-30.007L31.274,25.045z"></path>
        </g>
      </svg>
    </span>
    <span className="payment-flag credit-card border rounded border-gray-900 p-1 bg-white">
      <svg width="22px" height="16px" viewBox="0 0 30 30" fill="var(--blue-darker)" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-v-4e2639a2="">
        <g fill="none" fillRule="evenodd"><path fill="#707780" d="M0 0h30v30H0z"></path>
          <path fill="#FFF" d="M10.783 15.777h7.561l5.18 8.58h-7.561l-5.18-8.58"></path>
          <path fill="#45494F" d="M11.569 14.459h7.815l6.787-8.544h-7.815l-6.787 8.544m-7.814 9.95h7.814l6.787-8.649h-7.814l-6.787 8.649"></path>
          <path fill="#FFF" d="M6.573 5.811h7.293l4.997 8.648h-7.294L6.573 5.811"></path>
        </g>
      </svg>
    </span>
  </div>
);

const converObjectToParams = (object: any) => {
  return Object.keys(object)
    .filter((key) => object[key])
    .map((key) => {
      return `${key}=${encodeURIComponent(object[key])}`;
    })
    .join("&");
};

const RangeBar = ({ value, caption }: { value: number, caption: string }) => {
  const barRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (barRef.current) {
      barRef.current.style.width = `${value}%`;
    }
  }, [value]);

  return (
    <div className="flex flex-col justify-start gap-2 mx-2">
      <span className="bar flex rounded-full bg-gray-300 w-full h-2 my-1">
        <span ref={barRef} className="bar__fill rounded-l-full bg-blue-600" style={{ width: `${value}%` }}></span>
        <div className="relative content-center">
          <div className="absolute -left-2 -top-1 rounded-full border-2 bg-white p-0.5 border-blue-600">
            <div className="h-2 w-2 rounded-full bg-blue-600"></div>
          </div>
        </div>
      </span>
      <span className="text-md text-left font-semibold">{caption}</span>
    </div>
  );
}

type Plan = "monthly" | "quarterly" | "yearly";

const PlansComponent = ({
  id,
  selectedPlan,
  setselectedPlan,
  buildNextPath,
  timer,
  dark,
  variant,
}: {
  id?: string,
  selectedPlan: string,
  setselectedPlan: (value: Plan) => void,
  buildNextPath: () => string,
  timer?: boolean,
  dark?: boolean
  variant?: boolean
}) => {
  enum Plan {
    Monthly = "monthly",
    Quarterly = "quarterly",
    Yearly = "yearly"
  }

  const bgCard = dark ? "bg-gray-700" : "";
  const borderCard = dark ? "" : "border-slate-200";
  const border = dark ? "border-slate-600" : "border-slate-200";
  const textColor = dark ? "text-gray-50" : "text-gray-900";
  const textColorPlanTitle = dark ? "text-gray-50" : "";
  const textColorPlanPrice = dark ? "text-gray-50" : "text-black";
  const itemColorSelected = dark ? "bg-sky-900 border-blue-500" : "bg-slate-100 border-blue-500";
  const mostPopularColor = dark ? "text-slate-400 bg-slate-600" : "text-slate-400 bg-slate-200";
  const mostPopularColorSelected = dark ? "text-white bg-blue-500" : "text-white bg-blue-500";

  return (
    <div id={id} className={`flex flex-col border-2 ${bgCard} ${borderCard} rounded-xl mb-4`}>
      {timer && (
        <div className="bg-orange-400 py-1 rounded-t-[0.5rem] text-white text-sm font-semibold">
          59% DE DESCONTO RESERVADO POR <TimeCounter />
        </div>
      )}

      <h2 className={`mb-4 mt-5 text-3xl font-bold tracking-tight ${textColor} md:text-5xl lg:text-6xl`}>
        Escolha seu plano
      </h2>

      <div className="flex flex-col p-3 gap-2">

        <div
          role="option"
          onClick={() => setselectedPlan(Plan.Monthly)}
          className={
            "flex flex-col border-2 rounded-xl cursor-pointer text-gray-900 " +
            (selectedPlan === Plan.Monthly
              ? itemColorSelected
              : border)
          }
        >
          <div className="flex flex-col">
            <div className="flex gap-1 px-2 py-2 justify-between">
              <div className="flex">
                <div className="pr-2 content-center">
                  <div
                    className={
                      "mt-1.5 rounded-full border-2 p-0.5 " +
                      (selectedPlan === Plan.Monthly ? "border-blue-600" : border)
                    }
                  >
                    <div
                      className={
                        "h-2 w-2 rounded-full " +
                        (selectedPlan === Plan.Monthly ? "bg-blue-600" : "")
                      }
                    ></div>
                  </div>
                </div>


                <div className="flex flex-col items-start">
                  <h3 className={"text-xl font-bold mb-1 " + textColorPlanTitle}>1 mês</h3>
                  <span className="rounded-[0.275rem] bg-transparent text-white px-1 py-[0.06rem] text-xs h-4"></span>
                  <span className="text-sm text-slate-400 mt-1">
                    R$49,77
                  </span>
                </div>
              </div>

              <div className="plan-price-per-day flex gap-2">
                <div className="plan-price-per-day__old-price">
                  <span className="line-through text-md text-slate-400 block w-12"></span>
                </div>
                <div
                  className={
                    "plan-price-per-day__new-price flex font-bold " +
                    (selectedPlan === Plan.Monthly
                      ? textColorPlanPrice
                      : "text-slate-400")
                  }
                >
                  <span className="price__currency-symbol text-md text-slate-400">R$</span>
                  <span className="price__integer text-[4rem] leading-[3rem] mx-1 font-semibold">1</span>
                  <span className="price__decimal flex flex-col justify-start">
                    <span className="price__decimal-number text-left">65</span>
                    <span className="price__per text-[0.5rem] text-left">POR DIA</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          role="option"
          onClick={() => setselectedPlan(Plan.Quarterly)}
          className={
            "flex flex-col border-2 rounded-xl cursor-pointer text-gray-900 " +
            (selectedPlan === Plan.Quarterly
              ? itemColorSelected
              : border)
          }
        >
          <div className="flex flex-col">
            <div className="flex gap-1 px-2 py-2 justify-between">
              <div className="flex">
                <div className="pr-2 content-center">
                  <div
                    className={
                      "mt-1.5 rounded-full border-2 p-0.5 " +
                      (selectedPlan === Plan.Quarterly ? "border-blue-600" : border)
                    }
                  >
                    <div
                      className={
                        "h-2 w-2 rounded-full " +
                        (selectedPlan === Plan.Quarterly ? "bg-blue-600" : "")
                      }
                    ></div>
                  </div>
                </div>


                <div className="flex flex-col items-start">
                  <h3 className={"text-xl font-bold mb-1 " + textColorPlanTitle}>3 meses</h3>
                  <span
                    className={
                      "rounded-[0.275rem] font-semibold px-1 py-[0.06rem] text-xs " +
                      (selectedPlan === Plan.Quarterly ? "bg-blue-500 text-white" : "bg-gray-200 text-blue-500")
                    }
                  >25% OFF</span>
                  <span className="text-sm text-slate-400 mt-1">
                    <span className="line-through mr-1">R$129,77</span>
                    R$97,33
                  </span>
                </div>
              </div>

              <div className="plan-price-per-day flex gap-2">
                <div className="plan-price-per-day__old-price">
                  <span className="line-through text-md text-slate-400">R$1,44</span>
                </div>
                <div
                  className={
                    "plan-price-per-day__new-price flex font-bold " +
                    (selectedPlan === Plan.Quarterly
                      ? textColorPlanPrice
                      : "text-slate-400")
                  }
                >
                  <span className="price__currency-symbol text-md text-slate-400">R$</span>
                  <span className="price__integer text-[4rem] leading-[3rem] mx-1 font-semibold">1</span>
                  <span className="price__decimal flex flex-col justify-start">
                    <span className="price__decimal-number text-left">08</span>
                    <span className="price__per text-[0.5rem] text-left">POR DIA</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          role="option"
          onClick={() => setselectedPlan(Plan.Yearly)}
          className={
            "flex flex-col border-2 rounded-xl cursor-pointer text-gray-900 " +
            (selectedPlan === Plan.Yearly
              ? itemColorSelected
              : border)
          }
        >
          <div className="flex flex-col">
            <div
              className={
                "slate-200 font-semibold rounded-t-[0.50rem] " +
                (selectedPlan === Plan.Yearly
                  ? mostPopularColorSelected
                  : mostPopularColor)
              }
            >
              MAIS POPULAR
            </div>
            <div className="flex gap-1 px-2 py-2 justify-between">
              <div className="flex">
                <div className="pr-2 content-center">
                  <div
                    className={
                      "mt-1.5 rounded-full border-2 p-0.5 " +
                      (selectedPlan === Plan.Yearly ? "border-blue-600" : border)
                    }
                  >
                    <div
                      className={
                        "h-2 w-2 rounded-full " +
                        (selectedPlan === Plan.Yearly ? "bg-blue-600" : "")
                      }
                    ></div>
                  </div>
                </div>

                <div className="flex flex-col items-start">
                  <h3 className={"text-xl font-bold mb-1 " + textColorPlanTitle}>12 meses</h3>
                  <span
                    className={
                      "rounded-[0.275rem] font-semibold px-1 py-[0.06rem] text-xs " +
                      (selectedPlan === Plan.Yearly ? "bg-blue-500 text-white" : "bg-gray-200 text-blue-500")
                    }
                  >
                    {variant ? (
                      "63% OFF"
                    ) : (
                      "59% OFF"
                    )}
                  </span>
                  <span className="text-sm text-slate-400 mt-1">
                    <span className="line-through mr-1">R$359,90</span>
                      {variant ? (
                        "R$133,16"
                      ) : (
                        "R$147,56"
                      )}
                  </span>
                </div>
              </div>

              <div className="plan-price-per-day flex gap-2">
                <div className="plan-price-per-day__old-price">
                  <span className="line-through text-md text-slate-400">R$0.98</span>
                </div>
                <div
                  className={
                    "plan-price-per-day__new-price flex font-bold " +
                    (selectedPlan === Plan.Yearly
                      ? textColorPlanPrice
                      : "text-slate-400")
                  }
                >
                  <span className="price__currency-symbol text-md text-slate-400">R$</span>
                  <span className="price__integer text-[4rem] leading-[3rem] mx-1 font-semibold">0</span>
                  <span className="price__decimal flex flex-col justify-start">
                    <span className="price__decimal-number text-left">
                      {variant ? (
                        "36"
                      ) : (
                        "40"
                      )}
                    </span>
                    <span className="price__per text-[0.5rem] text-left">POR DIA</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Link className="rounded-lg bg-blue-500 text-white py-3 font-semibold mt-3" to={buildNextPath()}>
          Continue
        </Link>

        <div className="flex grow-0 shrink justify-center mt-3">
          <div className="flex items-center rounded-xl bg-green-500/10 p-2 text-sm text-green-500 font-semibold">
            <svg
              className="w-5 h-5 mr-1"
              xmlns="http://www.w3.org/2000/svg"
              fill="rgb(22 163 74)"
              viewBox="0 0 28 28"
              stroke="rgb(22 163 74)"
            >
              <path d="M22.75 4.375H5.25C4.78587 4.375 4.34075 4.55937 4.01256 4.88756C3.68437 5.21575 3.5 5.66087 3.5 6.125V12.25C3.5 18.0162 6.29125 21.5108 8.63297 23.427C11.1552 25.4898 13.6642 26.1898 13.7736 26.2194C13.924 26.2603 14.0826 26.2603 14.233 26.2194C14.3423 26.1898 16.8481 25.4898 19.3736 23.427C21.7088 21.5108 24.5 18.0162 24.5 12.25V6.125C24.5 5.66087 24.3156 5.21575 23.9874 4.88756C23.6592 4.55937 23.2141 4.375 22.75 4.375ZM22.75 12.25C22.75 16.3045 21.2559 19.5956 18.3094 22.0303C17.0267 23.0866 15.5679 23.9085 14 24.4584C12.4526 23.9181 11.0118 23.1107 9.74313 22.073C6.76156 19.6339 5.25 16.3297 5.25 12.25V6.125H22.75V12.25ZM9.00594 15.4941C8.84175 15.3299 8.74951 15.1072 8.74951 14.875C8.74951 14.6428 8.84175 14.4201 9.00594 14.2559C9.17012 14.0918 9.39281 13.9995 9.625 13.9995C9.85719 13.9995 10.0799 14.0918 10.2441 14.2559L12.25 16.263L17.7559 10.7559C17.8372 10.6746 17.9337 10.6102 18.04 10.5662C18.1462 10.5222 18.26 10.4995 18.375 10.4995C18.49 10.4995 18.6038 10.5222 18.71 10.5662C18.8163 10.6102 18.9128 10.6746 18.9941 10.7559C19.0754 10.8372 19.1398 10.9337 19.1838 11.04C19.2278 11.1462 19.2505 11.26 19.2505 11.375C19.2505 11.49 19.2278 11.6038 19.1838 11.71C19.1398 11.8163 19.0754 11.9128 18.9941 11.9941L12.8691 18.1191C12.7878 18.2004 12.6913 18.265 12.5851 18.309C12.4788 18.353 12.365 18.3757 12.25 18.3757C12.135 18.3757 12.0212 18.353 11.9149 18.309C11.8087 18.265 11.7122 18.2004 11.6309 18.1191L9.00594 15.4941Z" fill="rgb(34 197 94)"/>
            </svg>

            <span>Compra 100% segura</span>
          </div>
        </div>

        <PaymentFlags />

        <div className="money-back-label flex justify-center text-center items-center mb-4">
          <img src={image7DaysMoneyBack} width={192} alt="7 dias de garantia" />
        </div>

      </div>
    </div>
  );
};


const PopupCheckout = ({checkoutLink, setBestPrice}: {checkoutLink: any, setBestPrice: any}) => {
  const [openModal, setOpenModal] = useState(true);

  const customTheme: CustomFlowbiteTheme = {
    modal: {
      show: {
        "on": "flex bg-gray-900 bg-opacity-50 backdrop-blur dark:bg-opacity-80",
        "off": "hidden"
      },
      header: {
        base: "flex flex-row-reverse items-center border-b-0 rounded-t p-2 dark:border-gray-600",
        popup: "border-b-0 p-2",
        title: "w-full text-md font-normal text-center text-gray-900 dark:text-white",
        close: {
          base: "inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white",
          icon: "h-5 w-5"
        }
      },
      body: {
        base: ""
      },
      footer: {
        base: "flex flex-col border-t-0 p-4 gap-4 bg-gray-100 rounded-b-xl",
      }
    }
  };

  return (
    <>
      <Flowbite theme={{ theme: customTheme }}>
        <Modal show={openModal} onClose={() => {setBestPrice(true); setOpenModal(false)}}>
          <Modal.Header>Checkout</Modal.Header>
          <Modal.Body>
            <h2 className="text-center text-xl font-bold mb-4 p-4">
              <span className="text-blue-600">91% dos usuários</span> estão satisfeitos com o plano e permanecem conosco.
            </h2>
            <div className="flex justify-between text-sm px-4 mb-4">
              <span>Total</span>
              <span className="font-bold">R$ 147,56</span>
            </div>
            <div className="flex flex-col gap-4 p-4 bg-gray-100">
              <h3 className="text-lg font-bold">Pague com rapidez e segurança</h3>
              <div className="payment">
                <img src="https://hotmart.com/javax.faces.resource/images/hotpay/hotpay-logo.png.xhtml" alt="hotpay" />
              </div>
              <div className="payment-flags">
                <PaymentFlags />
              </div>
              {/* <div className="payment-form">
                <input type="text" placeholder="Nome no cartão" />
                <input type="text" placeholder="Número do cartão" />
                <div className="flex gap-2">
                  <input type="text" placeholder="MM/AA" />
                  <input type="text" placeholder="CVV" />
                </div>
                <input type="text" placeholder="CPF" />
              </div> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Link className="flex justify-center rounded-lg bg-blue-500 text-white py-3 font-semibold mt-3" to={checkoutLink}>
              <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 9V7C17 4.2 14.8 2 12 2C9.2 2 7 4.2 7 7V8H9V9H7C5.3 9 4 10.3 4 12L4.03855 17.1C4.03855 18.8 5.33855 20.1 7.03855 20.1H17.0386C18.7386 20.1 20.0386 18.8 20.0386 17.1L20 12C20 10.3 18.7 9 17 9ZM9 7C9 5.3 10.3 4 12 4C13.7 4 15 5.3 15 7V9H9V8V7ZM13.1386 14.6L13.0386 14.7V16.1C13.0386 16.7 12.6386 17.1 12.0386 17.1C11.4386 17.1 11.0386 16.7 11.0386 16.1V14.7C10.4386 14.1 10.3386 13.2 10.9386 12.6C11.5386 12 12.4386 11.9 13.0386 12.5C13.6386 13 13.7386 14 13.1386 14.6Z" fill="white"/>
              </svg>
              <span className="ml-1">CONFIRMAR PAGAMENTO</span>
            </Link>
            <div className="flex grow-0 shrink justify-center mt-3">
              <div className="flex items-center rounded-xl bg-green-500/10 p-2 text-sm text-green-500 font-semibold">
                <svg
                  className="w-5 h-5 mr-1"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="rgb(22 163 74)"
                  viewBox="0 0 28 28"
                  stroke="rgb(22 163 74)"
                >
                  <path d="M22.75 4.375H5.25C4.78587 4.375 4.34075 4.55937 4.01256 4.88756C3.68437 5.21575 3.5 5.66087 3.5 6.125V12.25C3.5 18.0162 6.29125 21.5108 8.63297 23.427C11.1552 25.4898 13.6642 26.1898 13.7736 26.2194C13.924 26.2603 14.0826 26.2603 14.233 26.2194C14.3423 26.1898 16.8481 25.4898 19.3736 23.427C21.7088 21.5108 24.5 18.0162 24.5 12.25V6.125C24.5 5.66087 24.3156 5.21575 23.9874 4.88756C23.6592 4.55937 23.2141 4.375 22.75 4.375ZM22.75 12.25C22.75 16.3045 21.2559 19.5956 18.3094 22.0303C17.0267 23.0866 15.5679 23.9085 14 24.4584C12.4526 23.9181 11.0118 23.1107 9.74313 22.073C6.76156 19.6339 5.25 16.3297 5.25 12.25V6.125H22.75V12.25ZM9.00594 15.4941C8.84175 15.3299 8.74951 15.1072 8.74951 14.875C8.74951 14.6428 8.84175 14.4201 9.00594 14.2559C9.17012 14.0918 9.39281 13.9995 9.625 13.9995C9.85719 13.9995 10.0799 14.0918 10.2441 14.2559L12.25 16.263L17.7559 10.7559C17.8372 10.6746 17.9337 10.6102 18.04 10.5662C18.1462 10.5222 18.26 10.4995 18.375 10.4995C18.49 10.4995 18.6038 10.5222 18.71 10.5662C18.8163 10.6102 18.9128 10.6746 18.9941 10.7559C19.0754 10.8372 19.1398 10.9337 19.1838 11.04C19.2278 11.1462 19.2505 11.26 19.2505 11.375C19.2505 11.49 19.2278 11.6038 19.1838 11.71C19.1398 11.8163 19.0754 11.9128 18.9941 11.9941L12.8691 18.1191C12.7878 18.2004 12.6913 18.265 12.5851 18.309C12.4788 18.353 12.365 18.3757 12.25 18.3757C12.135 18.3757 12.0212 18.353 11.9149 18.309C11.8087 18.265 11.7122 18.2004 11.6309 18.1191L9.00594 15.4941Z" fill="rgb(34 197 94)"/>
                </svg>

                <span>Compra 100% segura</span>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </Flowbite>
    </>
  );
};

type Gender = "man" | "woman" | "no";

const PersonComponenet = ({ gender }: { gender: Gender }) => {
  const person1A = require("../../assets/images/persons/person1_a.webp");
  const person1B = require("../../assets/images/persons/person1_b.webp");
  const person2A = require("../../assets/images/persons/person2_a.webp");
  const person2B = require("../../assets/images/persons/person2_b.webp");
  const person3A = require("../../assets/images/persons/person3_a.webp");
  const person3B = require("../../assets/images/persons/person3_b.webp");

  const person = {
    man: [person1A, person1B],
    woman: [person2A, person2B],
    no: [person3A, person3B],
  };

  return (
    <div className="flex justify-center pt-2 px-2 bg-neutral-100">
      <img
        className="w-1/2"
        src={person[gender][0]}
        alt="Person"
      />
      <img
        className="w-1/2"
        src={person[gender][1]}
        alt="Person"
      />
    </div>
  );
};

const LiCheckIcon: React.FC<{ text: string }> = ({ text }) => (
  <li className="flex gap-2 items-start">
    <span className="rounded-full flex grow-0 mt-1 h-auto justify-start items-start bg-blue-50">
      <svg
        className="w-5 h-5 text-[#4357AD]"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M5 13l4 4L19 7"
        />
      </svg>
    </span>
    <span>{text}</span>
  </li>
);

const GiftIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.334 4.66927H12.528C12.6258 4.34513 12.6728 4.0078 12.6673 3.66927C12.6673 2.3826 11.6207 1.33594 10.334 1.33594C9.25265 1.33594 8.53065 2.32394 8.06465 3.3926C7.60532 2.3826 6.84665 1.33594 5.66732 1.33594C4.38065 1.33594 3.33398 2.3826 3.33398 3.66927C3.33398 4.0666 3.38665 4.39527 3.47332 4.66927H2.66732C1.93198 4.66927 1.33398 5.26727 1.33398 6.0026V7.33594C1.33398 8.07127 1.93198 8.66927 2.66732 8.66927V13.3359C2.66732 14.0713 3.26532 14.6693 4.00065 14.6693H12.0007C12.736 14.6693 13.334 14.0713 13.334 13.3359V8.66927C14.0693 8.66927 14.6673 8.07127 14.6673 7.33594V6.0026C14.6673 5.26727 14.0693 4.66927 13.334 4.66927ZM10.334 2.66927C10.8853 2.66927 11.334 3.11794 11.334 3.66927C11.334 4.66927 10.9167 4.66927 10.6673 4.66927H9.01532C9.35598 3.6186 9.85065 2.66927 10.334 2.66927ZM4.66732 3.66927C4.66732 3.11794 5.11598 2.66927 5.66732 2.66927C6.25932 2.66927 6.80998 3.68594 7.13265 4.66927H5.33398C5.08465 4.66927 4.66732 4.66927 4.66732 3.66927ZM2.66732 6.0026H7.33398V7.33594H2.66732V6.0026ZM4.00065 13.3359V8.66927H7.33398V13.3359H4.00065ZM12.0007 13.3359H8.66732V8.66927H12.0007V13.3359ZM8.66732 7.33594V6.05927L8.67865 6.0026H13.334L13.3347 7.33594H8.66732Z" fill="#4357AD"/>
  </svg>
);


function SelectPlanV3() {
  const { t, i18n } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const [showOthersPlans, setShowOthersPlans] = useState<boolean>(false);
  const [selectedPlan, setselectedPlan] = useState<string>("yearly");
  const [currentFaq, setCurrentFaq] = useState<number>(0);
  const [bestPrice, setBestPrice] = useState<boolean>(false);
  const { offerType } = useParams();
  const navigate = useNavigate();
  const [flowStrategy, setFlowStrategy] = useSessionStorage(
    "flow-strategy",
    ""
  );
  const isRet = sessionStorage.getItem("utm_source") === "ret";
  const isFromTwitter = sessionStorage.getItem("utm_source") === "twitter";

  const isPostLeadFlowStrategy = flowStrategy === "lead-post-plan-selection";
  const [variant, _] = useSessionStorage("variant");

  const isDirectSale = offerType === "direct";
  const isRetSale = offerType === "ret";

  const isWithInterestVariant = variant === "with-interest" && isDirectSale;

  const activePlan = (isDirectSale || isRetSale) && selectedPlan === "yearly";

  type Plan = "monthly" | "quarterly" | "yearly";

  const checkoutId: any = {
    br: activePlan ? "E91856682Y" : "P5327674P",
    en: "K16542811H",
    es: activePlan ? "Y92363487F" : "Y16613490E",
  }[i18n.language];

  const offerCodeList: Record<string, string> = {
    twitter: "xrnblfqt",
    default: "7d18zm3s",
    exp16: "6reyasj1"
  };

  const offerCodeDirect: () => string = () => {
    if (isFromTwitter) return offerCodeList.twitter;
    return offerCodeList.default;
  };

  const discount = isRetSale  
    ? "promo75off" 
    : (bestPrice 
        ? "promo63off" 
        : "promo59off"
      );

  

  const offersTrial: any = {
    discounts: {
      base: "63OFF7D",
    },
    P5327674P: {
      yearly: { offerCode: "2zki1nl0" },
      monthly: { offerCode: "jx5nh72y" },
    },
    K16542811H: {
      yearly: { offerCode: "38tbqvq8" },
      monthly: { offerCode: "fys1w0n7" },
    },
    Y92363487F: {
      yearly: { offerCode: "vng1dkxz" },
      monthly: { offerCode: "7lcaoc5b" },
    },
  };

  const offersDirectSale: any = {
    discounts: {
      base: discount,
      quarterly: "promo30off",
    },
    P5327674P: {
      yearly: { offerCode: "suxzbi7s" },
      quarterly: { offerCode: "vp7zn9o1" },
      monthly: { offerCode: "jx5nh72y" },
    },
    E91856682Y: {
      yearly: { offerCode: offerCodeDirect() },
      monthly: { offerCode: "kppi78af" },
    },
    K16542811H: {
      yearly: { offerCode: "evc05pnv" },
      monthly: { offerCode: "b3i96d7z" },
    },
    Y16613490E: {
      yearly: { offerCode: "oqvwtnza" },
      monthly: { offerCode: "eptks9rt" },
    },
    Y92363487F: {
      yearly: { offerCode: "98fq4cvr" },
      monthly: { offerCode: "prlt2kyv" },
    },
  };

  const offersRetSale: any = {
    discounts: {
      base: "promo75off",
      quarterly: "promo30off",
    },
    E91856682Y: {
      yearly: { offerCode: "7d18zm3s" },
      monthly: { offerCode: "kppi78af" },
    },
  };

  const offPlans: () => false | string = () => {
    if (selectedPlan === "yearly") return bestPrice ? "promo63off" : "promo59off";
    if (selectedPlan === "quarterly") return "promo25off";
    return false;
  }

  const offersOptions: any = () => {
    if (isDirectSale) return offersDirectSale;
    if (isRetSale) return offersRetSale;
    return offersTrial;
  };

  const offers: any = offersOptions();
  const currentCheckout = offers[checkoutId];

  const buildCheckoutLink = () => {
    const baseUrl = `https://pay.hotmart.com/${checkoutId}`;
    const urlParams = !isPostLeadFlowStrategy ? screenData.urlParams : {};
    return (
      baseUrl +
      "?" +
      converObjectToParams({
        ...urlParams,
        off: currentCheckout[selectedPlan].offerCode,
        offDiscount: offPlans(), //selectedPlan === "yearly" ? offers.discounts.base : false,
        sck: rudderanalytics.getAnonymousId(),
        checkoutMode: "10",
        hidePix: "0",
        hideBillet: "0",
      })
    );
  };

  const buildNextPath = () => {
    const checkoutLink = buildCheckoutLink();
    return !isPostLeadFlowStrategy
      ? checkoutLink
      : "form?redirectUrl=" + encodeURIComponent(checkoutLink);
  };

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitdiv: undefined,
      votting: true, // unable cotinue button on the bottom screen
      nextPath: buildNextPath(),
      progress: 24,
      step: 24,
      submitLabel: false,
      disabledButton: true,
    }));

    setBestPrice(true)
  }, [selectedPlan]);

  // build checkout link
  let checkoutLink = buildNextPath();
  useEffect(() => {
    checkoutLink = buildNextPath();
  }, [screenData.nextPath]);

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      disabledButton: true,
    }));

  }, [screenData.disabledButton]);

  useEffect(() => {
    if (!showOthersPlans) setselectedPlan("yearly");
  }, [showOthersPlans]);

  return (
    <div className="flex flex-col">
      
      <div className="flex flex-col mb-4">
        <div className="flex flex-col gap-4 grow-0 justify-center items-center">
          <div className="bg-red-600 flex rounded-full text-white text-lg justify-center items-center px-4 py-1 font-medium">
            <div className="flex items-center justify-center pr-2">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 3V1H15V3H9ZM11 14H13V8H11V14ZM12 22C10.7667 22 9.604 21.7627 8.512 21.288C7.42 20.8133 6.466 20.1673 5.65 19.35C4.834 18.5327 4.18833 17.5783 3.713 16.487C3.23767 15.3957 3 14.2333 3 13C3 11.7667 3.23767 10.604 3.713 9.512C4.18833 8.42 4.834 7.466 5.65 6.65C6.466 5.834 7.42033 5.18833 8.513 4.713C9.60567 4.23767 10.768 4 12 4C13.0333 4 14.025 4.16667 14.975 4.5C15.925 4.83333 16.8167 5.31667 17.65 5.95L19.05 4.55L20.45 5.95L19.05 7.35C19.6833 8.18333 20.1667 9.075 20.5 10.025C20.8333 10.975 21 11.9667 21 13C21 14.2333 20.7623 15.396 20.287 16.488C19.8117 17.58 19.166 18.534 18.35 19.35C17.534 20.166 16.5797 20.812 15.487 21.288C14.3943 21.764 13.232 22.0013 12 22ZM12 20C13.9333 20 15.5833 19.3167 16.95 17.95C18.3167 16.5833 19 14.9333 19 13C19 11.0667 18.3167 9.41667 16.95 8.05C15.5833 6.68333 13.9333 6 12 6C10.0667 6 8.41667 6.68333 7.05 8.05C5.68333 9.41667 5 11.0667 5 13C5 14.9333 5.68333 16.5833 7.05 17.95C8.41667 19.3167 10.0667 20 12 20Z" fill="white"/>
              </svg>
            </div>
            <TimeCounter />
          </div>

          <h2 className="text-red-600 font-bold text-xl">OFERTA POR TEMPO LIMITADO</h2>
        </div>

        <h2 className="mb-4 mt-5 text-3xl font-bold tracking-tight text-gray-900  md:text-5xl lg:text-6xl">
          Torne-se a pessoa mais interessante da mesa
        </h2>

        <h3 className="mb-5 mt-4 text-xl">Junte-se às mais de <strong>5.274.333</strong> pessoas na comunidade 12min</h3>
      </div>

      <div className="flex flex-col rounded-xl mb-4 md:flex-row-reverse md:bg-[#F2F2FF]">
        <div className="top-right md:w-1/2 md:p-4">
          <h2 className="mb-2 mt-5 text-3xl font-bold tracking-tight text-gray-900 md:text-xl md:text-left">
            Receba
          </h2>

          <div className="flex flex-col p-3 gap-2">
            <ul className="text-left text-gray-600 font-bold">
              <LiCheckIcon text="2.700+ livros para ler ou ouvir." />
              <LiCheckIcon text="Use nossa IA para evoluir + rápido." />
              <LiCheckIcon text="Acesso antecipado a novas funções." />
            </ul>
          </div>

          <div
            onClick={() => setselectedPlan("yearly")}
            className={
              "relative cursor-pointer text-left flex flex-col p-3 mt-4 border rounded-3xl "
              + (selectedPlan === "yearly" ? "bg-[#F2F2FF] border-[#4357AD]" : "bg-gray-50 border-gray-200 grayscale")
            }
          >
            <span
              className={
                "absolute flex top-[-1rem] right-[1rem] text-white font-semibold px-3 py-1 rounded-full "
                + (selectedPlan === "yearly" ? "bg-[#3A50AE]" : "bg-gray-200")
              }
            >
              Economize 63%
            </span>
            <span className="text-[#4357AD] text-sm font-bold text-left mb-1">
              PROMOÇÃO
            </span>
            <h2 className="font-bold">
              Premium Anual
            </h2>
            <span className="text-[#FF5151] line-through font-semibold">de R$ 35.92/mês (R$ 431.04/ano)</span>
            <div className="font-bold text-sm">
              por 12x <span className="text-xl">R$ 13,29/mês</span> <span className="text-[#4357AD]">(R$ 133,16/ano)</span>
            </div>
            <div className="gift-text flex gap-1 mt-4 text-[#4357AD] text-sm font-semibold">
              <GiftIcon />
              <span>Aproveite esta oferta e ganhe um presente!</span>
            </div>
          </div>

          <div
            onClick={() => setselectedPlan("monthly")}
            className={
              "relative cursor-pointer text-left flex flex-col p-3 mt-4 border rounded-3xl "
              + (selectedPlan === "monthly" ? "bg-[#F2F2FF] border-[#4357AD]" : "bg-gray-50 border-gray-200 grayscale")
              + (showOthersPlans ? "" : " hidden")
            }
          >
            <h2 className="font-bold">
              Premium Mensal
            </h2>
            <div className="font-bold text-sm">
              <span className="text-xl">R$ 49,77/mês</span> <span className="text-[#4357AD] hidden">(R$ 0,00/ano)</span>
            </div>
          </div>

          <div className="flex justify-center mt-2">
            <Link
              className="flex w-full justify-center items-center rounded-full bg-[#4357AD] text-xl text-white py-3 font-semibold mt-3"
              to={checkoutLink}
            >
              Continue
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mt-[0.2rem]">
                <path d="M10.207 16.9531L15.914 11.2461L10.207 5.53906L8.79297 6.95306L13.086 11.2461L8.79297 15.5391L10.207 16.9531Z" fill="white"/>
              </svg>
            </Link>
          </div>

          <button className="mt-3" onClick={() => setShowOthersPlans(showOthersPlans ? false : true)}>
            {showOthersPlans ? "Ocultar outros planos" : "Ver outros planos"}
          </button>
        </div>

        <div className="bottom-left md:w-1/2 md:p-4 pt-2">
          <h2 className="hidden md:block mt-3 text-3xl font-bold tracking-tight text-gray-900 md:text-2xl md:text-left">
            Como Funciona
          </h2>
          
          <ol className="relative mt-4 border-s border-gray-200 dark:border-gray-700 text-left">                  
            <li className="ms-6 border-l-[8px] border-[#2E4295] ml-3">
              <span className="absolute flex items-center justify-center w-8 h-8 bg-[#3A50AE] rounded-full left-0">
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.49935 1.33594C6.66135 1.33594 5.16602 2.83127 5.16602 4.66927V6.0026H4.49935C3.76402 6.0026 3.16602 6.6006 3.16602 7.33594V13.3359C3.16602 14.0713 3.76402 14.6693 4.49935 14.6693H12.4993C13.2347 14.6693 13.8327 14.0713 13.8327 13.3359V7.33594C13.8327 6.6006 13.2347 6.0026 12.4993 6.0026H11.8327V4.66927C11.8327 2.83127 10.3373 1.33594 8.49935 1.33594ZM6.49935 4.66927C6.49935 3.5666 7.39668 2.66927 8.49935 2.66927C9.60202 2.66927 10.4993 3.5666 10.4993 4.66927V6.0026H6.49935V4.66927ZM12.5007 13.3359H9.16602V11.8173C9.56268 11.5859 9.83268 11.1606 9.83268 10.6693C9.83268 9.93394 9.23468 9.33594 8.49935 9.33594C7.76402 9.33594 7.16602 9.93394 7.16602 10.6693C7.16602 11.1599 7.43602 11.5859 7.83268 11.8173V13.3359H4.49935V7.33594H12.4993L12.5007 13.3359Z" fill="white"/>
                </svg>
              </span>
              <h3 className="flex ml-5 items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white">Hoje: Ganhe Acesso.</h3>
              <p className="pb-6 ml-5 text-base font-normal text-gray-500 dark:text-gray-400">Comece a aproveitar toda a biblioteca do 12min. Você terá acesso a 2.700+ livros, desafios, quizzes e muito mais. </p>
            </li>
            <li className="ms-6 border-l-[8px] border-[#171F44] ml-3">
              <span className="absolute flex items-center justify-center w-8 h-8 bg-[#182046] rounded-full left-0">
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.1667 9.05994V6.66927C13.1667 4.5246 11.71 2.71794 9.73667 2.1746C9.54133 1.6826 9.064 1.33594 8.5 1.33594C7.936 1.33594 7.45867 1.6826 7.26333 2.1746C5.29 2.7186 3.83333 4.5246 3.83333 6.66927V9.05994L2.69533 10.1979C2.63331 10.2597 2.58411 10.3332 2.55059 10.4141C2.51707 10.495 2.49987 10.5817 2.5 10.6693V12.0026C2.5 12.1794 2.57024 12.349 2.69526 12.474C2.82029 12.599 2.98986 12.6693 3.16667 12.6693H13.8333C14.0101 12.6693 14.1797 12.599 14.3047 12.474C14.4298 12.349 14.5 12.1794 14.5 12.0026V10.6693C14.5001 10.5817 14.4829 10.495 14.4494 10.4141C14.4159 10.3332 14.3667 10.2597 14.3047 10.1979L13.1667 9.05994ZM13.1667 11.3359H3.83333V10.9453L4.97133 9.80727C5.03336 9.74547 5.08255 9.67201 5.11608 9.59111C5.1496 9.51022 5.16679 9.4235 5.16667 9.33594V6.66927C5.16667 4.83127 6.662 3.33594 8.5 3.33594C10.338 3.33594 11.8333 4.83127 11.8333 6.66927V9.33594C11.8333 9.51327 11.9033 9.6826 12.0287 9.80727L13.1667 10.9453V11.3359ZM8.5 14.6693C8.91287 14.6698 9.31564 14.5417 9.65233 14.3027C9.98902 14.0638 10.2429 13.7258 10.3787 13.3359H6.62133C6.75711 13.7258 7.01099 14.0638 7.34767 14.3027C7.68436 14.5417 8.08713 14.6698 8.5 14.6693Z" fill="white"/>
                </svg>
              </span>
              <h3 className="mb-1 ml-5 text-lg font-semibold text-gray-900 dark:text-white">Dia 5: Lembrete</h3>
              <p className="text-base pb-6 ml-5 font-normal text-gray-500 dark:text-gray-400">Não se preocupe, enviaremos um lembrete avisando que seu período de garantia está acabando.</p>
            </li>
            <li className="ms-6 ml-5">
              <span className="absolute flex items-center justify-center w-8 h-8 bg-[#182046] rounded-full left-0">
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.834 1.33594H5.16732C3.32932 1.33594 1.83398 2.83127 1.83398 4.66927V11.3359C1.83398 13.1739 3.32932 14.6693 5.16732 14.6693H13.1673C14.27 14.6693 15.1673 13.7719 15.1673 12.6693C15.1673 11.5666 14.27 10.6693 13.1673 10.6693H4.50065V12.0026H13.1673C13.3441 12.0026 13.5137 12.0728 13.6387 12.1979C13.7637 12.3229 13.834 12.4925 13.834 12.6693C13.834 12.8461 13.7637 13.0157 13.6387 13.1407C13.5137 13.2657 13.3441 13.3359 13.1673 13.3359H5.16732C4.06465 13.3359 3.16732 12.4386 3.16732 11.3359C3.16732 10.2333 4.06465 9.33594 5.16732 9.33594H13.834C14.5693 9.33594 15.1673 8.73794 15.1673 8.0026V2.66927C15.1673 1.93394 14.5693 1.33594 13.834 1.33594ZM13.834 8.0026H5.16732C4.44572 8.00168 3.74352 8.23622 3.16732 8.6706V4.66927C3.16732 3.5666 4.06465 2.66927 5.16732 2.66927H13.834V8.0026Z" fill="white"/>
                </svg>
              </span>
              <h3 className="mb-1 ml-5 text-lg font-semibold text-gray-900 dark:text-white">Dia 7: Garantia Acaba</h3>
              <p className="text-base pb-6 ml-5 font-normal text-gray-500 dark:text-gray-400">Último dia para você solicitar seu reembolso.</p>
            </li>
          </ol>


        </div>
      </div>

      <div className="flex flex-col rounded-xl mb-4">
        <h2 className="mb-4 mt-5 text-2xl font-bold tracking-tight text-gray-900  md:text-3xl lg:text-4xl">
          {t("select_plan.faq.headline")}
        </h2>

        <div className="flex flex-col p-3 gap-2">

          <div
            className="bg-white border-b border-slate-300"
            onClick={() => setCurrentFaq(1)}
          >
            <div className="flex justify-between items-center p-3 cursor-pointer">
              <h3 className="text-md font-semibold text-gray-800 text-left">
                {t("select_plan.faq.q1.question")}
              </h3>
              <div className="flex items-center">
                {currentFaq === 1 ? <CloseIcon /> : <OpenIcon />}
              </div>
            </div>
            {currentFaq === 1 && (
            <div className="p-3 bg-gray-50 text-left">
              <p className="text-gray-600">
                {t("select_plan.faq.q1.answer")}
              </p>
            </div>
            )}
          </div>

          <div
            className="bg-white border-b border-slate-300"
            onClick={() => setCurrentFaq(2)}
          >
            <div className="flex justify-between items-center p-3 cursor-pointer">
              <h3 className="text-md font-semibold text-gray-800 text-left">
                {t("select_plan.faq.direct_sale_q2.question")}
              </h3>
              <div className="flex items-center">
                {currentFaq === 2 ? <CloseIcon /> : <OpenIcon />}
              </div>
            </div>
            {currentFaq === 2 && (
            <div className="p-3 bg-gray-50 text-left">
              <p className="text-gray-600">
                {t("select_plan.faq.direct_sale_q2.answer")}
              </p>
            </div>
            )}
          </div>

          <div
            className="bg-white border-b border-slate-300"
            onClick={() => setCurrentFaq(3)}
          >
            <div className="flex justify-between items-center p-3 cursor-pointer">
              <h3 className="text-md font-semibold text-gray-800 text-left">
                {t("select_plan.faq.q3.question")}
              </h3>
              <div className="flex items-center">
                {currentFaq === 3 ? <CloseIcon /> : <OpenIcon />}
              </div>
            </div>
            {currentFaq === 3 && (
            <div className="p-3 bg-gray-50 text-left">
              <p className="text-gray-600">
                {t("select_plan.faq.q3.answer")}
              </p>
            </div>
            )}
          </div>

          <div
            className="bg-white"
            onClick={() => setCurrentFaq(4)}
          >
            <div className="flex justify-between items-center p-3 cursor-pointer">
              <h3 className="text-md font-semibold text-gray-800 text-left">
                {t("select_plan.faq.q4.question")}
              </h3>
              <div className="flex items-center">
                {currentFaq === 4 ? <CloseIcon /> : <OpenIcon />}
              </div>
            </div>
            {currentFaq === 4 && (
            <div className="p-3 bg-gray-50 text-left">
              <p className="text-gray-600">
                {t("select_plan.faq.q4.answer")}
              </p>
            </div>
            )}
          </div>

        </div>
      </div>

    </div>
  );
}

export default SelectPlanV3;
