import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useScreenContext, Option, relativePath, OptionRadio, useStepNumberManager } from "../Layout";
import { useNavigate, useParams } from "react-router-dom";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

function Time() {
  const { t } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const navigate = useNavigate();
  const { offerType } = useParams();

  const isDirectSale = offerType === "direct";
  const isRetSale = offerType === "ret";

  const currentFunnel = sessionStorage.getItem("currentFunnel");

  const stepNumber = useStepNumberManager(4);

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: undefined,
      nextPath: relativePath("/reading-stats", offerType),
      progress: stepNumber,
      step: stepNumber,
    }));
  }, []);

  const handleOptionSelection = (option: string) => {
    setScreenData(() => ({
      ...screenData,
      userPreferences: {
        ...screenData.userPreferences,
        time: option,
      },
    }));

    navigate(screenData.nextPath);
  };

  return (
    <div>
      <h1 className="mb-4 text-2xl font-bold tracking-tight text-gray-900  md:text-5xl">
        {t(`time${ currentFunnel ? '.'+currentFunnel : '' }.headline`)}
      </h1>
      <div className="mt-10">
        <OptionRadio onClick={() => handleOptionSelection("15min")} name={"time"} value={"15min"}>
          {t("time.option_1")}
        </OptionRadio>
        <OptionRadio onClick={() => handleOptionSelection("15-30min")} name={"time"} value={"15-30min"}>
          {t("time.option_2")}
        </OptionRadio>
        <OptionRadio onClick={() => handleOptionSelection("30min+")} name={"time"} value={"30min+"}>
          {t("time.option_3")}
        </OptionRadio>
      </div>
    </div>
  );
}

export default Time;
