import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useScreenContext, relativePath, useStepNumberManager } from "../Layout";
import { useParams, useSearchParams } from "react-router-dom";
import { useSessionStorage } from "react-use";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

function SelectChallenge() {
  const { t, i18n } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const [selectedChallenge, setSelectedChallenge] = useState<Number>(7);
  const [searchParams] = useSearchParams();
  const { offerType } = useParams();
  const [flowStrategy, setFlowStrategy] = useSessionStorage(
    "flow-strategy",
    ""
  );

  const isDirectSale = offerType === "direct";
  const isRetSale = offerType === "ret";
  const isAppFunnel = offerType === "app";
  
  const isExp46 = useFeatureIsOn("exp-46-social-proof");

  const userParams = {
    name: searchParams.get("name"),
    email: searchParams.get("email"),
    phone: searchParams.get("phone"),
  };

  const urlParams = {
    ...userParams,
    hidePix: (isDirectSale || isRetSale || isAppFunnel) ? 0 : 1,
    hideBillet: (isDirectSale || isRetSale || isAppFunnel) ? 0 : 1,
    checkoutMode: 10,
    split: 12,
  };

  const Challenge7DaysImage = {
    br: require("../../assets/images/challenge7.br.png"),
    en: require("../../assets/images/challenge7.en.png"),
    es: require("../../assets/images/challenge7.es.png"),
  }[i18n.language];

  const Challenge28DaysImage = {
    br: require("../../assets/images/challenge28.br.png"),
    en: require("../../assets/images/challenge28.en.png"),
    es: require("../../assets/images/challenge28.es.png"),
  }[i18n.language];

  const stepNumber = useStepNumberManager(22);

  useEffect(() => {
    if (searchParams.get("flowStrategy") === "lead-post-plan-selection") {
      setFlowStrategy("lead-post-plan-selection");
    }

    setScreenData(() => ({
      ...screenData,
      submitLabel: t("layout.cta_continue"),
      nextPath: relativePath("/talking-about", offerType),
      progress: stepNumber,
      step: stepNumber,
      urlParams:
        Object.keys(screenData.urlParams).length === 0
          ? urlParams
          : screenData.urlParams,
    }));
  }, []);

  return (
    <div>
      <h1 className="mb-4 mt-5 text-4xl font-bold tracking-tight text-gray-900  md:text-5xl lg:text-6xl">
        {t("select_challenge.headline")}
      </h1>
      <p className="text-md mb-0 font-normal text-gray-900 lg:text-xl">
        {t("select_challenge.subheadline")}
      </p>
      <div>
        <button
          type="button"
          className={
            "mt-6 mr-2 transform rounded-md px-10 py-1 text-xl font-normal text-gray-900 " +
            (selectedChallenge === 7 ? "bg-amber-200" : "bg-amber-100")
          }
          onClick={() => setSelectedChallenge(7)}
        >
          {t("select_challenge.7_days")}
        </button>
        <button
          type="button"
          className={
            "mt-6 mr-2 transform rounded-md px-10 py-1 text-xl font-normal text-gray-900 " +
            (selectedChallenge === 28 ? "bg-amber-200" : "bg-amber-100")
          }
          onClick={() => setSelectedChallenge(28)}
        >
          {t("select_challenge.28_days")}
        </button>
      </div>
      <img
        className="mx-auto px-12"
        alt="Chart showing your progress"
        src={
          selectedChallenge === 7 ? Challenge7DaysImage : Challenge28DaysImage
        }
      />
    </div>
  );
}

export default SelectChallenge;
