import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useScreenContext, useStepNumberManager } from "../Layout";
import { useParams } from "react-router-dom";
import { useSessionStorage } from "react-use";
import * as rudderanalytics from "../../lib/rudderstack";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { useTimer } from "react-timer-hook";

import IconBooks from "../../assets/images/icon-books.png";
import IconHeadphone from "../../assets/images/icon-headphone.png";
import IconTarget from "../../assets/images/icon-target.png";

const OpenIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="h-6 w-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
    />
  </svg>
);

const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="h-6 w-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.5 15.75l7.5-7.5 7.5 7.5"
    />
  </svg>
);

function AppInstall() {
  const { t, i18n } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const [selectedPlan, setselectedPlan] = useState<string>("yearly");
  const [currentFaq, setCurrentFaq] = useState<number>(0);
  const { offerType } = useParams();

  const buildNextPath = () => {
    return "https://app.adjust.com/1ab1p3oq";
  };

  const stepNumber = useStepNumberManager(24);

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: t("app_install.cta_continue"),
      nextPath: buildNextPath(),
      progress: stepNumber,
      step: stepNumber,
    }));
  }, [selectedPlan]);

  return (
    <div>
      <div>
        <h1 className="mb-4 mt-5 text-3xl font-bold tracking-tight text-gray-900  md:text-5xl lg:text-6xl">
          {t("app_install.headline-transformation")}
        </h1>

        <div>
          <div
            onClick={() => setselectedPlan("yearly")}
            className="mt-10 cursor-pointer border-4 text-gray-900 border-green-400"
          >
            <div className="flex bg-gray-100 px-4 py-4 text-left">
              <div className="pr-4">
                <div className="mt-1.5 rounded-full border-2 border-gray-900 bg-white p-0.5">
                  <div
                    className={
                      "h-2 w-2 rounded-full  bg-gray-900 " +
                      (selectedPlan === "yearly" ? "bg-gray-900" : "bg-white")
                    }
                  ></div>
                </div>
              </div>
              <div>
                <h2 className="mt-1 font-bold block text-sm">
                  {t("app_install.title_plan")}
                </h2>
              </div>
            </div>

            <div className="ml-6 p-4 text-left">
              <b className="text-sm">
                
              </b>
              <div className="mt-2 text-left text-sm">
                <div className="mb-3 flex gap-2 items-center">
                  <img src={IconBooks} alt="icon books" width={48} />
                  <p className="font-bold text-sm">{t("app_install.plan_box.books")}</p>
                </div>
                <div className="mb-3 flex gap-2 items-center">
                  <img src={IconHeadphone} alt="icon headphone" width={48} />
                  <p className="font-bold text-sm">{t("app_install.plan_box.audio")}</p>
                </div>
                <div className="mb-3 flex gap-2 items-center">
                  <img src={IconTarget} alt="icon target" width={48} />
                  <p className="font-bold text-sm">{t("app_install.plan_box.target")}</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>


      <div>
        <h2 className="mb-4 mt-5 text-2xl font-bold tracking-tight text-gray-900  md:text-3xl lg:text-4xl">
          {t("app_install.faq.headline")}
        </h2>

        <div className="text-left">
          <div className="border-b border-gray-500">
            <div
              className="flex cursor-pointer"
              onClick={() => setCurrentFaq(1)}
            >
              <div className="mt-3 pr-3">
                {currentFaq === 1 ? <CloseIcon /> : <OpenIcon />}
              </div>
              <div>
                <h3 className="py-3 font-bold">
                  {t("app_install.faq.q1.question")}
                </h3>
              </div>
            </div>
            {currentFaq === 1 && (
              <div className="pb-3">
                <p className="text-sm font-light text-gray-700 ">
                  {t("app_install.faq.q1.answer")}
                </p>
              </div>
            )}
          </div>

          <div className="border-b border-gray-500">
            <div
              className="flex cursor-pointer"
              onClick={() => setCurrentFaq(2)}
            >
              <div className="mt-3 pr-3">
                {currentFaq === 2 ? <CloseIcon /> : <OpenIcon />}
              </div>
              <div>
                <h3 className="py-3 font-bold">
                  {t("app_install.faq.q2.question")}
                </h3>
              </div>
            </div>
            {currentFaq === 2 && (
              <div className="pb-3">
                <p className="text-sm font-light text-gray-700 ">
                  {t("app_install.faq.q2.answer")}
                </p>
              </div>
            )}
          </div>

          <div className="border-b border-gray-500">
            <div
              className="flex cursor-pointer"
              onClick={() => setCurrentFaq(3)}
            >
              <div className="mt-3 pr-3">
                {currentFaq === 3 ? <CloseIcon /> : <OpenIcon />}
              </div>
              <div>
                <h3 className="py-3 font-bold">
                  {t("app_install.faq.q3.question")}
                </h3>
              </div>
            </div>
            {currentFaq === 3 && (
              <div className="pb-3">
                <p className="text-sm font-light text-gray-700 ">
                  {t("app_install.faq.q3.answer")}
                </p>
              </div>
            )}
          </div>

          <div className="border-gray-500 pb-20">
            <div
              className="flex cursor-pointer"
              onClick={() => setCurrentFaq(4)}
            >
              <div className="mt-3 pr-3">
                {currentFaq === 4 ? <CloseIcon /> : <OpenIcon />}
              </div>
              <div>
                <h3 className="py-3 font-bold">
                  {t("app_install.faq.q4.question")}
                </h3>
              </div>
            </div>
            {currentFaq === 4 && (
              <div className="pb-3">
                <p className="text-sm font-light text-gray-700 ">
                  {t("app_install.faq.q4.answer")}
                </p>
              </div>
            )}
          </div>

        </div>
      </div>
    </div>
  );
}

export default AppInstall;
