import Logo from "../../../assets/images/12min-logo.svg";
import bookListBDayPT from "../../../assets/images/book-list-b-day-PT.png";

function LandingTikTok(): JSX.Element {
  return (
    <div className="flex justify-center items-center">
        <div className="flex flex-col items-center text-center max-w-md px-4">
            <header className="my-4">
                <img className="w-[120px]" src={Logo} alt="12min Logo" />
            </header>
            <h1 className="text-2xl font-bold">
                Troque as redes sociais por momentos de aprendizado
            </h1>
            <span className="bg-black text-sm text-white font-semibold rounded-full py-2 px-4 mt-4 w-full max-w-[378px]">
                Os resumos dos maiores livros em 12min
            </span>
            <img className="w-full max-w-[378px] mt-2" src={bookListBDayPT} alt="Books selection" />
            <div className="flex justify-center items-center">
                <svg className="h-4 w-4" focusable="false" aria-hidden="true" viewBox="0 0 24 24"><path d="M7.02334 18.6437L7.02599 18.6445L6.0601 20.3493C5.70753 20.9715 4.92661 21.1848 4.31593 20.8255C3.70528 20.4662 3.49603 19.6705 3.84859 19.0482L4.62843 17.6719C4.75038 17.4932 5.05133 17.185 5.65332 17.243C6.31682 17.3163 7.59 17.7857 7.02334 18.6437ZM20.7232 14.2895H17.7111C17.506 14.2755 17.4164 14.2009 17.3813 14.1575L17.379 14.1536L14.1547 8.46293L14.1506 8.46578L13.9572 8.18325C13.6403 7.68944 13.1371 8.95247 13.1371 8.95247C12.5362 10.3598 13.2223 11.9599 13.4615 12.4436L17.9399 20.3475C18.2924 20.9698 19.0734 21.183 19.684 20.8237C20.2947 20.4645 20.5039 19.6687 20.1514 19.0465L19.0315 17.07C19.0098 17.0222 18.9721 16.8921 19.2017 16.8916H20.7232C21.4283 16.8916 22 16.3091 22 15.5906C22 14.872 21.4283 14.2895 20.7232 14.2895ZM14.8728 16.0583C14.8728 16.0583 15.0336 16.8916 14.4115 16.8916H13.7894L3.2768 16.8916C2.57165 16.8916 2 16.3091 2 15.5906C2 14.872 2.57165 14.2895 3.2768 14.2895H6.13941C6.60158 14.2622 6.71115 13.9904 6.71115 13.9904L6.71367 13.9917L10.4503 7.39677L10.4492 7.39654C10.5173 7.26914 10.4606 7.14877 10.4507 7.12971L9.21672 4.95177C8.86413 4.3295 9.07338 3.53378 9.68406 3.17453C10.2947 2.81525 11.0756 3.02846 11.4282 3.65073L12.0005 4.66086L12.5718 3.65253C12.9244 3.03026 13.7053 2.81704 14.316 3.17632C14.9267 3.53561 15.1359 4.33132 14.7833 4.95356L9.58363 14.1305C9.5609 14.1864 9.55394 14.2741 9.72309 14.2895H12.8312H12.8318C12.8318 14.2895 14.6282 14.3489 14.8728 16.0583Z"></path></svg>
                <span className="text-sm mx-2">78k avaliações</span>
                <div className="rating-stars flex justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="rgb(251 146 60)" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="inline-block h-4 w-4 text-orange-400"><path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"></path></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="rgb(251 146 60)" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="inline-block h-4 w-4 text-orange-400"><path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"></path></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="rgb(251 146 60)" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="inline-block h-4 w-4 text-orange-400"><path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"></path></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="rgb(251 146 60)" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="inline-block h-4 w-4 text-orange-400"><path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"></path></svg>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="inline-block h-4 w-4 text-orange-400"><path d="M11.4787 3.49726C11.521 3.39409 11.593 3.30584 11.6856 3.24372C11.7782 3.18161 11.8872 3.14844 11.9987 3.14844C12.1102 3.14844 12.2192 3.18161 12.3118 3.24372C12.4044 3.30584 12.4764 3.39409 12.5187 3.49726L14.6437 8.60826C14.6835 8.7039 14.7489 8.78671 14.8327 8.84757C14.9165 8.90844 15.0154 8.94501 15.1187 8.95326L20.6367 9.39526C21.1357 9.43526 21.3377 10.0583 20.9577 10.3833L16.7537 13.9853C16.6751 14.0525 16.6166 14.14 16.5845 14.2382C16.5524 14.3365 16.548 14.4417 16.5717 14.5423L17.8567 19.9273C17.8825 20.0353 17.8757 20.1486 17.8372 20.2528C17.7987 20.357 17.7302 20.4474 17.6403 20.5127C17.5504 20.5779 17.4432 20.6151 17.3322 20.6195C17.2212 20.6239 17.1114 20.5953 17.0167 20.5373L12.2917 17.6523C12.2034 17.5985 12.1021 17.57 11.9987 17.57C11.8953 17.57 11.7939 17.5985 11.7057 17.6523L6.98069 20.5383C6.88594 20.5963 6.77614 20.6249 6.66514 20.6205C6.55415 20.6161 6.44694 20.5789 6.35705 20.5137C6.26717 20.4484 6.19865 20.358 6.16014 20.2538C6.12163 20.1496 6.11486 20.0363 6.14069 19.9283L7.42569 14.5423C7.44952 14.4417 7.44514 14.3364 7.41303 14.2382C7.38092 14.1399 7.32232 14.0524 7.24369 13.9853L3.03969 10.3833C2.9555 10.3109 2.8946 10.2152 2.86464 10.1083C2.83467 10.0014 2.83699 9.888 2.8713 9.78241C2.90561 9.67681 2.97037 9.58372 3.05745 9.51483C3.14453 9.44595 3.25003 9.40435 3.36069 9.39526L8.87869 8.95326C8.98194 8.94501 9.08091 8.90844 9.16471 8.84757C9.24852 8.78671 9.31391 8.7039 9.35369 8.60826L11.4787 3.49726Z" fill="#FB923C" stroke="#FB923C"></path><path d="M20.6352 9.39513L15.1172 8.95312L16.5 15.5L16.7522 13.9851L20.9562 10.3831C21.3362 10.0581 21.1342 9.43513 20.6352 9.39513Z" fill="white"></path></svg></div>
            </div>
            <a 
                href="https://lg65.adj.st/upgrade?adj_t=1f1lqmyi&adj_deep_link=twelvemin://upgrade&adj_fallback=https%3A%2F%2Fweb.12min.com&adj_redirect_macos=https%3A%2F%2Fweb.12min.com"
                className="text-center text-white font-bold bg-blue-600 rounded-xl py-5 mt-4 w-full max-w-[378px] hover:bg-blue-500 transition duration-200"
            >
                Ir para a loja de aplicativos
            </a>
        </div>
    </div>
  );
}

export default LandingTikTok;