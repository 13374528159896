import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useScreenContext } from "../Layout";
import { useParams } from "react-router-dom";
import { useSessionStorage } from "react-use";
import * as rudderanalytics from "../../lib/rudderstack";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { useTimer } from "react-timer-hook";

import imageSelectPlanExp16 from "../../assets/images/select-plan-exp-16.png";

function TimerBox() {
  const time = new Date();
  time.setSeconds(time.getSeconds() + 833);

  const { seconds, minutes, hours } = useTimer({ expiryTimestamp: time });

  return (
    <div>
      <div className="inline-block bg-red-600 p-2 font-bold text-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="inline-block h-6 w-6 pr-1"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        {hours.toString().padStart(2, "0")}:
        {minutes.toString().padStart(2, "0")}:
        {seconds.toString().padStart(2, "0")}
      </div>
      <p>Oferta por tempo limitado</p>
    </div>
  );
}

const OpenIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="h-6 w-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
    />
  </svg>
);

const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="h-6 w-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.5 15.75l7.5-7.5 7.5 7.5"
    />
  </svg>
);

const converObjectToParams = (object: any) => {
  return Object.keys(object)
    .filter((key) => object[key])
    .map((key) => {
      return `${key}=${encodeURIComponent(object[key])}`;
    })
    .join("&");
};

function SelectPlanExp16() {
  const { t, i18n } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const [showOthersPlans, setShowOthersPlans] = useState<boolean>(false);
  const [selectedPlan, setselectedPlan] = useState<string>("yearly");
  const [currentFaq, setCurrentFaq] = useState<number>(0);
  const { offerType } = useParams();
  const [flowStrategy, setFlowStrategy] = useSessionStorage(
    "flow-strategy",
    ""
  );
  const isRet = sessionStorage.getItem("utm_source") === "ret";
  const isFromTwitter = sessionStorage.getItem("utm_source") === "twitter";

  // const newPricing = useFeatureIsOn("yearly-plan-pricing-feature");
  const newPricing = useFeatureIsOn("exp-12-63-vs-59-off-with-the-bundle");
  const removeMonthly = useFeatureIsOn("remove-monthly-plan-feature");
  const newHeadline = useFeatureIsOn("select-plan-headline-feature")
  // const checkout12minPremium = useFeatureIsOn("checkout-12min-premium-feature");
  const exp16Checkout = useFeatureIsOn("exp-16-checkout-feature");

  const isPostLeadFlowStrategy = flowStrategy === "lead-post-plan-selection";
  const [variant, _] = useSessionStorage("variant");

  const isDirectSale = offerType === "direct";
  const isRetSale = offerType === "ret";

  const isWithInterestVariant = variant === "with-interest" && isDirectSale;

  const activePlan = (isDirectSale || isRetSale) && selectedPlan === "yearly";

  const checkoutId: any = {
    br: activePlan ? "E91856682Y" : "P5327674P",
    en: "K16542811H",
    es: activePlan ? "Y92363487F" : "Y16613490E",
  }[i18n.language];

  const offerCodeList: Record<string, string> = {
    twitter: "xrnblfqt",
    default: "7d18zm3s",
    exp16: "6reyasj1"
  };

  const offerCodeDirect: () => string = () => {
    if (isFromTwitter) return offerCodeList.twitter;
    if (exp16Checkout) return offerCodeList.exp16;
    return offerCodeList.default;
  };

  const discount = isRetSale  
    ? "promo75off" 
    : (newPricing 
        ? "promo59off" 
        : "promo63off"
      );

  const offersTrial: any = {
    discounts: {
      base: "63OFF7D",
    },
    P5327674P: {
      yearly: { offerCode: "2zki1nl0" },
      monthly: { offerCode: "jx5nh72y" },
    },
    K16542811H: {
      yearly: { offerCode: "38tbqvq8" },
      monthly: { offerCode: "fys1w0n7" },
    },
    Y92363487F: {
      yearly: { offerCode: "vng1dkxz" },
      monthly: { offerCode: "7lcaoc5b" },
    },
  };

  const offersDirectSale: any = {
    discounts: {
      base: discount,
    },
    P5327674P: {
      yearly: { offerCode: "suxzbi7s" },
      monthly: { offerCode: "kppi78af" },
    },
    E91856682Y: {
      yearly: { offerCode: offerCodeDirect() },
      monthly: { offerCode: "kppi78af" },
    },
    K16542811H: {
      yearly: { offerCode: "evc05pnv" },
      monthly: { offerCode: "b3i96d7z" },
    },
    Y16613490E: {
      yearly: { offerCode: "oqvwtnza" },
      monthly: { offerCode: "eptks9rt" },
    },
    Y92363487F: {
      yearly: { offerCode: "98fq4cvr" },
      monthly: { offerCode: "prlt2kyv" },
    },
  };

  const offersRetSale: any = {
    discounts: {
      base: "promo75off",
    },
    E91856682Y: {
      yearly: { offerCode: "7d18zm3s" },
      monthly: { offerCode: "kppi78af" },
    },
  };

  const offersOptions: any = () => {
    if (isDirectSale) return offersDirectSale;
    if (isRetSale) return offersRetSale;
    return offersTrial;
  };

  const offers: any = offersOptions();
  const currentCheckout = offers[checkoutId];

  const buildCheckoutLink = () => {
    const baseUrl = `https://pay.hotmart.com/${checkoutId}`;
    const urlParams = !isPostLeadFlowStrategy ? screenData.urlParams : {};
    return (
      baseUrl +
      "?" +
      converObjectToParams({
        ...urlParams,
        off: currentCheckout[selectedPlan].offerCode,
        offDiscount: selectedPlan === "yearly" ? offers.discounts.base : false,
        sck: rudderanalytics.getAnonymousId(),
        checkoutMode: "10",
      })
    );
  };

  const buildNextPath = () => {
    const checkoutLink = buildCheckoutLink();
    return !isPostLeadFlowStrategy
      ? checkoutLink
      : "form?redirectUrl=" + encodeURIComponent(checkoutLink);
  };

  const exp19feature = useFeatureIsOn("exp-19-feature");
  const step = exp19feature ? 23 : 19;

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: t("layout.cta_continue"),
      nextPath: buildNextPath(),
      progress: 100,
      progressVariation: step,
      step: step,
    }));
  }, [selectedPlan]);

  return (
    <div>
      <div>
        <h1 className="mb-4 mt-5 text-2xl tracking-tight text-gray-900  md:text-5xl lg:text-6xl">
          Selecione um plano para acessar seu Perfil de <strong>Crescimento</strong> Pessoal.
        </h1>

        <div className="flex justify-center mt-4 mb-8">
          <img className="max-w-xs w-full" src={imageSelectPlanExp16} alt="select plan" />
        </div>

        <p>{newHeadline ? <b>12 minutos = 2 horas de leitura!</b> : t("select_plan.subheadline-transformation")}</p>

        <p className="pt-5">
          <TimerBox />
        </p>
        <div>
          <div
            onClick={() => setselectedPlan("yearly")}
            className={
              "mt-10 cursor-pointer border-4 text-gray-900 " +
              (selectedPlan === "yearly"
                ? "border-green-400"
                : "border-gray-400")
            }
          >
            <div className="flex bg-gray-100 p-4 py-6 text-left">
              <div className="pr-4">
                <div className="mt-1.5 rounded-full border-2 border-gray-900 bg-white p-0.5">
                  <div
                    className={
                      "h-2 w-2 rounded-full  bg-gray-900 " +
                      (selectedPlan === "yearly" ? "bg-gray-900" : "bg-white")
                    }
                  ></div>
                </div>
              </div>
              <div>
                <div className="-ml-11 -mt-10 mb-2 w-28  bg-red-600 py-1 text-center text-xs text-white">
                  {t(
                    `select_plan.yearly.${
                      (isDirectSale ? "direct_sale_" : "") 
                      || (isRetSale ? "ret_sale_" : "")
                    }save_offer${newPricing ? "_variant" : ""}`
                  )}
                </div>
                <b className="mt-5 block text-sm">
                  {t(
                    `select_plan.yearly.plan_name${
                      newPricing ? "_variant" : ""
                    }`
                  )}
                </b>
                <div className="py-2">
                  <b className="block text-xs font-normal text-red-600 line-through">
                    {t(
                      `select_plan.yearly.${
                        (isDirectSale || isRetSale) ? "direct_sale_" : ""
                      }plan_price_per_month_without_discount${
                        newPricing ? "_variant" : ""
                      }`
                    )}
                  </b>
                  <b className="block">
                    {t(
                      `select_plan.yearly.${
                        (isDirectSale ? "direct_sale_" : "")
                        || (isRetSale ? "ret_sale_" : "")
                      }plan_price_per_month${newPricing ? "_variant" : ""}`
                    )}
                  </b>
                </div>
                <b className="block text-sm text-blue-700">
                  {t(
                    `select_plan.yearly.${
                      (isDirectSale || isRetSale) ? "direct_sale_" : ""
                    }free_trial`
                  )}
                </b>
              </div>
            </div>
          </div>
          {!removeMonthly && (
            <div
              className="py-3 text-sm text-blue-600"
              onClick={() => setShowOthersPlans(!showOthersPlans)}
            >
              <span className="cursor-pointer">
                {t("select_plan.show_others_plans")}
              </span>
            </div>
          )}

          {showOthersPlans && (
            <div
              onClick={() => setselectedPlan("monthly")}
              className={
                "cursor-pointer border-4  text-gray-900 " +
                (selectedPlan === "monthly"
                  ? "border-green-400"
                  : "border-gray-400")
              }
            >
              <div className="flex bg-gray-100 p-4 py-6 text-left">
                <div className="pr-4">
                  <div className="mt-0.5 rounded-full border-2 border-gray-900 bg-white p-0.5">
                    <div
                      className={
                        "h-2 w-2 rounded-full  bg-gray-900 " +
                        (selectedPlan === "monthly"
                          ? "bg-gray-900"
                          : "bg-white")
                      }
                    ></div>
                  </div>
                </div>
                <div>
                  <b className="block text-sm">
                    {t("select_plan.monthly.plan_name")}
                  </b>
                  <div className="py-2">
                    <b className="block">
                      {t(
                        `select_plan.monthly.${
                          (isDirectSale || isRetSale) ? "direct_sale_" : ""
                        }plan_price_per_month${
                          isWithInterestVariant ? "_with_interest" : ""
                        }`
                      )}
                    </b>
                  </div>
                  <b className="block text-sm text-blue-700">
                    {t(
                      `select_plan.monthly.${
                        (isDirectSale || isRetSale) ? "direct_sale_" : ""
                      }no_trial`
                    )}
                  </b>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {variant === "select-plan-green-box" && (
        <div>
          <div className="my-5 rounded bg-green-200 px-5 pt-1 pb-4">
            <h2 className="mb-4 mt-5 text-xl font-bold tracking-tight text-gray-900  md:text-2xl lg:text-3xl">
              {t("select_plan.green_box.headline")}
            </h2>
            <div className="text-left">
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="mb-1 mr-1 inline-block h-5 w-5 text-green-700"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                  />
                </svg>
                {t("select_plan.green_box.item1")}
              </p>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="mb-1 mr-1 inline-block h-5 w-5 text-green-700"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                  />
                </svg>
                {t("select_plan.green_box.item2")}
              </p>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="mb-1 mr-1 inline-block h-5 w-5 text-green-700"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                  />
                </svg>
                {t("select_plan.green_box.item3")}
              </p>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="mb-1 mr-1 inline-block h-5 w-5 text-green-700"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                  />
                </svg>
                {t("select_plan.green_box.item4")}
              </p>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="mb-1 mr-1 inline-block h-5 w-5 text-green-700"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                  />
                </svg>
                {t("select_plan.green_box.item5")}
              </p>
            </div>
          </div>
        </div>
      )}

      <div>
        <h2 className="mb-4 mt-5 text-2xl font-bold tracking-tight text-gray-900  md:text-3xl lg:text-4xl">
          {t("select_plan.faq.headline")}
        </h2>

        <div className="text-left">
          <div className="border-b border-gray-500">
            <div
              className="flex cursor-pointer"
              onClick={() => setCurrentFaq(1)}
            >
              <div className="mt-3 pr-3">
                {currentFaq === 1 ? <CloseIcon /> : <OpenIcon />}
              </div>
              <div>
                <h3 className="py-3 font-bold">
                  {t("select_plan.faq.q1.question")}
                </h3>
              </div>
            </div>
            {currentFaq === 1 && (
              <div className="pb-3">
                <p className="text-sm font-light text-gray-700 ">
                  {t("select_plan.faq.q1.answer")}
                </p>
              </div>
            )}
          </div>

          <div className="border-b border-gray-500">
            <div
              className="flex cursor-pointer"
              onClick={() => setCurrentFaq(7)}
            >
              <div className="mt-3 pr-3">
                {currentFaq === 7 ? <CloseIcon /> : <OpenIcon />}
              </div>
              <div>
                <h3 className="py-3 font-bold">
                  O que é o Perfil de Crescimento Pessoal do 12min?
                </h3>
              </div>
            </div>
            {currentFaq === 7 && (
              <div className="pb-3">
                <p className="text-sm font-light text-gray-700 ">
                  O Perfil de Crescimento Pessoal do 12min é o mapa da sua jornada de desenvolvimento. Nele você vai ter acesso à uma biblioteca personalizada baseada nos seus objetivos, habilidades, tempo disponível, entre outros fatores, que vai te ajudar a subir de nível e alcançar novos patamares na sua vida.
                </p>
              </div>
            )}
          </div>

          <div className="border-b border-gray-500">
            <div
              className="flex cursor-pointer"
              onClick={() => setCurrentFaq(2)}
            >
              <div className="mt-3 pr-3">
                {currentFaq === 2 ? <CloseIcon /> : <OpenIcon />}
              </div>
              <div>
                <h3 className="py-3 font-bold">
                  {t(
                    `select_plan.faq.${
                      (isDirectSale || isRetSale) ? "direct_sale_" : ""
                    }q2.question`
                  )}
                </h3>
              </div>
            </div>
            {currentFaq === 2 && (
              <div className="pb-3">
                <p className="text-sm font-light text-gray-700 ">
                  {t(
                    `select_plan.faq.${
                      (isDirectSale || isRetSale) ? "direct_sale_" : ""
                    }q2.answer`
                  )}
                </p>
              </div>
            )}
          </div>

          <div className="border-b border-gray-500">
            <div
              className="flex cursor-pointer"
              onClick={() => setCurrentFaq(3)}
            >
              <div className="mt-3 pr-3">
                {currentFaq === 3 ? <CloseIcon /> : <OpenIcon />}
              </div>
              <div>
                <h3 className="py-3 font-bold">
                  {t("select_plan.faq.q3.question")}
                </h3>
              </div>
            </div>
            {currentFaq === 3 && (
              <div className="pb-3">
                <p className="text-sm font-light text-gray-700 ">
                  {t("select_plan.faq.q3.answer")}
                </p>
              </div>
            )}
          </div>

          <div className="border-b border-gray-500">
            <div
              className="flex cursor-pointer"
              onClick={() => setCurrentFaq(4)}
            >
              <div className="mt-3 pr-3">
                {currentFaq === 4 ? <CloseIcon /> : <OpenIcon />}
              </div>
              <div>
                <h3 className="py-3 font-bold">
                  {t("select_plan.faq.q4.question")}
                </h3>
              </div>
            </div>
            {currentFaq === 4 && (
              <div className="pb-3">
                <p className="text-sm font-light text-gray-700 ">
                  {t("select_plan.faq.q4.answer")}
                </p>
              </div>
            )}
          </div>

          <div className="border-b border-gray-500">
            <div
              className="flex cursor-pointer"
              onClick={() => setCurrentFaq(5)}
            >
              <div className="mt-3 pr-3">
                {currentFaq === 5 ? <CloseIcon /> : <OpenIcon />}
              </div>
              <div>
                <h3 className="py-3 font-bold">
                  {t(
                    `select_plan.faq.${
                      (isDirectSale || isRetSale) ? "direct_sale_" : ""
                    }q5.question`
                  )}
                </h3>
              </div>
            </div>
            {currentFaq === 5 && (
              <div className="pb-3">
                <p className="text-sm font-light text-gray-700 ">
                  {t(
                    `select_plan.faq.${
                      (isDirectSale || isRetSale) ? "direct_sale_" : ""
                    }q5.answer`
                  )}
                </p>
              </div>
            )}
          </div>

          <div className="border-gray-500 pb-20">
            <div
              className="flex cursor-pointer"
              onClick={() => setCurrentFaq(6)}
            >
              <div className="mt-3 pr-3">
                {currentFaq === 6 ? <CloseIcon /> : <OpenIcon />}
              </div>
              <div>
                <h3 className="py-3 font-bold">
                  {t("select_plan.faq.q6.question")}
                </h3>
              </div>
            </div>
            {currentFaq === 6 && (
              <div className="pb-3">
                <p className="text-sm font-light text-gray-700 ">
                  {t("select_plan.faq.q6.answer")}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectPlanExp16;
