import { useEffect } from "react";
import { useScreenContext, useStepNumberManager } from "../Layout";
import InfoImage from "../../assets/images/info.png";
import { useTranslation } from "react-i18next";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { useParams } from "react-router-dom";

function Info() {
  const { t } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const { offerType } = useParams();

  const isSlFunnel = offerType === "sl";

  const isExp45Microbooks = useFeatureIsOn("exp-45-copy-test-microbooks");

  type TextType = {
    [key: string]: {
      headline: string;
      subheadline: string;
    };
  };
  
  type StepType = {
    [key: string]: number;
  };

  const text: TextType = {
    sl: {
      headline: t("info_sl.headline"),
      subheadline: t("info_sl.subheadline"),
    },
    default: {
      headline: t("info.headline"),
      subheadline: isExp45Microbooks ?
        "Você também está no caminho para a mudança positiva! Vamos criar uma seleção de titulos que te ajudará com isso." :
        t("info.subheadline"),
    },
  };
  
  const step: StepType = {
    sl: 13,
    default: 17,
  };

  function getText(funnel: string) {
    return text[funnel] || text.default;
  }

  function getStep(funnel: string) {
    return step[funnel] || step.default;
  }

  const stepNumber = useStepNumberManager(getStep(offerType ?? ""));

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: t("layout.cta_continue"),
      nextPath: "loading",
      progress: stepNumber,
      step: stepNumber,
      votting: false,
    }));
  }, []);

  return (
    <main className="flex flex-col h-full justify-between gap-10">
      <header>
        <h1 className="mx-0 mb-4 mt-5 text-xl font-bold tracking-tight text-gray-900  md:text-4xl">
          {getText(offerType ?? "").headline}
        </h1>
        <p className="font-light">{getText(offerType ?? "").subheadline}</p>
      </header>
      <img
        className="mx-auto w-full max-w-sm px-10"
        alt="Chart showing your progress"
        src={InfoImage}
      />
    </main>
  );
}

export default Info;
