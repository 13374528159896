import { useEffect } from "react";
import { relativePath, useScreenContext } from "../Layout";
import { useTranslation } from "react-i18next";
import { useSessionStorage } from "react-use";
import { useParams, useSearchParams } from "react-router-dom";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

const startImage: any = {
  br: require("../../assets/images/start.exp.br.webp"),
  en: require("../../assets/images/start.en.png"),
  es: require("../../assets/images/start.es.png"),
};

function Welcome() {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const { offerType } = useParams();
  const [screenData, setScreenData] = useScreenContext();
  const [getSessionStorageOffer, setSessionStorageOffer] = useSessionStorage(
    "offer",
    "foo"
  );
  
  const isSlFunnel = offerType === "sl"; // Super Leitura

  const newHeadline = useFeatureIsOn("welcome-screen-headline-feature");

  const [_, setSessionStorageVariant] = useSessionStorage("variant");

  const headline = searchParams.get("headline");
  const image = searchParams.get("image");

  const variant = searchParams.get("variant");

  useEffect(() => {
    if (searchParams.get("offer")) {
      setSessionStorageOffer(searchParams.get("offer") || "");
    } else {
      setSessionStorageOffer("");
    }

    if (variant) {
      setSessionStorageVariant(variant);
    }

    let path = isSlFunnel ? relativePath("/usage", offerType) : relativePath("/gender", offerType)
    const nextPath = screenData.utmSource ? path + '?utm_source=' + screenData.utmSource : path

    setScreenData(() => ({
      ...screenData,
      submitLabel: t("layout.cta_continue", {
        interpolation: { escapeValue: false },
      }),
      nextPath: nextPath,
      progress: 1,
    }));
  }, []);

  return (
    <main className="flex flex-col">
      <header>
        <h1 className="mb-2 mt-5 text-3xl font-bold tracking-tight text-gray-900  md:text-5xl lg:text-6xl">
          Sua melhor versão - Em apenas 12 minutos com o 12min App
        </h1>
        <p className="text-md mb-0 py-4 font-normal text-gray-900 lg:text-xl">
          Somos todos únicos. Nos diga <strong>suas metas e interesses</strong> que vamos <strong>personalizar o 12min</strong> especialmente para você.
        </p>
      </header>
      <div className="container">
        <img
          className="mx-auto"
          width="300"
          alt="Woman with a list of books"
          src={image || startImage[i18n.language]}
        />
      </div>
    </main>
  );
}

export default Welcome;
