import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ThankYouImage from "../../assets/images/thank-you.png";
import { useScreenContext, useStepNumberManager } from "../Layout";
import { getRecommendations } from "../../lib/data";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

function ThankYou() {
  const { t } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();

  const isExp45Microbooks = useFeatureIsOn("exp-45-copy-test-microbooks");

  const stepNumber = useStepNumberManager(15);

  useEffect(() => {
    setScreenData({
      ...screenData,
      submitLabel: false,
      nextPath: "",
      progress: 15,
      step: 15,
    });

    const topics = [
      49, 50, 57, 44, 40, 47, 30, 37, 41, 31, 35, 38, 32, 36, 42, 34, 48, 33,
      39, 43, 29, 46, 45,
    ];

    getRecommendations(topics)
      .then((response) => response.json())
      .then((response) => {
        const normalizedData = response.data.map((book: any) => ({
          id: book.id,
          title: book.title.split(" - ")[0],
          coverImage: book.custom_cover_large_image_url,
          thumImage: book.custom_cover_thumb_image_url,
          author: book.author,
        }));

        normalizedData.forEach((book: any) => {
          const img = new Image();
          img.src = book.coverImage;
        });

        setScreenData({
          ...screenData,
          submitLabel: t("layout.cta_continue"),
          nextPath: "book-suggestion/1",
          progress: stepNumber,
          step: stepNumber,
          bookRecommendations: normalizedData,
        });
      });
  }, []);

  return (
    <main className="flex flex-col h-full justify-between gap-10">
      <header>
        <h1 className="mx-5 mb-4 mt-5 text-2xl font-bold tracking-tight text-gray-900  md:text-4xl">
          {t("thank_you.headline.part_1")}
        </h1>
        <h2 className="mx-5 mb-4 mt-5 text-2xl font-bold tracking-tight text-gray-900  md:text-4xl">
          { isExp45Microbooks ? 
            <>Agora me fala quais titulos a seguir tem a ver com você.</> :
            t("thank_you.headline.part_2")
          }
        </h2>
      </header>
      <img
        className="mx-auto w-full max-w-sm px-10"
        alt="Chart showing your progress"
        src={ThankYouImage}
      />
    </main>
  );
}

export default ThankYou;
