import * as rudderanalytics from "rudder-sdk-js";
rudderanalytics.load("2TOcjmEvJY3OEWRI2tq0W8TjRNU", "https://track.12min.com");

export function identify(email: string) {
  rudderanalytics.identify(email, { email });
}

export function page() {
  rudderanalytics.page();
}

export function lead() {
  rudderanalytics.track("Lead");
}

export function continueButtonClicked() {
  rudderanalytics.track("Continue Button Clicked");
}

export function likeBook(book_id: number) {
  rudderanalytics.track("Like Book", { book_id });
}

export function unlikeBook(book_id: number) {
  rudderanalytics.track("Unlike Book", { book_id });
}

export function optionSelected(props = {}) {
  rudderanalytics.track("Option Selected", props);
}

export function checkoutStarted() {
  rudderanalytics.track("Checkout Started", {
    affiliation: "hotmart",
    currency: "BRL",
    product_id: 0,
    revenue: 0,
    total: 0,
    value: 0,
    products: [
      {
        product_id: 0,
        name: 0,
        price: 0,
        quantity: 1,
      },
    ],
  });
}

export function quizStarted() {
  rudderanalytics.track("Quiz Started");
}

export function quizFinished() {
  rudderanalytics.track("Quiz Finished");
}

export function openLeadForm() {
  rudderanalytics.track("Open Lead Form");
}

export function experimentViewed(experimentId: string, variationId: string) {
  rudderanalytics.track("Experiment Viewed", {
    experimentId: experimentId,
    variationId: variationId,
  });
}

export function getAnonymousId() {
  return rudderanalytics.getAnonymousId();
}
