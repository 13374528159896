import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useScreenContext, PlanData } from "../Layout";
import { Link, useParams } from "react-router-dom";
import { useSessionStorage } from "react-use";
import * as rudderanalytics from "../../lib/rudderstack";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { useTimer } from "react-timer-hook";
import { Button, CustomFlowbiteTheme, Flowbite, Modal } from "flowbite-react";

function TimerBox({text}: {text?: string}) {
  const time = new Date();
  time.setSeconds(time.getSeconds() + 833);

  const { seconds, minutes, hours } = useTimer({ expiryTimestamp: time });

  return (
    <div>
      <div className="inline-block bg-red-600 p-2 font-bold text-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="inline-block h-6 w-6 pr-1"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        {hours.toString().padStart(2, "0")}:
        {minutes.toString().padStart(2, "0")}:
        {seconds.toString().padStart(2, "0")}
      </div>
      {text ? <p>{text}</p> : <p>Oferta por tempo limitado</p>}
    </div>
  );
}

const OpenIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="h-6 w-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
    />
  </svg>
);

const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="h-6 w-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.5 15.75l7.5-7.5 7.5 7.5"
    />
  </svg>
);

const converObjectToParams = (object: any) => {
  return Object.keys(object)
    .filter((key) => object[key])
    .map((key) => {
      return `${key}=${encodeURIComponent(object[key])}`;
    })
    .join("&");
};

const PopupCheckout = ({
    checkoutLink,
    modalState,
    bestPriceState,
    selectedPlan
  }: {
    checkoutLink: any,
    modalState: any,
    bestPriceState: any,
    selectedPlan: string
  }) => {
  const [openModal, setOpenModal] = modalState;
  const [bestPrice, setBestPrice] = bestPriceState;

  const customTheme: CustomFlowbiteTheme = {
    modal: {
      show: {
        "on": "flex bg-gray-900 bg-opacity-50 backdrop-blur dark:bg-opacity-80 h-[100%] md:h-[100%]",
        "off": "hidden"
      },
      header: {
        base: "flex flex-row-reverse items-center border-b-0 rounded-t p-2 dark:border-gray-600",
        popup: "border-b-0 p-2",
        title: "w-full text-md font-normal text-center text-gray-900 dark:text-white",
        close: {
          base: "inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white",
          icon: "h-5 w-5"
        }
      },
      body: {
        base: ""
      },
      footer: {
        base: "flex flex-col border-t-0 p-4 gap-4 bg-gray-100 rounded-b-xl",
      }
    }
  };

  let checkoutLinkSelected = checkoutLink.default;
  useEffect(() => {
    if (selectedPlan === "monthly") {
      checkoutLinkSelected = checkoutLink.monthly;

      let iframe = document.getElementById("iframe_root");
      if ( iframe ) {
        iframe.setAttribute("src", checkoutLinkSelected);

        iframe.onload = function() {
          console.log("iframe loaded");
        }
      }
    }

    if (bestPrice && selectedPlan !== "monthly") {
      checkoutLinkSelected = checkoutLink.bestPrice;
      let iframe = document.getElementById("iframe_root");
      if ( iframe ) {
        iframe.setAttribute("src", checkoutLinkSelected);

        iframe.onload = function() {
          console.log("iframe loaded");
        }
      }
    }

  }, [bestPrice, selectedPlan]);

  useEffect(() => {
    if (!bestPrice) {
      checkoutLinkSelected = checkoutLink.default;
      let iframe = document.getElementById("iframe_root");
      if ( iframe ) {
        iframe.setAttribute("src", checkoutLinkSelected);

        iframe.onload = function() {
          console.log("iframe loaded default");
        }
      }
    }
  }, []);

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [openModal]);

  return (
    <>
      <Flowbite theme={{ theme: customTheme }}>
        <Modal show={openModal} onClose={() => {setBestPrice(true); setOpenModal(false)}}>
          <Modal.Header>Checkout</Modal.Header>
          <Modal.Body>
            <iframe
              src={checkoutLinkSelected}
              id="iframe_root"
              className="w-full h-full rounded-b-xl"
              style={{height: "calc(100vh - 100px)"}}
            ></iframe>
          </Modal.Body>
        </Modal>
      </Flowbite>
    </>
  );
};

function SelectPlan() {
  const { t, i18n } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const [showOthersPlans, setShowOthersPlans] = useState<boolean>(false);
  const [selectedPlan, setselectedPlan] = useState<string>("yearly");
  const [currentFaq, setCurrentFaq] = useState<number>(0);

  const bestPriceState = useState<boolean>(false);
  const [bestPrice, setBestPrice] = bestPriceState;
  const modalState = useState(false);
  const [showModal, setShowModal] = modalState;

  const { offerType } = useParams();
  const [flowStrategy, setFlowStrategy] = useSessionStorage(
    "flow-strategy",
    ""
  );
  const isRet = sessionStorage.getItem("utm_source") === "ret";
  const isFromTwitter = sessionStorage.getItem("utm_source") === "twitter";

  // const newPricing = useFeatureIsOn("yearly-plan-pricing-feature");
  const removeMonthly = useFeatureIsOn("remove-monthly-plan-feature");
  const newHeadline = useFeatureIsOn("select-plan-headline-feature")
  // const checkout12minPremium = useFeatureIsOn("checkout-12min-premium-feature");
  const exp16Checkout = useFeatureIsOn("exp-16-checkout-feature");
  const exp17feature = useFeatureIsOn("exp-17-feature");
  const exp38ptTwoOffers = useFeatureIsOn("exp-38-pt-two-offers");
  const exp43ptTwoOffersYt = useFeatureIsOn("exp-43-two-offers-yt");
  const newPricing = exp38ptTwoOffers || exp43ptTwoOffersYt;

  const isPostLeadFlowStrategy = flowStrategy === "lead-post-plan-selection";
  const [variant, _] = useSessionStorage("variant");

  const isDirectSale = offerType === "direct";
  const isRetFunnel = offerType === "ret";
  const isLtFunnel = offerType === "lt" || offerType === "ltf";
  const isYtFunnel = offerType === "yt";
  const isTwFunnel = offerType === "tw";
  const isAppFunnel = offerType === "app" || offerType === "appf";

  const isWithInterestVariant = variant === "with-interest" && isDirectSale;

  const activePlan = (isDirectSale || isRetFunnel) && selectedPlan === "yearly";

  type Funnel = "direct" | "ret" | "lt" | "ltf" | "yt" | "tw";

  const i18nPrefix = {
    direct: "direct_sale_",
    ret: "ret_sale_",
    lt: "lifetime_sale_",
    ltf: "lifetime_sale_",
    yt: "youtube_sale_",
    tw: "twitter_sale_",
    app: "app_sale_",
  } as Record<Funnel, string>;

  const checkoutIdListBR: Record<string, string> = {
    direct: "E91856682Y",
    ret: "E91856682Y",
    lt: "L49428651O",
    ltf: "L49428651O",
    yt: "E91856682Y",
    tw: "E91856682Y",
    trial: "P5327674P",
  };

  const checkoutIdListMonthlyBR: Record<string, string> = {
    direct: "P5327674P",
    ret: "P5327674P",
    lt: "L49428651O",
    ltf: "L49428651O",
    yt: "P5327674P",
    tw: "P5327674P",
    trial: "P5327674P",
  };

  // activePlan ? "E91856682Y" : 
  //       isLtFunnel ? "L49428651O" : "P5327674P",
  const checkoutId: any = {
    br: selectedPlan === "yearly" && checkoutIdListBR[offerType || ""] || checkoutIdListBR.trial,
    en: "K16542811H",
    es: activePlan ? "Y92363487F" : "Y16613490E",
  }[i18n.language];

  const offerCodeList: Record<string, string> = {
    twitter: "xrnblfqt",
    default: "7d18zm3s",
    exp16: "6reyasj1",
    exp17: "6reyasj1",
  };

  const offerCodeDirect: () => string = () => {
    if (isFromTwitter) return offerCodeList.twitter;
    if (exp16Checkout) return offerCodeList.exp16;
    return offerCodeList.default;
  };

  const discount = isRetFunnel  
    ? "promo75off" 
    : (newPricing 
        ? "promo63off" 
        : "promo63off"
      );

  const offersTrial: any = {
    discounts: {
      base: "63OFF7D",
    },
    P5327674P: {
      yearly: { offerCode: "2zki1nl0" },
      monthly: { offerCode: "jx5nh72y" },
    },
    K16542811H: {
      yearly: { offerCode: "38tbqvq8" },
      monthly: { offerCode: "fys1w0n7" },
    },
    Y92363487F: {
      yearly: { offerCode: "vng1dkxz" },
      monthly: { offerCode: "7lcaoc5b" },
    },
  };

  type Co = "co" | "mx" | "pe" | "ve" | "py" | "ar" | "hn" | "cl";

  const offersDirectSale: any = {
    discounts: {
      base: discount,
    },
    P5327674P: {
      yearly: { offerCode: "suxzbi7s" },
      monthly: { offerCode: "jx5nh72y" },
    },
    E91856682Y: {
      yearly: { offerCode: offerCodeDirect() },
      monthly: { offerCode: "kppi78af" },
    },
    K16542811H: {
      yearly: { offerCode: "evc05pnv" },
      monthly: { offerCode: "b3i96d7z" },
    },
    Y16613490E: {
      yearly: { offerCode: "oqvwtnza" },
      monthly: { offerCode: "eptks9rt" },
    },
    Y92363487F: {
      yearly: { offerCode: "98fq4cvr" },
      monthly: { offerCode: "prlt2kyv" },

      co: {
        yearly: { offerCode: "2earfcgt" },
        monthly: { offerCode: "an79xa2b" },
      },
      mx: {
        yearly: { offerCode: "hdg8agyx" },
        monthly: { offerCode: "eptks9rt" },
      },
      pe: {
        yearly: { offerCode: "f2q21x2l" },
        monthly: { offerCode: "kpzyvt39" },
      },
      ar: {
        yearly: { offerCode: "pk09dmu5" },
        monthly: { offerCode: "v4kfjdja" },
      },
      cl: {
        yearly: { offerCode: "ck7gszd7" },
        monthly: { offerCode: "8jltpjdm" },
      },
    },
  };

  const offersRetSale: any = {
    discounts: {
      base: "promo75off",
    },
    E91856682Y: {
      yearly: { offerCode: "7d18zm3s" },
      monthly: { offerCode: "kppi78af" },
    },
    P5327674P: {
      yearly: { offerCode: "2zki1nl0" },
      monthly: { offerCode: "jx5nh72y" },
    },
  };

  const offersLifetime: any = {
    discounts: {
      base: "",
    },
    L49428651O: {
      yearly: { offerCode: "atfd8gmh" },
    },
  };

  const offersYtSale: any = {
    discounts: {
      base: "promo63off",
    },
    E91856682Y: {
      yearly: { offerCode: "v99ighqd" },
      monthly: { offerCode: "kppi78af" },
    },
    P5327674P: {
      yearly: { offerCode: "2zki1nl0" },
      monthly: { offerCode: "fbixgxyd" },
    },
  };

  const offersTwSale: any = {
    discounts: {
      base: "promo63off",
    },
    E91856682Y: {
      yearly: { offerCode: "i6rm22xe" },
      monthly: { offerCode: "rjkuz3j9" },
    },
    P5327674P: {
      yearly: { offerCode: "2zki1nl0" },
      monthly: { offerCode: "rjkuz3j9" },
    },
  };

  const offersExp17Sale: any = {
    discounts: {
      base: bestPrice ? "promo63off" : "promo53off",
    },
    E91856682Y: {
      yearly: { offerCode: "7d18zm3s" },
      monthly: { offerCode: "" },
    },
    P5327674P: {
      yearly: { offerCode: "suxzbi7s" },
      monthly: { offerCode: "kppi78af" },
    },
  };

  const offersOptions: any = () => {
    if (isDirectSale) return offersDirectSale;
    if (isRetFunnel) return offersRetSale;
    if (isLtFunnel) return offersLifetime;
    if (isYtFunnel) return offersYtSale;
    if (isTwFunnel) return offersTwSale;
    if (newPricing) return offersExp17Sale;
    return offersTrial;
  };

  const offers: any = offersOptions();
  const currentCheckout = offers[checkoutId];

  interface CheckoutPopupLinks {
    default: string;
    bestPrice: string;
    monthly: string;
  }

  const checkoutPopupLink = (id: string, off: string, offDiscount: string) => {
    const baseUrl = `https://pay.hotmart.com/${id}`;
    const urlParams = !isPostLeadFlowStrategy ? screenData.urlParams : {};

    return (
      baseUrl +
      "?" +
      converObjectToParams({
        ...urlParams,
        off: off,
        offDiscount: offDiscount,
        sck: rudderanalytics.getAnonymousId(),
        checkoutMode: "10",
        hidePix: "0",
        hideBillet: "0"
      })
    );
  };

  const checkoutPopupLinks: CheckoutPopupLinks = {
    default: checkoutPopupLink("P5327674P", "suxzbi7s", "promo63off"),//"https://pay.hotmart.com/P5327674P?checkoutMode=2&off=suxzbi7s&offDiscount=promo53off&split=12&hidePix=0&hideBillet=0" + converObjectToParams({...screenData.urlParams}), // 53 OFF
    bestPrice: checkoutPopupLink("E91856682Y", "6reyasj1", "promo75off"), //"https://pay.hotmart.com/E91856682Y?checkoutMode=10&off=7d18zm3s&offDiscount=promo63off&split12&hidePix=0&hideBillet=0" + converObjectToParams({...screenData.urlParams}), // 63 OFF
    monthly: checkoutPopupLink("P5327674P", "kppi78af", "") //"https://pay.hotmart.com/P5327674P?checkoutMode=2&off=kppi78af&hidePix=0&hideBillet=0" + converObjectToParams({...screenData.urlParams}),
  }


  /**
   * Retrieves the price list for a given country.
   * @param country - The country code.
   * @returns The price list object for the specified country.
   */
  const priceListByCountry = (country: string) => {
    const prices: {
      [key: string]: {
        fullPrice: string;
        discountedPrice: string;
        installmentPrice: string; 
        monthlyPrice: string;
      }
    } = {
      co: {
        fullPrice: "310.329 COP",
        discountedPrice: "114.824 COP",
        installmentPrice: "9.568 COP",
        monthlyPrice: "32.424 COP"
      },
      mx: {
        fullPrice: "1485 MXN",
        discountedPrice: "549 MXN",
        installmentPrice: "",
        monthlyPrice: "156 MXN"
      },
      pe: {
        fullPrice: "300 PEN",
        discountedPrice: "111 PEN",
        installmentPrice: "9,33 PEN",
        monthlyPrice: "32 PEN"
      },
      ve: {
        fullPrice: "77 US$",
        discountedPrice: "29 US$",
        installmentPrice: "",
        monthlyPrice: "9 US$"
      },
      py: {
        fullPrice: "589.085 PYG",
        discountedPrice: "217.964 PYG",
        installmentPrice: "",
        monthlyPrice: "61.374 PYG"
      },
      ar: {
        fullPrice: "71.869 ARS",
        discountedPrice: "26.592 ARS",
        installmentPrice: "",
        monthlyPrice: "7.500 ARS"
      },
      hn: {
        fullPrice: "1967 HNL",
        discountedPrice: "729 HNL",
        installmentPrice: "",
        monthlyPrice: "205 HNL"
      },
      cl: {
        fullPrice: "90.466 CLP",
        discountedPrice: "33.472 CLP",
        installmentPrice: "2.789 CLP",
        monthlyPrice: "9.450 CLP"
      }
    };

    return prices[country] || undefined;
  };

  const buildCheckoutLink = () => {
    const baseUrl = `https://pay.hotmart.com/${checkoutId}`;
    const urlParams = !isPostLeadFlowStrategy ? screenData.urlParams : {};

    return (
      baseUrl +
      "?" +
      converObjectToParams({
        ...urlParams,
        off: screenData.co as Co ? currentCheckout[screenData.co][selectedPlan].offerCode : currentCheckout[selectedPlan].offerCode,
        offDiscount: selectedPlan === "yearly" ? offers.discounts.base : false,
        sck: rudderanalytics.getAnonymousId(),
        checkoutMode: "10",
        hidePix: "0",
        hideBillet: "0"
      })
    );
  };

  const buildNextPath = () => {
    const checkoutLink = buildCheckoutLink();
    return !isPostLeadFlowStrategy
      ? checkoutLink
      : "form?redirectUrl=" + encodeURIComponent(checkoutLink);
  };

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: !newPricing && t("layout.cta_continue"),
      disabledButton: false,
      nextPath: buildNextPath(),
      progress: 24,
      step: 24,
      bestPrice: bestPrice
    }));
  }, [selectedPlan, bestPrice]);

  let activePlanData = PlanData(bestPrice);

  return (
    <>
      { newPricing 
        && <PopupCheckout
          checkoutLink={checkoutPopupLinks}
          modalState={modalState}
          bestPriceState={bestPriceState}
          selectedPlan={selectedPlan}
        />
      }
      <div>

        <h1 className="mb-4 mt-5 text-3xl font-bold tracking-tight text-gray-900  md:text-5xl lg:text-6xl">
          {newHeadline
            ? ("Maximize o ritmo da sua evolução")
            : t("select_plan.headline-transformation")}
        </h1>

        <p>{newHeadline ? <b>12 minutos = 2 horas de leitura!</b> : t("select_plan.subheadline-transformation")}</p>

        <p className="pt-5">
          {isLtFunnel ? <TimerBox text={"Oferta por tempo limitado: 12min Vitalício com um desconto exclusivo de 93%"} /> : <TimerBox />}
        </p>
        <div>
          <div
            onClick={() => setselectedPlan("yearly")}
            className={
              "mt-10 cursor-pointer border-4 text-gray-900 " +
              (selectedPlan === "yearly"
                ? "border-green-400"
                : "border-gray-400")
            }
          >
            <div className="flex bg-gray-100 p-4 py-6 text-left">
              <div className="pr-4">
                <div className="mt-1.5 rounded-full border-2 border-gray-900 bg-white p-0.5">
                  <div
                    className={
                      "h-2 w-2 rounded-full  bg-gray-900 " +
                      (selectedPlan === "yearly" ? "bg-gray-900" : "bg-white")
                    }
                  ></div>
                </div>
              </div>
              <div>
                <div className="-ml-11 -mt-10 mb-2 w-28  bg-red-600 py-1 text-center text-xs text-white">
                  {bestPrice ? "-75% OFF" : "-63% OFF"}
                </div>
                <strong className="mt-5 block text-sm">
                  {t(
                    `select_plan.yearly.${
                      i18nPrefix[offerType as Funnel]
                    }plan_name${
                      newPricing ? "_variant" : ""
                    }`
                  )}
                </strong>
                <div className="py-2">
                  <strong className="block text-xs font-normal text-red-600 line-through">
                  {newPricing ? (
                    activePlanData?.yearly.plan_price_per_month_without_discount
                  ) : (
                    (screenData.co && priceListByCountry(screenData.co)) ? (
                      priceListByCountry(screenData.co).fullPrice
                    ) : (
                      t(
                        `select_plan.yearly.${
                          i18nPrefix[offerType as Funnel]
                        }plan_price_per_month_without_discount${
                          newPricing ? "_variant" : ""
                        }`
                      )
                    )
                  )}
                  </strong>

                  {bestPrice && (
                    <strong className="block text-sm font-bold text-red-600 line-through">
                      R$ 13,29/mês
                    </strong>
                  )}

                  <b className="block">
                  {bestPrice ? "R$ 8,98/mês" : "R$ 13,29/mês"}
                  </b>
                </div>
                <b className="block text-sm text-blue-700">
                  {t(
                    `select_plan.yearly.${
                      i18nPrefix[offerType as Funnel]
                    }free_trial`
                  )}
                </b>
              </div>
            </div>
            <div className="ml-8 p-4 text-left">
              <b className="text-sm">
                {t(
                  `select_plan.yearly.${
                    i18nPrefix[offerType as Funnel]
                  }how_trial_works`
                )}
                :
              </b>
              <div className="mt-2 text-left text-sm">
                <div className="mb-3">
                  <b className="text-blue-700">
                    {t("book_list.trial_box.today.headline")}
                  </b>
                  <p className="font-light">
                    {t(
                      `book_list.trial_box.today.${
                        (isDirectSale || isRetFunnel || isLtFunnel || isTwFunnel || isYtFunnel) ? "direct_sale_" : ""
                      }text`
                    )}
                  </p>
                </div>
                <div className="mb-3">
                  <b className="text-blue-700">
                    {t("book_list.trial_box.day5.headline")}
                  </b>
                  <p className="font-light">
                    {t(
                      `book_list.trial_box.day5.${
                        (isDirectSale || isRetFunnel || isLtFunnel || isTwFunnel || isYtFunnel) ? "direct_sale_" : ""
                      }text`
                    )}
                  </p>
                </div>
                <div className="mb-3">
                  <b className="text-blue-700">
                    {t("book_list.trial_box.day7.headline")}
                  </b>
                  <p className="font-light">
                    {t("book_list.trial_box.day7.text")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {(!removeMonthly && !isLtFunnel) && (
            <div
              className="py-3 text-sm text-blue-600"
              onClick={() => setShowOthersPlans(!showOthersPlans)}
            >
              <span className="cursor-pointer">
                {t("select_plan.show_others_plans")}
              </span>
            </div>
          )}

          {showOthersPlans && (
            <div
              onClick={() => setselectedPlan("monthly")}
              className={
                "cursor-pointer border-4  text-gray-900 " +
                (selectedPlan === "monthly"
                  ? "border-green-400"
                  : "border-gray-400")
              }
            >
              <div className="flex bg-gray-100 p-4 py-6 text-left">
                <div className="pr-4">
                  <div className="mt-0.5 rounded-full border-2 border-gray-900 bg-white p-0.5">
                    <div
                      className={
                        "h-2 w-2 rounded-full  bg-gray-900 " +
                        (selectedPlan === "monthly"
                          ? "bg-gray-900"
                          : "bg-white")
                      }
                    ></div>
                  </div>
                </div>
                <div>
                  <b className="block text-sm">
                    {t("select_plan.monthly.plan_name")}
                  </b>
                  <div className="py-2">
                    <b className="block">
                    {newPricing ? (
                      activePlanData?.monthly.plan_price_per_month
                    ) : (
                      (screenData.co && priceListByCountry(screenData.co)) ? (
                        priceListByCountry(screenData.co).monthlyPrice
                      ) : (
                        t(
                          `select_plan.monthly.${
                            i18nPrefix[offerType as Funnel]
                          }plan_price_per_month${
                            isWithInterestVariant ? "_with_interest" : ""
                          }`
                        )
                      )
                    )}
                    </b>
                  </div>
                  <b className="block text-sm text-blue-700">
                    {t(
                      `select_plan.monthly.${
                        (isDirectSale || isRetFunnel) ? "direct_sale_" : ""
                      }no_trial`
                    )}
                  </b>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {variant === "select-plan-green-box" && (
        <div>
          <div className="my-5 rounded bg-green-200 px-5 pt-1 pb-4">
            <h2 className="mb-4 mt-5 text-xl font-bold tracking-tight text-gray-900  md:text-2xl lg:text-3xl">
              {t("select_plan.green_box.headline")}
            </h2>
            <div className="text-left">
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="mb-1 mr-1 inline-block h-5 w-5 text-green-700"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                  />
                </svg>
                {t("select_plan.green_box.item1")}
              </p>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="mb-1 mr-1 inline-block h-5 w-5 text-green-700"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                  />
                </svg>
                {t("select_plan.green_box.item2")}
              </p>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="mb-1 mr-1 inline-block h-5 w-5 text-green-700"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                  />
                </svg>
                {t("select_plan.green_box.item3")}
              </p>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="mb-1 mr-1 inline-block h-5 w-5 text-green-700"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                  />
                </svg>
                {t("select_plan.green_box.item4")}
              </p>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="mb-1 mr-1 inline-block h-5 w-5 text-green-700"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                  />
                </svg>
                {t("select_plan.green_box.item5")}
              </p>
            </div>
          </div>
        </div>
      )}

      {/* FAQ */}
      <div>
        <h2 className="mb-4 mt-5 text-2xl font-bold tracking-tight text-gray-900  md:text-3xl lg:text-4xl">
          {t("select_plan.faq.headline")}
        </h2>

        <div className="text-left">
          <div className="border-b border-gray-500">
            <div
              className="flex cursor-pointer"
              onClick={() => setCurrentFaq(1)}
            >
              <div className="mt-3 pr-3">
                {currentFaq === 1 ? <CloseIcon /> : <OpenIcon />}
              </div>
              <div>
                <h3 className="py-3 font-bold">
                  {t("select_plan.faq.q1.question")}
                </h3>
              </div>
            </div>
            {currentFaq === 1 && (
              <div className="pb-3">
                <p className="text-sm font-light text-gray-700 ">
                  {t("select_plan.faq.q1.answer")}
                </p>
              </div>
            )}
          </div>

          <div className="border-b border-gray-500">
            <div
              className="flex cursor-pointer"
              onClick={() => setCurrentFaq(2)}
            >
              <div className="mt-3 pr-3">
                {currentFaq === 2 ? <CloseIcon /> : <OpenIcon />}
              </div>
              <div>
                <h3 className="py-3 font-bold">
                  {t(
                    `select_plan.faq.${
                      (isDirectSale || isRetFunnel || isLtFunnel || isTwFunnel || isYtFunnel) ? "direct_sale_" : ""
                    }q2.question`
                  )}
                </h3>
              </div>
            </div>
            {currentFaq === 2 && (
              <div className="pb-3">
                <p className="text-sm font-light text-gray-700 ">
                  {t(
                    `select_plan.faq.${
                      (isDirectSale || isRetFunnel || isLtFunnel || isTwFunnel || isYtFunnel) ? "direct_sale_" : ""
                    }q2.answer`
                  )}
                </p>
              </div>
            )}
          </div>
          
          {!isLtFunnel && (
          <div className="border-b border-gray-500">
            <div
              className="flex cursor-pointer"
              onClick={() => setCurrentFaq(3)}
            >
              <div className="mt-3 pr-3">
                {currentFaq === 3 ? <CloseIcon /> : <OpenIcon />}
              </div>
              <div>
                <h3 className="py-3 font-bold">
                  {t("select_plan.faq.q3.question")}
                </h3>
              </div>
            </div>
            {currentFaq === 3 && (
              <div className="pb-3">
                <p className="text-sm font-light text-gray-700 ">
                  {t("select_plan.faq.q3.answer")}
                </p>
              </div>
            )}
          </div>
          )}

          <div className="border-b border-gray-500">
            <div
              className="flex cursor-pointer"
              onClick={() => setCurrentFaq(4)}
            >
              <div className="mt-3 pr-3">
                {currentFaq === 4 ? <CloseIcon /> : <OpenIcon />}
              </div>
              <div>
                <h3 className="py-3 font-bold">
                  {t(
                    `select_plan.faq.${
                      (isLtFunnel) ? "lifetime_" : ""
                    }q4.question`
                  )}
                </h3>
              </div>
            </div>
            {currentFaq === 4 && (
              <div className="pb-3">
                <p className="text-sm font-light text-gray-700 ">
                  {t("select_plan.faq.q4.answer")}
                </p>
              </div>
            )}
          </div>

          <div className="border-b border-gray-500">
            <div
              className="flex cursor-pointer"
              onClick={() => setCurrentFaq(5)}
            >
              <div className="mt-3 pr-3">
                {currentFaq === 5 ? <CloseIcon /> : <OpenIcon />}
              </div>
              <div>
                <h3 className="py-3 font-bold">
                  {t(
                    `select_plan.faq.${
                      (isDirectSale || isRetFunnel || isTwFunnel || isYtFunnel) 
                        ? "direct_sale_" 
                        : isLtFunnel ? "lifetime_" : ""
                    }q5.question`
                  )}
                </h3>
              </div>
            </div>
            {currentFaq === 5 && (
              <div className="pb-3">
                <p className="text-sm font-light text-gray-700 ">
                  {t(
                    `select_plan.faq.${
                      (isDirectSale || isRetFunnel || isTwFunnel || isYtFunnel) 
                        ? "direct_sale_" 
                        : isLtFunnel ? "lifetime_" : ""
                    }q5.answer`
                  )}
                </p>
              </div>
            )}
          </div>

          <div className="border-gray-500 pb-20">
            <div
              className="flex cursor-pointer"
              onClick={() => setCurrentFaq(6)}
            >
              <div className="mt-3 pr-3">
                {currentFaq === 6 ? <CloseIcon /> : <OpenIcon />}
              </div>
              <div>
                <h3 className="py-3 font-bold">
                  {t("select_plan.faq.q6.question")}
                </h3>
              </div>
            </div>
            {currentFaq === 6 && (
              <div className="pb-3">
                <p className="text-sm font-light text-gray-700 ">
                  {t("select_plan.faq.q6.answer")}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      {newPricing && (
        <div className="fixed inset-x-0 bottom-0 mx-auto max-w-screen-sm bg-gradient-to-t from-white via-white">
          <div className="mx-4">
            <button
              onClick={() => setShowModal(true)}
              className="hotmart-fb flex justify-center text-center text-md my-6 w-full rounded-md bg-blue-700 px-5 py-2.5 font-normal text-white hover:bg-blue-800 focus:ring-blue-300"
            >Continuar</button>
          </div>
        </div>
      )}
    </>
  );
}

export default SelectPlan;