import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useScreenContext, relativePath, OptionRadio } from "../../Layout";
import { useNavigate, useParams } from "react-router-dom";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

function BiggestDifficulty() {
  const { t } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const navigate = useNavigate();
  const { offerType } = useParams();

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: undefined,
      nextPath: relativePath("/target", offerType),
      progress: 7,
      step: 7,
    }));
  }, []);

  const handleOptionSelection = (option: string) => {
    setScreenData(() => ({
      ...screenData,
      userPreferences: {
        ...screenData.userPreferences,
        biggestDifficulty: option,
      },
    }));

    navigate(screenData.nextPath);
  };

  return (
    <div>
      <h1 className="mb-4 text-2xl font-bold tracking-tight text-gray-900  md:text-5xl">
        {t("biggest_difficulty.headline")}
      </h1>
      <div className="mt-10">
        <OptionRadio onClick={() => handleOptionSelection("very_slow")} name={"biggest_difficulty"} value={"very_slow"}>
          {t("biggest_difficulty.option_1")}
        </OptionRadio>
        <OptionRadio onClick={() => handleOptionSelection("lost_focus")} name={"biggest_difficulty"} value={"lost_focus"}>
          {t("biggest_difficulty.option_2")}
        </OptionRadio>
        <OptionRadio onClick={() => handleOptionSelection("sleepy")} name={"biggest_difficulty"} value={"sleepy"}>
          {t("biggest_difficulty.option_3")}
        </OptionRadio>
        <OptionRadio onClick={() => handleOptionSelection("not_remember")} name={"biggest_difficulty"} value={"not_remember"}>
          {t("biggest_difficulty.option_4")}
        </OptionRadio>
      </div>
    </div>
  );
}

export default BiggestDifficulty;
