import { useEffect } from "react";
import { useScreenContext, relativePath } from "../../Layout";
import type { Book } from "../../../types";
import { Link, useParams } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { useSessionStorage } from "react-use";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { getRecommendations } from "../../../lib/data";
import data from '../../../data.json';

const Star = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className="inline-block h-4 w-4"
  >
    <path
      fillRule="evenodd"
      d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
      clipRule="evenodd"
    />
  </svg>
);

type BookParams = {
  bookId: string;
  offerType: string;
};

function BookList() {
  const { t } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const { bookId, offerType } = useParams<BookParams>() as BookParams;
  const decreaseOfferDiscountFeature = useFeatureIsOn(
    "decrease-offer-discount-feature"
  );

  const exp17feature = useFeatureIsOn("exp-17-feature");

  const isDirectSale = offerType === "direct";
  const isRetSale = offerType === "ret";
  const isAppFunnel = offerType === "app";
  const isLtFunnel = offerType === "lt"; // Lifetime funnel
  const isLtfFunnel = offerType === "ltf"; // Lifetime funnel with Form
  const isYtFunnel = offerType === "yt";
  const isTwFunnel = offerType === "tw";
  const isAppfFunnel = offerType === "appf";

  const funnelsActive = isDirectSale || isRetSale || isAppFunnel || isLtFunnel || isLtfFunnel || isYtFunnel || isTwFunnel;

  type Funnel = "direct" | "ret" | "lt" | "ltf" | "yt" | "tw";

  const i18nPrefix = {
    direct: "direct_sale_",
    ret: "ret_",
    lt: "lifetime_",
    ltf: "lifetime_",
    yt: "youtube_",
    tw: "twitter_",
    app: "app_",
    appf: "app_",
  } as Record<Funnel, string>;

  const nextPathBuild = (offerType?: string) => {
    if (offerType && (isLtFunnel || isAppfFunnel)) {
      return relativePath("/select-challenge", offerType);
    }

    return offerType ? relativePath("/form", offerType) : "/form"
  };

  const nextPath = nextPathBuild(offerType);

  const hideSection = useFeatureIsOn("hide-book-list-section-feature");
  // const newPricing = useFeatureIsOn("yearly-plan-pricing-feature");
  const newPricing = exp17feature;
  const appInstallExp = useFeatureIsOn("app-install-feature");

  useEffect(() => {
    setScreenData({
      ...screenData,
      submitLabel: false,
      nextPath: "",
      disabledButton: true,
      progress: 16,
      step: 16,
    });
  }, []);

  return (
    <div className="mb-20">
      {!hideSection && (
        <>
          <h1 className="mb-4 mt-5 text-2xl font-semibold leading-tight tracking-normal text-gray-900  md:text-4xl">
            Para quem é esse curso?
          </h1>
          <div className="mx-auto max-w-sm">
            <ul className="list-disc text-left">
              <li className="mb-2">Para quem tem dificuldade de se concentrar</li>
              <li className="mb-2">Para quem sente que muitas vezes lê mas não entende o conteúdo</li>
              <li className="mb-2">Para aqueles que tem pouco tempo livre para ler</li>
              <li className="mb-2">Para quem quer formar o hábito da leitura</li>
              <li className="mb-2">Para aqueles que se sentem cansados sempre que lêem um livro</li>
            </ul>
          </div>
          <div className="py-5">
            {/* <Link className="text-blue-600 underline" to={nextPath}>
              {t("book_list.enter")}
            </Link> */}
          </div>
        </>
      )}
      <h1 className="mb-4 mt-5 text-3xl font-semibold leading-tight tracking-normal text-gray-900  md:text-4xl">
        {t("about_sl.reviews.headline")}
      </h1>
      <div className="mb-5 rounded-md bg-amber-100 p-5 text-left">
        <p>{t("about_sl.reviews.review_1.name")}</p>
        <div className="my-1">
          {[1, 2, 3, 4, 5].map((i) => (
            <Star key={i} />
          ))}
        </div>
        <p>{t("about_sl.reviews.review_1.text")} </p>
      </div>
      <div className="mb-5 rounded-md bg-amber-100 p-5 text-left">
        <p>{t("about_sl.reviews.review_2.name")}</p>
        <div className="my-1">
          {[1, 2, 3, 4, 5].map((i) => (
            <Star key={i} />
          ))}
        </div>
        <p>{t("about_sl.reviews.review_2.text")} </p>
      </div>
      <div className="mb-5 rounded-md bg-amber-100 p-5 text-left">
        <p>{t("about_sl.reviews.review_3.name")}</p>
        <div className="my-1">
          {[1, 2, 3, 4, 5].map((i) => (
            <Star key={i} />
          ))}
        </div>
        <p>{t("about_sl.reviews.review_3.text")}</p>
      </div>

      <Link to={nextPath}>
        <button
          type="button"
          className="text-md my-6 w-full rounded-md bg-blue-700 px-5 py-2.5 font-normal text-white hover:bg-blue-800 focus:ring-blue-300"
        >
          {t(
            (funnelsActive) ? "book_list.read_now" : "book_list.start_free_trial"
          )}
        </button>
      </Link>

      <h1 className="my-10 text-3xl font-semibold leading-tight tracking-normal text-gray-900  md:text-4xl">
        {t("about_sl.statistics.headline")}
      </h1>
      <div className="mb-5 rounded-md bg-blue-100 p-8">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="inline-block h-10 w-10"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0"
          />
        </svg>
        <h2 className="my-4 text-3xl font-bold leading-tight tracking-normal text-gray-900  md:text-4xl">
          {t("about_sl.statistics.block_1.headline")}
        </h2>
        <p>{t("about_sl.statistics.block_1.text")}</p>
      </div>
      <div className="mb-5 rounded-md bg-blue-100 p-8">
        <div className="my-1">
          {[1, 2, 3, 4, 5].map((i) => (
            <Star key={i} />
          ))}
        </div>
        <h2 className="my-4 text-3xl font-bold leading-tight tracking-normal text-gray-900  md:text-4xl">
          {t("about_sl.statistics.block_2.headline")}
        </h2>
        <p>{t("about_sl.statistics.block_2.text")}</p>
      </div>
      <div className="mb-5 rounded-md bg-blue-100 p-8">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="inline-block h-10 w-10"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
          />
        </svg>

        <h2 className="my-4 text-3xl font-bold leading-tight tracking-normal text-gray-900  md:text-4xl">
          {t("about_sl.statistics.block_3.headline")}
        </h2>
        <p>{t("about_sl.statistics.block_3.text")}</p>
      </div>
      <p className="text-sm text-gray-400">{t("about_sl.statistics.info")}</p>

      <Link to={nextPath}>
        <button
          type="button"
          className="text-md my-6 w-full rounded-md bg-blue-700 px-5 py-2.5 font-normal text-white hover:bg-blue-800 focus:ring-blue-300"
        >
          {t(
            (funnelsActive) ? "book_list.read_now" : "book_list.start_free_trial"
          )}
        </button>
      </Link>

      <div className="my-10 text-left">
        <p className="mb-2 text-xl font-bold leading-tight tracking-normal text-gray-500">
          {t("about_sl.feed_your_brain.headline")}
        </p>
        <p className="mb-2 text-xl font-bold leading-tight tracking-normal text-gray-900">
          {t("about_sl.feed_your_brain.drive")}
        </p>
        <p className="mb-2 text-xl font-bold leading-tight tracking-normal text-gray-900">
          {t("about_sl.feed_your_brain.work")}
        </p>
        <p className="mb-2 text-xl font-bold leading-tight tracking-normal text-gray-900">
          {t("about_sl.feed_your_brain.house")}
        </p>
        <p className="mb-2 text-xl font-bold leading-tight tracking-normal text-gray-900">
          {t("about_sl.feed_your_brain.workout")}
        </p>
        <p className="mb-2 text-xl font-bold leading-tight tracking-normal text-gray-900">
          {t("about_sl.feed_your_brain.chill")}
        </p>

        <Link to={nextPath}>
          <button
            type="button"
            className="text-md my-6 w-full rounded-md bg-blue-700 px-5 py-2.5 font-normal text-white hover:bg-blue-800 focus:ring-blue-300"
          >
            {t(
              (funnelsActive) ? "book_list.read_now" : "book_list.start_free_trial"
            )}
          </button>
        </Link>
      </div>

      <h1 className="my-10 text-3xl font-semibold leading-tight tracking-normal text-gray-900  md:text-4xl">
        {t("about_sl.features.headline")}
      </h1>
      <div className="flex flex-row py-2">
        <div className="basis-1/5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="m-auto mt-3 h-10 w-10"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"
            />
          </svg>
        </div>
        <div className="book-info basis-4/5 text-left ">
          <p className="font-bold">
            {t("about_sl.features.block_1.headline")}
          </p>
          <p className="">{t("about_sl.features.block_1.text")}</p>
        </div>
      </div>
      <div className="flex flex-row py-2">
        <div className="basis-1/5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="m-auto mt-3 h-10 w-10"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"
            />
          </svg>
        </div>
        <div className="book-info basis-4/5 text-left ">
          <p className="font-bold">
            {t("about_sl.features.block_2.headline")}
          </p>
          <p className="">{t("about_sl.features.block_2.text")}</p>
        </div>
      </div>
      <div className="flex flex-row py-2">
        <div className="basis-1/5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="m-auto mt-3 h-10 w-10"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
            />
          </svg>
        </div>
        <div className="book-info basis-4/5 text-left ">
          <p className="font-bold">
            {t("about_sl.features.block_3.headline")}
          </p>
          <p className="">{t("about_sl.features.block_3.text")}</p>
        </div>
      </div>

      <Link to={nextPath}>
        <button
          type="button"
          className="text-md my-10 w-full rounded-md bg-blue-700 px-5 py-2.5 font-normal text-white hover:bg-blue-800 focus:ring-blue-300"
        >
          {t(
            (funnelsActive) ? "book_list.read_now" : "book_list.start_free_trial"
          )}
        </button>
      </Link>

      <h1 className="mt-10 text-2xl font-semibold leading-tight tracking-normal text-gray-900  md:text-4xl">
        {t("about_sl.trial_box.headline")}
      </h1>
      <div className="my-10 rounded-md bg-blue-100 p-4">
        <p className="text-left">
          {t("about_sl.trial_box.box_text")}
        </p>
        <Link to={nextPath}>
          <button
            type="button"
            className="text-md my-4 w-full rounded-md bg-blue-700 px-5 py-2.5 font-normal text-white hover:bg-blue-800 focus:ring-blue-300"
          >
            {t("about_sl.trial_box.button_text")}
          </button>
        </Link>
      </div>
    </div>
  );
}

export default BookList;
