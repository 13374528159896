import { useEffect, useState } from "react";
import { relativePath, useScreenContext, useStepNumberManager } from "../Layout";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./Loading.css";
import { useTranslation } from "react-i18next";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { useParams } from "react-router-dom";

function Loading() {
  const { t } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const [progress, setProgress] = useState(0);
  const [activeTestimony, setActiveTestimony] = useState(0);
  const { offerType } = useParams();

  const isSlFunnel = offerType === "sl";

  const exp18feature = useFeatureIsOn("exp-18-feature");
  const isExp45Microbooks = useFeatureIsOn("exp-45-copy-test-microbooks");

  type TextType = {
    [key: string]: {
      headline: string;
      item_1: string;
      item_2: string;
      item_3: string;
      item_4: string;
      item_5: string;
    };
  };
  
  type StepType = {
    [key: string]: number;
  };

  const text: TextType = {
    sl: {
      headline: t("loading_sl.headline"),
      item_1: t("loading_sl.item_1"),
      item_2: t("loading_sl.item_2"),
      item_3: t("loading_sl.item_3"),
      item_4: t("loading_sl.item_4"),
      item_5: t("loading_sl.item_5"),
    },
    default: {
      headline: t("loading.headline"),
      item_1: t("loading.item_1"),
      item_2: t("loading.item_2"),
      item_3: isExp45Microbooks ? "✅ Combinando titulos com seus interesses" : t("loading.item_3"),
      item_4: isExp45Microbooks ? "✅ Incluindo os titulos selecionados" : t("loading.item_4"),
      item_5: t("loading.item_5"),
    },
  };
  
  const step: StepType = {
    sl: 14,
    default: 18,
  };

  function getText(funnel: string) {
    return text[funnel] || text.default;
  }

  function getStep(funnel: string) {
    return step[funnel] || step.default;
  }

  setTimeout(() => {
    if (progress === 100) {
      setScreenData(() => ({
        ...screenData,
        submitLabel: t("layout.cta_continue"),
      }));
      return;
    }
    setProgress(progress + 2);
  }, 120);

  const stepNumber = useStepNumberManager(getStep(offerType ?? ""))

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: false,
      nextPath: isSlFunnel ? relativePath("/loading-result-sl", offerType) : "loading-result",
      progress: stepNumber,
      step: stepNumber,
    }));
  }, []);


  const iconStarOrange = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="rgb(251 146 60)"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="inline-block h-5 w-5 text-orange-400"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
      />
    </svg>
  );

  const iconFiveStarsOrange = (
    <>
      {iconStarOrange}
      {iconStarOrange}
      {iconStarOrange}
      {iconStarOrange}
      {iconStarOrange}
    </>
  );

  const nextTestimony = () => {
    setActiveTestimony((prevIndex) => (prevIndex + 1) % 3); // Loop from 0 to 2
  };

  useEffect(() => {
    const interval = setInterval(nextTestimony, 2666);

    return () => clearInterval(interval);
  }, [activeTestimony]);

  return (
    <div>
      <div className="mx-auto max-w-sm px-20">
        <CircularProgressbar
          value={progress}
          text={`${progress}%`}
          styles={buildStyles({
            pathColor: "#1A56DB",
            textColor: "#666",
            trailColor: "#d6d6d6",
            backgroundColor: "#3e98c7",
          })}
        />
      </div>

      <h1 className="text-gray-900md:text-4xl my-5 text-2xl font-bold tracking-tight">
        {getText(offerType ?? "").headline}
      </h1>

      <div className="mx-auto max-w-sm text-left font-light">
        <p className="fadein-1 pb-1">{getText(offerType ?? "").item_1}</p>
        <p className="fadein-2 pb-1">{getText(offerType ?? "").item_2}</p>
        <p className="fadein-3 pb-1">{getText(offerType ?? "").item_3}</p>
        <p className="fadein-4 pb-1">{getText(offerType ?? "").item_4}</p>
        <p className="fadein-5 pb-1">{getText(offerType ?? "").item_5}</p>
      </div>

      {exp18feature && (
        <div className="mt-2 mb-12 h-56 sm:h-64 xl:h-80 2xl:h-96">
          <div id="default-carousel" className="relative w-full" data-carousel="slide">
            <div className="relative h-auto overflow-hidden rounded-lg md:h-96">
              <div className={`testimony duration-700 ease-in-out flex flex-col gap-1 p-2 items-start border border-gray-200 rounded-xl text-gray-900 bg-white ${activeTestimony === 0 ? 'active' : 'hidden'}`}>
                <div className="testimony-rating">
                  {iconFiveStarsOrange}
                </div>
                <div className="testimony-text text-left font-semibold text-gray-700">
                  <p>Um mundo de informações em doses possíveis diárias. Este aplicativo me fornece um entendimento prévio antes de entrar nos livros, complementa meu aprendizado e fixação.</p>
                </div>
                <div className="testimony-customer text-sm text-gray-400">
                  Rafão Santos
                </div>
              </div>
              
              <div className={`testimony duration-700 ease-in-out flex flex-col gap-1 p-2 items-start border border-gray-200 rounded-xl text-gray-900 bg-white ${activeTestimony === 1 ? 'active' : 'hidden'}`}>
                <div className="testimony-rating">
                  {iconFiveStarsOrange}
                </div>
                <div className="testimony-text text-left font-semibold text-gray-700">
                  <p>Excelente forma de começar o dia, se atualizar, ter conhecimento e trazer assuntos relevantes nas reuniões, com eficiência. Muito boas as recomendações, as uso diariamente no App! Recomendo!</p>
                </div>
                <div className="testimony-customer text-sm text-gray-400">
                  Pedro Cintra
                </div>
              </div>

              <div className={`testimony duration-700 ease-in-out flex flex-col gap-1 p-2 items-start border border-gray-200 rounded-xl text-gray-900 bg-white ${activeTestimony === 2 ? 'active' : 'hidden'}`}>
                <div className="testimony-rating">
                  {iconFiveStarsOrange}
                </div>
                <div className="testimony-text text-left font-semibold text-gray-700">
                  <p>Muito Bom. Praticidade e otimização do meu tempo e ainda assim me manter informado com uma infinidade de livros. 👏👏👏👏👏</p>
                </div>
                <div className="testimony-customer text-sm text-gray-400">
                  Bruno Lembo
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
}

export default Loading;
