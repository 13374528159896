import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useScreenContext, Option, relativePath, useStepNumberManager } from "../../Layout";
import { useNavigate, useParams } from "react-router-dom";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

function GoalRequirementsQuestion() {
  const { t } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const navigate = useNavigate();
  const { offerType } = useParams();

  const isDirectSale = offerType === "direct";
  const isSlFunnel = offerType === "sl";

  const stepNumber = useStepNumberManager(isSlFunnel ? 9 : 11)

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: undefined,
      nextPath: relativePath("/goal-requirements-info", offerType),
      progress: stepNumber,
      step: stepNumber,
    }));
  }, []);

  const handleOptionSelection = (option: string) => {
    setScreenData(() => ({
      ...screenData,
      userPreferences: {
        ...screenData.userPreferences,
        goalRequirements: option,
      },
    }));

    navigate(screenData.nextPath);
  };

  return (
    <div>
      <h1 className="mb-4 text-2xl font-bold tracking-tight text-gray-900  md:text-5xl">
        {t("goal_requirements_question.headline")}
      </h1>
      <div className="mt-10">
        <Option onClick={() => handleOptionSelection("focus")}>
          {t("goal_requirements_question.option_1")}
        </Option>
        <Option onClick={() => handleOptionSelection("motivation")}>
          {t("goal_requirements_question.option_2")}
        </Option>
        <Option onClick={() => handleOptionSelection("effort")}>
          {t("goal_requirements_question.option_3")}
        </Option>
        <Option onClick={() => handleOptionSelection("other")}>
          {t("goal_requirements_question.option_4")}
        </Option>
      </div>
    </div>
  );
}

export default GoalRequirementsQuestion;
