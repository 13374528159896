import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useScreenContext } from "../Layout";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useSessionStorage } from "react-use";
import * as rudderanalytics from "../../lib/rudderstack";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { useTimer } from "react-timer-hook";
import { Button, Modal, Flowbite } from "flowbite-react";
import type { CustomFlowbiteTheme } from "flowbite-react";

import image7DaysMoneyBack from "../../assets/images/7dayslabel.png";

function TimeCounter() {
  const time = new Date();
  time.setSeconds(time.getSeconds() + 833);

  const { seconds, minutes, hours } = useTimer({ expiryTimestamp: time });

  return (
    <>
      {hours.toString().padStart(2, "0")}:
      {minutes.toString().padStart(2, "0")}:
      {seconds.toString().padStart(2, "0")}
    </>
  );
}

const OpenIcon = () => (
  <svg enableBackground="new 0 0 42 50" height="28px" id="Layer_1" version="1.1" viewBox="0 0 50 50" width="28px"><rect fill="none" height="50" width="50"/><line fill="none" stroke="#555555" strokeMiterlimit="10" strokeWidth="4" x1="9" x2="41" y1="25" y2="25"/><line fill="none" stroke="#555555" strokeMiterlimit="10" strokeWidth="4" x1="25" x2="25" y1="9" y2="41"/></svg>
);

const CloseIcon = () => (
  <svg enableBackground="new 0 0 42 50" height="28px" id="Layer_1" version="1.1" viewBox="0 0 50 50" width="28px"><rect fill="none" height="50" width="50"/><line fill="none" stroke="#555555" strokeMiterlimit="10" strokeWidth="4" x1="9" x2="41" y1="25" y2="25"/></svg>
);

const PaymentFlags = () => (
  <div className="flex gap-1 justify-center mt-2 mb-4">
    <span className="payment-flag credit-card border rounded border-gray-900 p-1 bg-white">
      <svg width="22px" height="16px" viewBox="0 0 22 16" fill="var(--blue-darker)" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-v-4e2639a2="">
        <path d="M3 12.5C3 12.2239 3.22386 12 3.5 12H7.5C7.77614 12 8 12.2239 8 12.5C8 12.7761 7.77614 13 7.5 13H3.5C3.22386 13 3 12.7761 3 12.5Z" fillRule="evenodd" clipRule="evenodd"></path>
        <path d="M0 2.5C0 1.11929 1.11929 0 2.5 0H17.5C18.8807 0 20 1.11929 20 2.5V13.5C20 14.8807 18.8807 16 17.5 16H2.5C1.11929 16 0 14.8807 0 13.5V2.5ZM1 7V13.5C1 14.3284 1.67157 15 2.5 15H17.5C18.3284 15 19 14.3284 19 13.5V7H1ZM19 4H1V2.5C1 1.67157 1.67157 1 2.5 1H17.5C18.3284 1 19 1.67157 19 2.5V4Z" fillRule="evenodd" clipRule="evenodd"></path>
      </svg>
    </span>
    <span className="payment-flag credit-card border rounded border-gray-900 p-1 bg-white">
      <svg width="22px" height="16px" viewBox="0 0 22 23" fill="var(--blue-darker)" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-v-4e2639a2="">
        <path d="M5.19223 5.24323C6.06969 5.24323 6.89487 5.58498 7.51525 6.20516L10.8818 9.57225C11.1243 9.8147 11.5202 9.81575 11.7633 9.57193L15.1175 6.21736C15.738 5.59718 16.5632 5.25554 17.4407 5.25554H17.8447L13.5842 0.995107C12.2574 -0.331702 10.1063 -0.331702 8.77948 0.995107L4.53135 5.24323H5.19223ZM17.4406 17.108C16.5632 17.108 15.738 16.7664 15.1176 16.1462L11.7632 12.792C11.5278 12.5558 11.1173 12.5565 10.8819 12.792L7.51531 16.1585C6.89482 16.7786 6.06964 17.1202 5.19219 17.1202H4.5312L8.77943 21.3686C10.1062 22.6953 12.2574 22.6953 13.5842 21.3686L17.8447 17.108H17.4406ZM18.794 6.20484L21.3686 8.77947C22.6954 10.1062 22.6954 12.2573 21.3686 13.5842L18.7941 16.1587C18.7373 16.1359 18.6761 16.1218 18.6112 16.1218H17.4407C16.8354 16.1218 16.243 15.8764 15.8154 15.4484L12.4611 12.0945C11.8532 11.4859 10.7925 11.4862 10.184 12.0942L6.81744 15.4607C6.38976 15.8886 5.79746 16.134 5.19222 16.134H3.75286C3.69154 16.134 3.634 16.1486 3.57983 16.169L0.995108 13.5842C-0.331703 12.2573 -0.331703 10.1062 0.995108 8.77947L3.57994 6.19464C3.63411 6.21504 3.69154 6.22956 3.75286 6.22956H5.19222C5.79746 6.22956 6.38976 6.47496 6.81744 6.90285L10.1843 10.2697C10.4982 10.5833 10.9103 10.7404 11.3227 10.7404C11.7349 10.7404 12.1473 10.5833 12.4611 10.2694L15.8154 6.91505C16.243 6.48716 16.8354 6.24176 17.4407 6.24176H18.6112C18.676 6.24176 18.7373 6.22756 18.794 6.20484Z" fillRule="evenodd" clipRule="evenodd"></path>
      </svg>
    </span>
    <span className="payment-flag credit-card border rounded border-gray-900 p-1 bg-white">
      <svg width="22px" height="16px" viewBox="0 0 109 150" fill="var(--blue-darker)" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-v-4e2639a2="">
        <path d="M108.268,95.232c0-13.826-4.389-34.675-15.362-52.452c0,0-0.658,0-0.658,0.439  c0.878,2.634,1.097,7.681-3.292,7.242c-7.681-0.439,0-16.679-16.46-27.872c-0.219,0-0.439,0.219-0.439,0.439l0,0  c1.097,2.195,1.756,8.778-0.878,10.973c-2.195,2.195-5.706,1.536-9.437-4.389c-5.267-8.559-5.267-19.313,0-27.872 c0.658-0.658,0-0.878,0-0.658C38.04,8.544,33.651,35.538,28.823,45.414c-1.097,1.756-1.756,2.634-3.292,2.195 c-4.389,0-1.097-8.778,0.439-12.07c-0.219-0.219-0.439-0.219-0.658,0l0,0C12.363,50.242,0.732,73.285,0.732,93.257  C0.732,123.323,22.02,149,54.281,149C83.908,149,108.268,125.298,108.268,95.232z M54.061,120.25 c-13.607-0.439-24.36-11.632-24.141-25.458c0.439-13.607,11.632-24.36,25.458-24.141c13.387,0.439,23.921,11.193,24.141,24.58 c0.219,13.607-10.754,24.799-24.58,25.019C54.719,120.25,54.5,120.25,54.061,120.25L54.061,120.25z" fillRule="evenodd" clipRule="evenodd"></path>
      </svg>
    </span>
    <span className="payment-flag credit-card border rounded border-gray-900 p-1 bg-white">
      <svg width="22px" height="16px" viewBox="0 0 22 16" fill="var(--blue-darker)" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-v-4e2639a2="">
        <path d="M1.5 1C1.22386 1 1 1.22386 1 1.5V4.5C1 4.77614 0.776142 5 0.5 5C0.223858 5 0 4.77614 0 4.5V1.5C0 0.671573 0.671573 0 1.5 0H4.5C4.77614 0 5 0.223858 5 0.5C5 0.776142 4.77614 1 4.5 1H1.5ZM17 0.5C17 0.223858 17.2239 0 17.5 0H20.5C21.3284 0 22 0.671573 22 1.5V4.5C22 4.77614 21.7761 5 21.5 5C21.2239 5 21 4.77614 21 4.5V1.5C21 1.22386 20.7761 1 20.5 1H17.5C17.2239 1 17 0.776142 17 0.5ZM3.5 3C3.77614 3 4 3.22386 4 3.5V12.5C4 12.7761 3.77614 13 3.5 13C3.22386 13 3 12.7761 3 12.5V3.5C3 3.22386 3.22386 3 3.5 3ZM6.5 3C6.77614 3 7 3.22386 7 3.5V12.5C7 12.7761 6.77614 13 6.5 13C6.22386 13 6 12.7761 6 12.5V3.5C6 3.22386 6.22386 3 6.5 3ZM9.5 3C9.77614 3 10 3.22386 10 3.5V12.5C10 12.7761 9.77614 13 9.5 13C9.22386 13 9 12.7761 9 12.5V3.5C9 3.22386 9.22386 3 9.5 3ZM12.5 3C12.7761 3 13 3.22386 13 3.5V12.5C13 12.7761 12.7761 13 12.5 13C12.2239 13 12 12.7761 12 12.5V3.5C12 3.22386 12.2239 3 12.5 3ZM15.5 3C15.7761 3 16 3.22386 16 3.5V12.5C16 12.7761 15.7761 13 15.5 13C15.2239 13 15 12.7761 15 12.5V3.5C15 3.22386 15.2239 3 15.5 3ZM0.5 11C0.776142 11 1 11.2239 1 11.5V14.5C1 14.7761 1.22386 15 1.5 15H4.5C4.77614 15 5 15.2239 5 15.5C5 15.7761 4.77614 16 4.5 16H1.5C0.671573 16 0 15.3284 0 14.5V11.5C0 11.2239 0.223858 11 0.5 11ZM21.5 11C21.7761 11 22 11.2239 22 11.5V14.5C22 15.3284 21.3284 16 20.5 16H17.5C17.2239 16 17 15.7761 17 15.5C17 15.2239 17.2239 15 17.5 15H20.5C20.7761 15 21 14.7761 21 14.5V11.5C21 11.2239 21.2239 11 21.5 11Z" fillRule="evenodd" clipRule="evenodd"></path>
      </svg>
    </span>
    <span className="payment-flag credit-card border rounded border-gray-900 p-1 bg-white">
      <svg width="22px" height="16px" viewBox="0 0 84 98.179" fill="var(--blue-darker)" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-v-4e2639a2="">
        <g>
          <path opacity="0.68" fill="#707780" d="M74.981,24.944c1.198-7.638-0.008-12.834-4.139-17.542C66.294,2.22,58.078,0,47.566,0 H17.052c-2.148,0-3.978,1.563-4.312,3.686L0.033,84.258c-0.25,1.59,0.979,3.027,2.587,3.027h18.838l-1.3,8.245 c-0.219,1.391,0.855,2.648,2.263,2.648H38.3c1.879,0,3.479-1.367,3.772-3.224l0.156-0.808l2.991-18.968l0.193-1.048 c0.293-1.856,1.893-3.225,3.772-3.225h2.375c15.383,0,27.428-6.249,30.947-24.323c1.471-7.552,0.71-13.856-3.179-18.288 C78.152,26.956,76.688,25.848,74.981,24.944L74.981,24.944"></path>
          <path opacity="0.7" fill="#707780" d="M74.981,24.944c1.198-7.638-0.008-12.834-4.139-17.542C66.294,2.22,58.078,0,47.566,0H17.052 c-2.148,0-3.978,1.563-4.312,3.686L0.033,84.258c-0.25,1.59,0.979,3.027,2.587,3.027h18.838l4.732-30.007l-0.147,0.941 c0.335-2.122,2.148-3.686,4.297-3.686h8.954c17.584,0,31.353-7.144,35.375-27.804C74.789,26.119,74.89,25.526,74.981,24.944"></path>
          <path fill="#707780" d="M31.274,25.045c0.201-1.276,1.021-2.322,2.124-2.85c0.501-0.24,1.062-0.374,1.649-0.374h23.919 c2.834,0,5.476,0.186,7.891,0.575c0.69,0.111,1.362,0.239,2.015,0.384c0.652,0.145,1.286,0.306,1.899,0.486 c0.307,0.089,0.608,0.184,0.905,0.282c1.186,0.394,2.291,0.858,3.307,1.397c1.198-7.638-0.008-12.834-4.139-17.542 C66.294,2.22,58.078,0,47.566,0H17.052c-2.148,0-3.978,1.563-4.312,3.686L0.033,84.258c-0.25,1.59,0.979,3.027,2.587,3.027h18.838 l4.732-30.007L31.274,25.045z"></path>
        </g>
      </svg>
    </span>
    <span className="payment-flag credit-card border rounded border-gray-900 p-1 bg-white">
      <svg width="22px" height="16px" viewBox="0 0 30 30" fill="var(--blue-darker)" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-v-4e2639a2="">
        <g fill="none" fillRule="evenodd"><path fill="#707780" d="M0 0h30v30H0z"></path>
          <path fill="#FFF" d="M10.783 15.777h7.561l5.18 8.58h-7.561l-5.18-8.58"></path>
          <path fill="#45494F" d="M11.569 14.459h7.815l6.787-8.544h-7.815l-6.787 8.544m-7.814 9.95h7.814l6.787-8.649h-7.814l-6.787 8.649"></path>
          <path fill="#FFF" d="M6.573 5.811h7.293l4.997 8.648h-7.294L6.573 5.811"></path>
        </g>
      </svg>
    </span>
  </div>
);

const converObjectToParams = (object: any) => {
  return Object.keys(object)
    .filter((key) => object[key])
    .map((key) => {
      return `${key}=${encodeURIComponent(object[key])}`;
    })
    .join("&");
};

const RangeBar = ({ value, caption }: { value: number, caption: string }) => {
  const barRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (barRef.current) {
      barRef.current.style.width = `${value}%`;
    }
  }, [value]);

  return (
    <div className="flex flex-col justify-start gap-2 mx-2">
      <span className="bar flex rounded-full bg-gray-300 w-full h-2 my-1">
        <span ref={barRef} className="bar__fill rounded-l-full bg-blue-600" style={{ width: `${value}%` }}></span>
        <div className="relative content-center">
          <div className="absolute -left-2 -top-1 rounded-full border-2 bg-white p-0.5 border-blue-600">
            <div className="h-2 w-2 rounded-full bg-blue-600"></div>
          </div>
        </div>
      </span>
      <span className="text-md text-left font-semibold">{caption}</span>
    </div>
  );
}

type Plan = "monthly" | "quarterly" | "yearly";

const PlansComponent = ({
  id,
  selectedPlan,
  setselectedPlan,
  buildNextPath,
  timer,
  dark,
  variant,
}: {
  id?: string,
  selectedPlan: string,
  setselectedPlan: (value: Plan) => void,
  buildNextPath: () => string,
  timer?: boolean,
  dark?: boolean
  variant?: boolean
}) => {
  enum Plan {
    Monthly = "monthly",
    Quarterly = "quarterly",
    Yearly = "yearly"
  }

  const bgCard = dark ? "bg-gray-700" : "";
  const borderCard = dark ? "" : "border-slate-200";
  const border = dark ? "border-slate-600" : "border-slate-200";
  const textColor = dark ? "text-gray-50" : "text-gray-900";
  const textColorPlanTitle = dark ? "text-gray-50" : "";
  const textColorPlanPrice = dark ? "text-gray-50" : "text-black";
  const itemColorSelected = dark ? "bg-sky-900 border-blue-500" : "bg-slate-100 border-blue-500";
  const mostPopularColor = dark ? "text-slate-400 bg-slate-600" : "text-slate-400 bg-slate-200";
  const mostPopularColorSelected = dark ? "text-white bg-blue-500" : "text-white bg-blue-500";

  return (
    <div id={id} className={`flex flex-col border-2 ${bgCard} ${borderCard} rounded-xl mb-4`}>
      {timer && (
        <div className="bg-orange-400 py-1 rounded-t-[0.5rem] text-white text-sm font-semibold">
          59% DE DESCONTO RESERVADO POR <TimeCounter />
        </div>
      )}

      <h2 className={`mb-4 mt-5 text-3xl font-bold tracking-tight ${textColor} md:text-5xl lg:text-6xl`}>
        Escolha seu plano
      </h2>

      <div className="flex flex-col p-3 gap-2">

        <div
          role="option"
          onClick={() => setselectedPlan(Plan.Monthly)}
          className={
            "flex flex-col border-2 rounded-xl cursor-pointer text-gray-900 " +
            (selectedPlan === Plan.Monthly
              ? itemColorSelected
              : border)
          }
        >
          <div className="flex flex-col">
            <div className="flex gap-1 px-2 py-2 justify-between">
              <div className="flex">
                <div className="pr-2 content-center">
                  <div
                    className={
                      "mt-1.5 rounded-full border-2 p-0.5 " +
                      (selectedPlan === Plan.Monthly ? "border-blue-600" : border)
                    }
                  >
                    <div
                      className={
                        "h-2 w-2 rounded-full " +
                        (selectedPlan === Plan.Monthly ? "bg-blue-600" : "")
                      }
                    ></div>
                  </div>
                </div>


                <div className="flex flex-col items-start">
                  <h3 className={"text-xl font-bold mb-1 " + textColorPlanTitle}>1 mês</h3>
                  <span className="rounded-[0.275rem] bg-transparent text-white px-1 py-[0.06rem] text-xs h-4"></span>
                  <span className="text-sm text-slate-400 mt-1">
                    R$49,77
                  </span>
                </div>
              </div>

              <div className="plan-price-per-day flex gap-2">
                <div className="plan-price-per-day__old-price">
                  <span className="line-through text-md text-slate-400 block w-12"></span>
                </div>
                <div
                  className={
                    "plan-price-per-day__new-price flex font-bold " +
                    (selectedPlan === Plan.Monthly
                      ? textColorPlanPrice
                      : "text-slate-400")
                  }
                >
                  <span className="price__currency-symbol text-md text-slate-400">R$</span>
                  <span className="price__integer text-[4rem] leading-[3rem] mx-1 font-semibold">1</span>
                  <span className="price__decimal flex flex-col justify-start">
                    <span className="price__decimal-number text-left">65</span>
                    <span className="price__per text-[0.5rem] text-left">POR DIA</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          role="option"
          onClick={() => setselectedPlan(Plan.Quarterly)}
          className={
            "flex flex-col border-2 rounded-xl cursor-pointer text-gray-900 " +
            (selectedPlan === Plan.Quarterly
              ? itemColorSelected
              : border)
          }
        >
          <div className="flex flex-col">
            <div className="flex gap-1 px-2 py-2 justify-between">
              <div className="flex">
                <div className="pr-2 content-center">
                  <div
                    className={
                      "mt-1.5 rounded-full border-2 p-0.5 " +
                      (selectedPlan === Plan.Quarterly ? "border-blue-600" : border)
                    }
                  >
                    <div
                      className={
                        "h-2 w-2 rounded-full " +
                        (selectedPlan === Plan.Quarterly ? "bg-blue-600" : "")
                      }
                    ></div>
                  </div>
                </div>


                <div className="flex flex-col items-start">
                  <h3 className={"text-xl font-bold mb-1 " + textColorPlanTitle}>3 meses</h3>
                  <span
                    className={
                      "rounded-[0.275rem] font-semibold px-1 py-[0.06rem] text-xs " +
                      (selectedPlan === Plan.Quarterly ? "bg-blue-500 text-white" : "bg-gray-200 text-blue-500")
                    }
                  >25% OFF</span>
                  <span className="text-sm text-slate-400 mt-1">
                    <span className="line-through mr-1">R$129,77</span>
                    R$97,33
                  </span>
                </div>
              </div>

              <div className="plan-price-per-day flex gap-2">
                <div className="plan-price-per-day__old-price">
                  <span className="line-through text-md text-slate-400">R$1,44</span>
                </div>
                <div
                  className={
                    "plan-price-per-day__new-price flex font-bold " +
                    (selectedPlan === Plan.Quarterly
                      ? textColorPlanPrice
                      : "text-slate-400")
                  }
                >
                  <span className="price__currency-symbol text-md text-slate-400">R$</span>
                  <span className="price__integer text-[4rem] leading-[3rem] mx-1 font-semibold">1</span>
                  <span className="price__decimal flex flex-col justify-start">
                    <span className="price__decimal-number text-left">08</span>
                    <span className="price__per text-[0.5rem] text-left">POR DIA</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          role="option"
          onClick={() => setselectedPlan(Plan.Yearly)}
          className={
            "flex flex-col border-2 rounded-xl cursor-pointer text-gray-900 " +
            (selectedPlan === Plan.Yearly
              ? itemColorSelected
              : border)
          }
        >
          <div className="flex flex-col">
            <div
              className={
                "slate-200 font-semibold rounded-t-[0.50rem] " +
                (selectedPlan === Plan.Yearly
                  ? mostPopularColorSelected
                  : mostPopularColor)
              }
            >
              MAIS POPULAR
            </div>
            <div className="flex gap-1 px-2 py-2 justify-between">
              <div className="flex">
                <div className="pr-2 content-center">
                  <div
                    className={
                      "mt-1.5 rounded-full border-2 p-0.5 " +
                      (selectedPlan === Plan.Yearly ? "border-blue-600" : border)
                    }
                  >
                    <div
                      className={
                        "h-2 w-2 rounded-full " +
                        (selectedPlan === Plan.Yearly ? "bg-blue-600" : "")
                      }
                    ></div>
                  </div>
                </div>

                <div className="flex flex-col items-start">
                  <h3 className={"text-xl font-bold mb-1 " + textColorPlanTitle}>12 meses</h3>
                  <span
                    className={
                      "rounded-[0.275rem] font-semibold px-1 py-[0.06rem] text-xs " +
                      (selectedPlan === Plan.Yearly ? "bg-blue-500 text-white" : "bg-gray-200 text-blue-500")
                    }
                  >
                    {variant ? (
                      "63% OFF"
                    ) : (
                      "59% OFF"
                    )}
                  </span>
                  <span className="text-sm text-slate-400 mt-1">
                    <span className="line-through mr-1">R$359,90</span>
                      {variant ? (
                        "R$133,16"
                      ) : (
                        "R$147,56"
                      )}
                  </span>
                </div>
              </div>

              <div className="plan-price-per-day flex gap-2">
                <div className="plan-price-per-day__old-price">
                  <span className="line-through text-md text-slate-400">R$0.98</span>
                </div>
                <div
                  className={
                    "plan-price-per-day__new-price flex font-bold " +
                    (selectedPlan === Plan.Yearly
                      ? textColorPlanPrice
                      : "text-slate-400")
                  }
                >
                  <span className="price__currency-symbol text-md text-slate-400">R$</span>
                  <span className="price__integer text-[4rem] leading-[3rem] mx-1 font-semibold">0</span>
                  <span className="price__decimal flex flex-col justify-start">
                    <span className="price__decimal-number text-left">
                      {variant ? (
                        "36"
                      ) : (
                        "40"
                      )}
                    </span>
                    <span className="price__per text-[0.5rem] text-left">POR DIA</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Link className="rounded-lg bg-blue-500 text-white py-3 font-semibold mt-3" to={buildNextPath()}>
          Continue
        </Link>

        <div className="flex grow-0 shrink justify-center mt-3">
          <div className="flex items-center rounded-xl bg-green-500/10 p-2 text-sm text-green-500 font-semibold">
            <svg
              className="w-5 h-5 mr-1"
              xmlns="http://www.w3.org/2000/svg"
              fill="rgb(22 163 74)"
              viewBox="0 0 28 28"
              stroke="rgb(22 163 74)"
            >
              <path d="M22.75 4.375H5.25C4.78587 4.375 4.34075 4.55937 4.01256 4.88756C3.68437 5.21575 3.5 5.66087 3.5 6.125V12.25C3.5 18.0162 6.29125 21.5108 8.63297 23.427C11.1552 25.4898 13.6642 26.1898 13.7736 26.2194C13.924 26.2603 14.0826 26.2603 14.233 26.2194C14.3423 26.1898 16.8481 25.4898 19.3736 23.427C21.7088 21.5108 24.5 18.0162 24.5 12.25V6.125C24.5 5.66087 24.3156 5.21575 23.9874 4.88756C23.6592 4.55937 23.2141 4.375 22.75 4.375ZM22.75 12.25C22.75 16.3045 21.2559 19.5956 18.3094 22.0303C17.0267 23.0866 15.5679 23.9085 14 24.4584C12.4526 23.9181 11.0118 23.1107 9.74313 22.073C6.76156 19.6339 5.25 16.3297 5.25 12.25V6.125H22.75V12.25ZM9.00594 15.4941C8.84175 15.3299 8.74951 15.1072 8.74951 14.875C8.74951 14.6428 8.84175 14.4201 9.00594 14.2559C9.17012 14.0918 9.39281 13.9995 9.625 13.9995C9.85719 13.9995 10.0799 14.0918 10.2441 14.2559L12.25 16.263L17.7559 10.7559C17.8372 10.6746 17.9337 10.6102 18.04 10.5662C18.1462 10.5222 18.26 10.4995 18.375 10.4995C18.49 10.4995 18.6038 10.5222 18.71 10.5662C18.8163 10.6102 18.9128 10.6746 18.9941 10.7559C19.0754 10.8372 19.1398 10.9337 19.1838 11.04C19.2278 11.1462 19.2505 11.26 19.2505 11.375C19.2505 11.49 19.2278 11.6038 19.1838 11.71C19.1398 11.8163 19.0754 11.9128 18.9941 11.9941L12.8691 18.1191C12.7878 18.2004 12.6913 18.265 12.5851 18.309C12.4788 18.353 12.365 18.3757 12.25 18.3757C12.135 18.3757 12.0212 18.353 11.9149 18.309C11.8087 18.265 11.7122 18.2004 11.6309 18.1191L9.00594 15.4941Z" fill="rgb(34 197 94)"/>
            </svg>

            <span>Compra 100% segura</span>
          </div>
        </div>

        <PaymentFlags />

        <div className="money-back-label flex justify-center text-center items-center mb-4">
          <img src={image7DaysMoneyBack} width={192} alt="7 dias de garantia" />
        </div>

      </div>
    </div>
  );
};


const PopupCheckout = ({checkoutLink, setBestPrice}: {checkoutLink: any, setBestPrice: any}) => {
  const [openModal, setOpenModal] = useState(true);

  const customTheme: CustomFlowbiteTheme = {
    modal: {
      show: {
        "on": "flex bg-gray-900 bg-opacity-50 backdrop-blur dark:bg-opacity-80",
        "off": "hidden"
      },
      header: {
        base: "flex flex-row-reverse items-center border-b-0 rounded-t p-2 dark:border-gray-600",
        popup: "border-b-0 p-2",
        title: "w-full text-md font-normal text-center text-gray-900 dark:text-white",
        close: {
          base: "inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white",
          icon: "h-5 w-5"
        }
      },
      body: {
        base: ""
      },
      footer: {
        base: "flex flex-col border-t-0 p-4 gap-4 bg-gray-100 rounded-b-xl",
      }
    }
  };

  return (
    <>
      <Flowbite theme={{ theme: customTheme }}>
        <Modal show={openModal} onClose={() => {setBestPrice(true); setOpenModal(false)}}>
          <Modal.Header>Checkout</Modal.Header>
          <Modal.Body>
            <h2 className="text-center text-xl font-bold mb-4 p-4">
              <span className="text-blue-600">91% dos usuários</span> estão satisfeitos com o plano e permanecem conosco.
            </h2>
            <div className="flex justify-between text-sm px-4 mb-4">
              <span>Total</span>
              <span className="font-bold">R$ 147,56</span>
            </div>
            <div className="flex flex-col gap-4 p-4 bg-gray-100">
              <h3 className="text-lg font-bold">Pague com rapidez e segurança</h3>
              <div className="payment">
                <img src="https://hotmart.com/javax.faces.resource/images/hotpay/hotpay-logo.png.xhtml" alt="hotpay" />
              </div>
              <div className="payment-flags">
                <PaymentFlags />
              </div>
              {/* <div className="payment-form">
                <input type="text" placeholder="Nome no cartão" />
                <input type="text" placeholder="Número do cartão" />
                <div className="flex gap-2">
                  <input type="text" placeholder="MM/AA" />
                  <input type="text" placeholder="CVV" />
                </div>
                <input type="text" placeholder="CPF" />
              </div> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Link className="flex justify-center rounded-lg bg-blue-500 text-white py-3 font-semibold mt-3" to={checkoutLink}>
              <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 9V7C17 4.2 14.8 2 12 2C9.2 2 7 4.2 7 7V8H9V9H7C5.3 9 4 10.3 4 12L4.03855 17.1C4.03855 18.8 5.33855 20.1 7.03855 20.1H17.0386C18.7386 20.1 20.0386 18.8 20.0386 17.1L20 12C20 10.3 18.7 9 17 9ZM9 7C9 5.3 10.3 4 12 4C13.7 4 15 5.3 15 7V9H9V8V7ZM13.1386 14.6L13.0386 14.7V16.1C13.0386 16.7 12.6386 17.1 12.0386 17.1C11.4386 17.1 11.0386 16.7 11.0386 16.1V14.7C10.4386 14.1 10.3386 13.2 10.9386 12.6C11.5386 12 12.4386 11.9 13.0386 12.5C13.6386 13 13.7386 14 13.1386 14.6Z" fill="white"/>
              </svg>
              <span className="ml-1">CONFIRMAR PAGAMENTO</span>
            </Link>
            <div className="flex grow-0 shrink justify-center mt-3">
              <div className="flex items-center rounded-xl bg-green-500/10 p-2 text-sm text-green-500 font-semibold">
                <svg
                  className="w-5 h-5 mr-1"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="rgb(22 163 74)"
                  viewBox="0 0 28 28"
                  stroke="rgb(22 163 74)"
                >
                  <path d="M22.75 4.375H5.25C4.78587 4.375 4.34075 4.55937 4.01256 4.88756C3.68437 5.21575 3.5 5.66087 3.5 6.125V12.25C3.5 18.0162 6.29125 21.5108 8.63297 23.427C11.1552 25.4898 13.6642 26.1898 13.7736 26.2194C13.924 26.2603 14.0826 26.2603 14.233 26.2194C14.3423 26.1898 16.8481 25.4898 19.3736 23.427C21.7088 21.5108 24.5 18.0162 24.5 12.25V6.125C24.5 5.66087 24.3156 5.21575 23.9874 4.88756C23.6592 4.55937 23.2141 4.375 22.75 4.375ZM22.75 12.25C22.75 16.3045 21.2559 19.5956 18.3094 22.0303C17.0267 23.0866 15.5679 23.9085 14 24.4584C12.4526 23.9181 11.0118 23.1107 9.74313 22.073C6.76156 19.6339 5.25 16.3297 5.25 12.25V6.125H22.75V12.25ZM9.00594 15.4941C8.84175 15.3299 8.74951 15.1072 8.74951 14.875C8.74951 14.6428 8.84175 14.4201 9.00594 14.2559C9.17012 14.0918 9.39281 13.9995 9.625 13.9995C9.85719 13.9995 10.0799 14.0918 10.2441 14.2559L12.25 16.263L17.7559 10.7559C17.8372 10.6746 17.9337 10.6102 18.04 10.5662C18.1462 10.5222 18.26 10.4995 18.375 10.4995C18.49 10.4995 18.6038 10.5222 18.71 10.5662C18.8163 10.6102 18.9128 10.6746 18.9941 10.7559C19.0754 10.8372 19.1398 10.9337 19.1838 11.04C19.2278 11.1462 19.2505 11.26 19.2505 11.375C19.2505 11.49 19.2278 11.6038 19.1838 11.71C19.1398 11.8163 19.0754 11.9128 18.9941 11.9941L12.8691 18.1191C12.7878 18.2004 12.6913 18.265 12.5851 18.309C12.4788 18.353 12.365 18.3757 12.25 18.3757C12.135 18.3757 12.0212 18.353 11.9149 18.309C11.8087 18.265 11.7122 18.2004 11.6309 18.1191L9.00594 15.4941Z" fill="rgb(34 197 94)"/>
                </svg>

                <span>Compra 100% segura</span>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </Flowbite>
    </>
  );
};

type Gender = "man" | "woman" | "no";

const PersonComponenet = ({ gender }: { gender: Gender }) => {
  const person1A = require("../../assets/images/persons/person1_a.webp");
  const person1B = require("../../assets/images/persons/person1_b.webp");
  const person2A = require("../../assets/images/persons/person2_a.webp");
  const person2B = require("../../assets/images/persons/person2_b.webp");
  const person3A = require("../../assets/images/persons/person3_a.webp");
  const person3B = require("../../assets/images/persons/person3_b.webp");

  const person = {
    man: [person1A, person1B],
    woman: [person2A, person2B],
    no: [person3A, person3B],
  };

  return (
    <div className="flex justify-center pt-2 px-2 bg-neutral-100">
      <img
        className="w-1/2"
        src={person[gender][0]}
        alt="Person"
      />
      <img
        className="w-1/2"
        src={person[gender][1]}
        alt="Person"
      />
    </div>
  );
};


function SelectPlanV2() {
  const { t, i18n } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const [showOthersPlans, setShowOthersPlans] = useState<boolean>(false);
  const [selectedPlan, setselectedPlan] = useState<string>("yearly");
  const [currentFaq, setCurrentFaq] = useState<number>(0);
  const [bestPrice, setBestPrice] = useState<boolean>(false);
  const { offerType } = useParams();
  const navigate = useNavigate();
  const [flowStrategy, setFlowStrategy] = useSessionStorage(
    "flow-strategy",
    ""
  );
  const isRet = sessionStorage.getItem("utm_source") === "ret";
  const isFromTwitter = sessionStorage.getItem("utm_source") === "twitter";

  // const newPricing = useFeatureIsOn("yearly-plan-pricing-feature");
  // const newPricing = useFeatureIsOn("exp-12-63-vs-59-off-with-the-bundle");
  const removeMonthly = useFeatureIsOn("remove-monthly-plan-feature");
  const newHeadline = useFeatureIsOn("select-plan-headline-feature")
  // const checkout12minPremium = useFeatureIsOn("checkout-12min-premium-feature");
  const exp16Checkout = useFeatureIsOn("exp-16-checkout-feature");
  const exp17feature = useFeatureIsOn("exp-17-feature");

  const isPostLeadFlowStrategy = flowStrategy === "lead-post-plan-selection";
  const [variant, _] = useSessionStorage("variant");

  const isDirectSale = offerType === "direct";
  const isRetSale = offerType === "ret";

  const isWithInterestVariant = variant === "with-interest" && isDirectSale;

  const activePlan = (isDirectSale || isRetSale) && selectedPlan === "yearly";

  type Plan = "monthly" | "quarterly" | "yearly";

  const checkoutId: any = {
    br: activePlan ? "E91856682Y" : "P5327674P",
    en: "K16542811H",
    es: activePlan ? "Y92363487F" : "Y16613490E",
  }[i18n.language];

  const offerCodeList: Record<string, string> = {
    twitter: "xrnblfqt",
    default: "7d18zm3s",
    exp16: "6reyasj1"
  };

  const offerCodeDirect: () => string = () => {
    if (isFromTwitter) return offerCodeList.twitter;
    if (exp16Checkout) return offerCodeList.exp16;
    return offerCodeList.default;
  };

  const discount = isRetSale  
    ? "promo75off" 
    : (bestPrice 
        ? "promo63off" 
        : "promo59off"
      );

  

  const offersTrial: any = {
    discounts: {
      base: "63OFF7D",
    },
    P5327674P: {
      yearly: { offerCode: "2zki1nl0" },
      monthly: { offerCode: "jx5nh72y" },
    },
    K16542811H: {
      yearly: { offerCode: "38tbqvq8" },
      monthly: { offerCode: "fys1w0n7" },
    },
    Y92363487F: {
      yearly: { offerCode: "vng1dkxz" },
      monthly: { offerCode: "7lcaoc5b" },
    },
  };

  const offersDirectSale: any = {
    discounts: {
      base: discount,
      quarterly: "promo30off",
    },
    P5327674P: {
      yearly: { offerCode: "suxzbi7s" },
      quarterly: { offerCode: "vp7zn9o1" },
      monthly: { offerCode: "jx5nh72y" },
    },
    E91856682Y: {
      yearly: { offerCode: offerCodeDirect() },
      monthly: { offerCode: "kppi78af" },
    },
    K16542811H: {
      yearly: { offerCode: "evc05pnv" },
      monthly: { offerCode: "b3i96d7z" },
    },
    Y16613490E: {
      yearly: { offerCode: "oqvwtnza" },
      monthly: { offerCode: "eptks9rt" },
    },
    Y92363487F: {
      yearly: { offerCode: "98fq4cvr" },
      monthly: { offerCode: "prlt2kyv" },
    },
  };

  const offersRetSale: any = {
    discounts: {
      base: "promo75off",
      quarterly: "promo30off",
    },
    E91856682Y: {
      yearly: { offerCode: "7d18zm3s" },
      monthly: { offerCode: "kppi78af" },
    },
  };

  const offPlans: () => false | string = () => {
    if (selectedPlan === "yearly") return bestPrice ? "promo63off" : "promo59off";
    if (selectedPlan === "quarterly") return "promo25off";
    return false;
  }

  const offersOptions: any = () => {
    if (isDirectSale) return offersDirectSale;
    if (isRetSale) return offersRetSale;
    return offersTrial;
  };

  const offers: any = offersOptions();
  const currentCheckout = offers[checkoutId];

  const buildCheckoutLink = () => {
    const baseUrl = `https://pay.hotmart.com/${checkoutId}`;
    const urlParams = !isPostLeadFlowStrategy ? screenData.urlParams : {};
    return (
      baseUrl +
      "?" +
      converObjectToParams({
        ...urlParams,
        off: currentCheckout[selectedPlan].offerCode,
        offDiscount: offPlans(), //selectedPlan === "yearly" ? offers.discounts.base : false,
        sck: rudderanalytics.getAnonymousId(),
        checkoutMode: "10",
        hidePix: "0",
        hideBillet: "0",
      })
    );
  };

  const buildNextPath = () => {
    const checkoutLink = buildCheckoutLink();
    return !isPostLeadFlowStrategy
      ? checkoutLink
      : "form?redirectUrl=" + encodeURIComponent(checkoutLink);
  };

  const Exp13AdditionalSteps = useFeatureIsOn("exp-13-additional-steps-feature");
  const step = Exp13AdditionalSteps ? 23 : 19;

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitdiv: undefined,
      votting: true, // unable cotinue button on the bottom screen
      nextPath: buildNextPath(),
      progress: 100,
      progressVariation: step,
      step: step,
      submitLabel: false,
      disabledButton: true,
    }));
  }, [selectedPlan, bestPrice]);

  // build checkout link
  let checkoutLink = buildNextPath();
  useEffect(() => {
    checkoutLink = buildNextPath();
  }, [screenData.nextPath]);

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      disabledButton: true,
    }));

  }, [screenData.disabledButton]);

  const iconStar = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="mb-1 mr-1 inline-block h-5 w-5 text-green-700"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
      />
    </svg>
  );

  const iconStarOrange = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="rgb(251 146 60)"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="inline-block h-5 w-5 text-orange-400"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
      />
    </svg>
  );

  const iconFiveStarsOrange = (
    <>
      {iconStarOrange}
      {iconStarOrange}
      {iconStarOrange}
      {iconStarOrange}
      {iconStarOrange}
    </>
  );

  const iconStarOrangeLittleEmpty = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="inline-block h-5 w-5 text-orange-400"
    >
      <path d="M11.4787 3.49726C11.521 3.39409 11.593 3.30584 11.6856 3.24372C11.7782 3.18161 11.8872 3.14844 11.9987 3.14844C12.1102 3.14844 12.2192 3.18161 12.3118 3.24372C12.4044 3.30584 12.4764 3.39409 12.5187 3.49726L14.6437 8.60826C14.6835 8.7039 14.7489 8.78671 14.8327 8.84757C14.9165 8.90844 15.0154 8.94501 15.1187 8.95326L20.6367 9.39526C21.1357 9.43526 21.3377 10.0583 20.9577 10.3833L16.7537 13.9853C16.6751 14.0525 16.6166 14.14 16.5845 14.2382C16.5524 14.3365 16.548 14.4417 16.5717 14.5423L17.8567 19.9273C17.8825 20.0353 17.8757 20.1486 17.8372 20.2528C17.7987 20.357 17.7302 20.4474 17.6403 20.5127C17.5504 20.5779 17.4432 20.6151 17.3322 20.6195C17.2212 20.6239 17.1114 20.5953 17.0167 20.5373L12.2917 17.6523C12.2034 17.5985 12.1021 17.57 11.9987 17.57C11.8953 17.57 11.7939 17.5985 11.7057 17.6523L6.98069 20.5383C6.88594 20.5963 6.77614 20.6249 6.66514 20.6205C6.55415 20.6161 6.44694 20.5789 6.35705 20.5137C6.26717 20.4484 6.19865 20.358 6.16014 20.2538C6.12163 20.1496 6.11486 20.0363 6.14069 19.9283L7.42569 14.5423C7.44952 14.4417 7.44514 14.3364 7.41303 14.2382C7.38092 14.1399 7.32232 14.0524 7.24369 13.9853L3.03969 10.3833C2.9555 10.3109 2.8946 10.2152 2.86464 10.1083C2.83467 10.0014 2.83699 9.888 2.8713 9.78241C2.90561 9.67681 2.97037 9.58372 3.05745 9.51483C3.14453 9.44595 3.25003 9.40435 3.36069 9.39526L8.87869 8.95326C8.98194 8.94501 9.08091 8.90844 9.16471 8.84757C9.24852 8.78671 9.31391 8.7039 9.35369 8.60826L11.4787 3.49726Z" fill="#FB923C" stroke="#FB923C"/>
      <path d="M20.6352 9.39513L15.1172 8.95312L16.5 15.5L16.7522 13.9851L20.9562 10.3831C21.3362 10.0581 21.1342 9.43513 20.6352 9.39513Z" fill="white"/>
    </svg>
  );

  const iconStarOrangeLittleFull = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="inline-block h-5 w-5 text-orange-400"
    >
      <path d="M11.4787 3.49726C11.521 3.39409 11.593 3.30584 11.6856 3.24372C11.7782 3.18161 11.8872 3.14844 11.9987 3.14844C12.1102 3.14844 12.2192 3.18161 12.3118 3.24372C12.4044 3.30584 12.4764 3.39409 12.5187 3.49726L14.6437 8.60826C14.6835 8.7039 14.7489 8.78671 14.8327 8.84757C14.9165 8.90844 15.0154 8.94501 15.1187 8.95326L20.6367 9.39526C21.1357 9.43526 21.3377 10.0583 20.9577 10.3833L16.7537 13.9853C16.6751 14.0525 16.6166 14.14 16.5845 14.2382C16.5524 14.3365 16.548 14.4417 16.5717 14.5423L17.8567 19.9273C17.8825 20.0353 17.8757 20.1486 17.8372 20.2528C17.7987 20.357 17.7302 20.4474 17.6403 20.5127C17.5504 20.5779 17.4432 20.6151 17.3322 20.6195C17.2212 20.6239 17.1114 20.5953 17.0167 20.5373L12.2917 17.6523C12.2034 17.5985 12.1021 17.57 11.9987 17.57C11.8953 17.57 11.7939 17.5985 11.7057 17.6523L6.98069 20.5383C6.88594 20.5963 6.77614 20.6249 6.66514 20.6205C6.55415 20.6161 6.44694 20.5789 6.35705 20.5137C6.26717 20.4484 6.19865 20.358 6.16014 20.2538C6.12163 20.1496 6.11486 20.0363 6.14069 19.9283L7.42569 14.5423C7.44952 14.4417 7.44514 14.3364 7.41303 14.2382C7.38092 14.1399 7.32232 14.0524 7.24369 13.9853L3.03969 10.3833C2.9555 10.3109 2.8946 10.2152 2.86464 10.1083C2.83467 10.0014 2.83699 9.888 2.8713 9.78241C2.90561 9.67681 2.97037 9.58372 3.05745 9.51483C3.14453 9.44595 3.25003 9.40435 3.36069 9.39526L8.87869 8.95326C8.98194 8.94501 9.08091 8.90844 9.16471 8.84757C9.24852 8.78671 9.31391 8.7039 9.35369 8.60826L11.4787 3.49726Z" fill="white" stroke="#FB923C"/>
      <path d="M3.51716 9.39513L8 9V19.5L6.5 20.5L7.40016 13.9851L3.19616 10.3831C2.81616 10.0581 3.01816 9.43513 3.51716 9.39513Z" fill="#FB923C"/>
    </svg>
  );

  const gender: Gender = screenData.userPreferences.genderAnswer
    ? screenData.userPreferences.genderAnswer
    : "no";

  return (
    <div className="flex flex-col">
      {exp17feature && (
        <PopupCheckout checkoutLink={checkoutLink} setBestPrice={setBestPrice} />
      )}

      {!exp17feature && (
      <div className="flex flex-col border-2 border-slate-200 rounded-xl mb-4 bg-neutral-50">
        <div className="flex justify-center divide-x py-2 text-md font-bold">
          <span className="text-center w-full py-1">
            Agora
          </span>
          <span className="text-center w-full py-1">
            Objetivo
          </span>
        </div>
        <div className="gender-illustration mb-1">
          <PersonComponenet gender={gender} />
        </div>
        <div className="potential pb-2">
          <div className="potential__section grid grid-rows-2 grid-cols-2 divide-x bg-neutral-50">
            <h2 className="text-lg text-center font-bold my-1 col-span-2">
              Renda potencial
            </h2>
            <div className="potential__item border-none">
              <RangeBar value={43} caption="Limitado" />
            </div>
            <div className="potential__item">
              <RangeBar value={72} caption="Alto" />
            </div>
          </div>
          <div className="potential__section grid grid-rows-2 grid-cols-2 divide-x bg-neutral-50">
            <h2 className="text-lg text-center font-bold my-1 col-span-2">
              Motivação
            </h2>
            <div className="potential__item border-none">
              <RangeBar value={53} caption="Limitado" />
            </div>
            <div className="potential__item">
              <RangeBar value={77} caption="Alto" />
            </div>
          </div>
          <div className="potential__section grid grid-rows-2 grid-cols-2 divide-x bg-neutral-50">
            <h2 className="text-lg text-center font-bold my-1 col-span-2">
              Conhecimento
            </h2>
            <div className="potential__item border-none">
              <RangeBar value={46} caption="Limitado" />
            </div>
            <div className="potential__item">
              <RangeBar value={82} caption="Alto" />
            </div>
          </div>
        </div>
      </div>
      )}

      <PlansComponent id="select-plan-a" selectedPlan={selectedPlan} setselectedPlan={(value) => setselectedPlan(value)} buildNextPath={buildNextPath} variant={bestPrice} />

      <div className="flex flex-col border border-emerald-300 rounded-xl mb-4 bg-emerald-50">
        <h2 className="mb-4 mt-5 text-3xl font-bold tracking-tight text-gray-900  md:text-5xl lg:text-6xl">
          O que te espera no 12min
        </h2>

        <div className="flex flex-col p-3 gap-2">
          <ul className="text-left text-gray-600 font-semibold">
            <li>{iconStar} +3000 microbooks de não ficção</li>
            <li>{iconStar} +15000 insights valiosos</li>
            <li>{iconStar} Acompanhamento de progresso</li>
            <li>{iconStar} Desafios de Auto-Desenvolvimento</li>
            <li>{iconStar} Quizzes exclusivos para memorização e absorção do conteúdo</li>
          </ul>
        </div>
      </div>

      <div className="flex flex-col border border-orange-200 rounded-xl mb-4 bg-orange-50">
        <h2 className="mb-4 mt-5 text-3xl font-bold tracking-tight text-gray-900  md:text-5xl lg:text-6xl">
          Perguntas Frequentes
        </h2>

        <div className="flex flex-col p-3 gap-2">

          <div
            className="bg-white border border-slate-100 rounded-md"
            onClick={() => setCurrentFaq(1)}
          >
            <div className="flex justify-between items-center p-3 cursor-pointer">
              <h3 className="text-md font-semibold text-gray-800 text-left">
                {t("select_plan.faq.q1.question")}
              </h3>
              <div className="flex items-center">
                {currentFaq === 1 ? <CloseIcon /> : <OpenIcon />}
              </div>
            </div>
            {currentFaq === 1 && (
            <div className="p-3 bg-gray-100 text-left">
              <p className="text-gray-600">
                {t("select_plan.faq.q1.answer")}
              </p>
            </div>
            )}
          </div>

          <div
            className="bg-white border border-slate-100 rounded-md"
            onClick={() => setCurrentFaq(2)}
          >
            <div className="flex justify-between items-center p-3 cursor-pointer">
              <h3 className="text-md font-semibold text-gray-800 text-left">
                {t("select_plan.faq.direct_sale_q2.question")}
              </h3>
              <div className="flex items-center">
                {currentFaq === 2 ? <CloseIcon /> : <OpenIcon />}
              </div>
            </div>
            {currentFaq === 2 && (
            <div className="p-3 bg-gray-100 text-left">
              <p className="text-gray-600">
                {t("select_plan.faq.direct_sale_q2.answer")}
              </p>
            </div>
            )}
          </div>

          <div
            className="bg-white border border-slate-100 rounded-md"
            onClick={() => setCurrentFaq(3)}
          >
            <div className="flex justify-between items-center p-3 cursor-pointer">
              <h3 className="text-md font-semibold text-gray-800 text-left">
                {t("select_plan.faq.q3.question")}
              </h3>
              <div className="flex items-center">
                {currentFaq === 3 ? <CloseIcon /> : <OpenIcon />}
              </div>
            </div>
            {currentFaq === 3 && (
            <div className="p-3 bg-gray-100 text-left">
              <p className="text-gray-600">
                {t("select_plan.faq.q3.answer")}
              </p>
            </div>
            )}
          </div>

          <div
            className="bg-white border border-slate-100 rounded-md"
            onClick={() => setCurrentFaq(4)}
          >
            <div className="flex justify-between items-center p-3 cursor-pointer">
              <h3 className="text-md font-semibold text-gray-800 text-left">
                {t("select_plan.faq.q4.question")}
              </h3>
              <div className="flex items-center">
                {currentFaq === 4 ? <CloseIcon /> : <OpenIcon />}
              </div>
            </div>
            {currentFaq === 4 && (
            <div className="p-3 bg-gray-100 text-left">
              <p className="text-gray-600">
                {t("select_plan.faq.q4.answer")}
              </p>
            </div>
            )}
          </div>

          <div
            className="bg-white border border-slate-100 rounded-md"
            onClick={() => setCurrentFaq(5)}
          >
            <div className="flex justify-between items-center p-3 cursor-pointer">
              <h3 className="text-md font-semibold text-gray-800 text-left">
                {t("select_plan.faq.direct_sale_q5.question")}
              </h3>
              <div className="flex items-center">
                {currentFaq === 5 ? <CloseIcon /> : <OpenIcon />}
              </div>
            </div>
            {currentFaq === 5 && (
            <div className="p-3 bg-gray-100 text-left">
              <p className="text-gray-600">
                {t("select_plan.faq.direct_sale_q5.answer")}
              </p>
            </div>
            )}
          </div>

          <div
            className="bg-white border border-slate-100 rounded-md"
            onClick={() => setCurrentFaq(6)}
          >
            <div className="flex justify-between items-center p-3 cursor-pointer">
              <h3 className="text-md font-semibold text-gray-800 text-left">
                {t("select_plan.faq.q6.question")}
              </h3>
              <div className="flex items-center">
                {currentFaq === 6 ? <CloseIcon /> : <OpenIcon />}
              </div>
            </div>
            {currentFaq === 6 && (
            <div className="p-3 bg-gray-100 text-left">
              <p className="text-gray-600">
                {t("select_plan.faq.q6.answer")}
              </p>
            </div>
            )}
          </div>

        </div>
      </div>

      <div className="flex flex-col border border-indigo-200 rounded-xl mb-4 bg-indigo-50 border-emerald-300">
        <h2 className="mt-5 text-3xl font-bold tracking-tight text-gray-900 px-3 md:text-5xl lg:text-6xl">
          As pessoas amam o 12min App!
        </h2>

        <h3 className="mb-4 mt-5 text-md font-semibold tracking-tight text-gray-900 px-3 md:text-xl lg:text-2xl">
          Faça parte da nossa comunidade global com <span className="text-blue-600">+5 milhões de pessoas</span>
        </h3>

        <div className="flex flex-col p-3 gap-4">
          <div className="testimony flex flex-col gap-1 p-2 items-start border border-gray-200 rounded-xl text-gray-900 bg-white">
            <div className="testimony-rating">
              {iconFiveStarsOrange}
            </div>
            <div className="testimony-text text-left font-semibold text-gray-700">
              <p>Um mundo de informações em doses possíveis diárias. Este aplicativo me fornece um entendimento prévio antes de entrar nos livros, complementa meu aprendizado e fixação.</p>
            </div>
            <div className="testimony-customer text-sm text-gray-400">
              Rafão Santos
            </div>
          </div>
          
          <div className="testimony flex flex-col gap-1 p-2 items-start border border-gray-200 rounded-xl text-gray-900 bg-white">
            <div className="testimony-rating">
              {iconFiveStarsOrange}
            </div>
            <div className="testimony-text text-left font-semibold text-gray-700">
              <p>Excelente forma de começar o dia, se atualizar, ter conhecimento e trazer assuntos relevantes nas reuniões, com eficiência. Muito boas as recomendações, as uso diariamente no App! Recomendo!</p>
            </div>
            <div className="testimony-customer text-sm text-gray-400">
              Pedro Cintra
            </div>
          </div>

          <div className="testimony flex flex-col gap-1 p-2 items-start border border-gray-200 rounded-xl text-gray-900 bg-white">
            <div className="testimony-rating">
              {iconFiveStarsOrange}
            </div>
            <div className="testimony-text text-left font-semibold text-gray-700">
              <p>Muito Bom. Praticidade e otimização do meu tempo e ainda assim me manter informado com uma infinidade de livros. 👏👏👏👏👏</p>
            </div>
            <div className="testimony-customer text-sm text-gray-400">
              Bruno Lembo
            </div>
          </div>
          
          <div className="testimony flex flex-col gap-1 p-2 items-start border border-gray-200 rounded-xl text-gray-900 bg-white">
            <div className="testimony-rating">
              {iconFiveStarsOrange}
            </div>
            <div className="testimony-text text-left font-semibold text-gray-700">
              <p>Perfeito! Estou amando cada audiobook, sem contar a praticidade, muito conhecimento estou agregando a minha vida particular e profissional. Muito obrigada, gratidão!♥️</p>
            </div>
            <div className="testimony-customer text-sm text-gray-400">
              Flavirene Ferreira
            </div>
          </div>
        </div>

        <div className="flex justify-between gap-2 p-3">
          <div className="flex flex-col grow gap-1 py-4 border border-gray-200 rounded-xl text-gray-900 bg-white">
            <div className="rating text-3xl font-bold text-blue-600">
              4.8
            </div>
            <div className="rating-stars">
              {iconStarOrange}
              {iconStarOrange}
              {iconStarOrange}
              {iconStarOrange}
              {iconStarOrangeLittleEmpty}
            </div>
            <div className="rating-text text-sm text-gray-400">
              78k avaliações
            </div>
          </div>

          <div className="flex flex-col grow gap-1 py-4 border border-gray-200 rounded-xl text-gray-900 bg-white">
            <div className="rating text-3xl font-bold text-green-400">
              4.3
            </div>
            <div className="rating-stars">
              {iconStarOrange}
              {iconStarOrange}
              {iconStarOrange}
              {iconStarOrange}
              {iconStarOrangeLittleFull}
            </div>
            <div className="rating-text text-sm text-gray-400">
              64k avaliações
            </div>
          </div>
        </div>

      </div>

      <PlansComponent selectedPlan={selectedPlan} setselectedPlan={(value) => setselectedPlan(value)} buildNextPath={buildNextPath} variant={bestPrice} dark timer />

    </div>
  );
}

export default SelectPlanV2;
