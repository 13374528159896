import { useEffect } from "react";
import { useScreenContext, Option, useStepNumberManager } from "../Layout";

function Goals() {
  const [screenData, setScreenData] = useScreenContext();

  const stepNumber = useStepNumberManager(14);

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: "Continuar",
      nextPath: "age",
      progress: stepNumber,
    }));
  }, []);

  const handleOptionSelection = (option: string) => {
    setScreenData(() => ({
      ...screenData,
      userPreferences: {
        ...screenData.userPreferences,
        goal: option,
      },
    }));
  };

  return (
    <div>
      <h1 className="mb-4 text-2xl font-bold tracking-tight text-gray-900  md:text-5xl">
        Defina seu objetivo!
      </h1>
      <p className="text-md mb-6 px-7 py-4 font-light text-gray-900 lg:text-xl">
        Iremos ajudar você a desenvolver o hábito que te fará evoluir.
      </p>
      <div className="mb-20">
        <Option onClick={() => handleOptionSelection("")}>
          Quero crescer na minha carreira
        </Option>
        <Option onClick={() => handleOptionSelection("")}>
          Aprender sobre um assunto específico
        </Option>
        <Option onClick={() => handleOptionSelection("")}>
          Quero me inspirar
        </Option>
        <Option onClick={() => handleOptionSelection("")}>
          Quero ser mais produtivo
        </Option>
        <Option onClick={() => handleOptionSelection("")}>
          Poder conversar sobre qualquer assunto
        </Option>
        <Option onClick={() => handleOptionSelection("")}>Ainda não sei</Option>
      </div>
    </div>
  );
}

export default Goals;
