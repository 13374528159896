import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useScreenContext, Option, useStepNumberManager } from "../Layout";

function Age() {
  const { t } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();

  const stepNumber = useStepNumberManager(21);

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: t("layout.cta_continue"),
      nextPath: "usage",
      progress: stepNumber,
    }));
  }, []);

  const handleOptionSelection = (option: string) => {
    setScreenData(() => ({
      ...screenData,
      userPreferences: {
        ...screenData.userPreferences,
        age: option,
      },
    }));
  };

  return (
    <div>
      <h1 className="mb-4 text-2xl font-bold tracking-tight text-gray-900  md:text-5xl">
        {t("age.headline")}
      </h1>
      <div>
        <Option onClick={() => handleOptionSelection("Less than 20")}>
          {t("age.less_than_20")}
        </Option>
        <Option onClick={() => handleOptionSelection("20s")}>20s</Option>
        <Option onClick={() => handleOptionSelection("30s")}>30s</Option>
        <Option onClick={() => handleOptionSelection("40s")}>40s</Option>
        <Option onClick={() => handleOptionSelection("50s")}>50s</Option>
        <Option onClick={() => handleOptionSelection("60s")}>60s</Option>
        <Option onClick={() => handleOptionSelection("70+")}>70+</Option>
      </div>
    </div>
  );
}

export default Age;
