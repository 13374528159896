import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useSessionStorage } from "react-use";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { useTimer } from "react-timer-hook";
import { Button, CustomFlowbiteTheme, Flowbite, Modal } from "flowbite-react";

import * as rudderanalytics from "../../lib/rudderstack";
import { useScreenContext, PlanData, useStepNumberManager } from "../Layout";
import ProductComponent from "../../components/ProductComponent";
import { t } from "i18next";

function TimerBox({text, customTime}: {text?: string, customTime?: number | undefined}) {
  const time = new Date();
  const customTimeInMinutes = customTime || 15;
  const customTimeInSeconds = customTimeInMinutes * 60;
  time.setSeconds(time.getSeconds() + customTimeInSeconds);

  const { seconds, minutes, hours } = useTimer({ expiryTimestamp: time });

  return (
    <div>
      <div className="inline-block bg-red-600 p-2 font-bold text-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="inline-block h-6 w-6 pr-1"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        {hours.toString().padStart(2, "0")}:
        {minutes.toString().padStart(2, "0")}:
        {seconds.toString().padStart(2, "0")}
      </div>
      {text ? <p>{text}</p> : <p>Oferta por tempo limitado</p>}
    </div>
  );
}

const OpenIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="h-6 w-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
    />
  </svg>
);

const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="h-6 w-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.5 15.75l7.5-7.5 7.5 7.5"
    />
  </svg>
);

const converObjectToParams = (object: any) => {
  return Object.keys(object)
    .filter((key) => object[key])
    .map((key) => {
      return `${key}=${encodeURIComponent(object[key])}`;
    })
    .join("&");
};

interface PopupCheckoutProps {
  checkoutLink: any,
  modalState: [boolean, React.Dispatch<React.SetStateAction<boolean>>],
  bestPriceState: [boolean, React.Dispatch<React.SetStateAction<boolean>>],
  selectedPlan: string,
  locale: string
}

const PopupCheckout: React.FC<PopupCheckoutProps> = ({
  checkoutLink,
  modalState,
  bestPriceState,
  selectedPlan,
  locale
}) => {
  const [openModal, setOpenModal] = modalState;
  const [bestPrice, setBestPrice] = bestPriceState;
  const [showBestPrice, setShowBestPrice] = useState<boolean>(true);

  const modalTitle = bestPrice ? "Survey" : "Checkout"

  const customTheme: CustomFlowbiteTheme = {
    modal: {
      show: {
        "on": "flex bg-gray-900 bg-opacity-50 backdrop-blur dark:bg-opacity-80 h-[100%]",
        "off": "hidden"
      },
      header: {
        base: "flex flex-row-reverse items-center border-b-0 rounded-t p-2 dark:border-gray-600",
        popup: "border-b-0 p-2",
        title: "w-full text-md font-normal text-center text-gray-900 dark:text-white",
        close: {
          base: "inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white",
          icon: "h-5 w-5"
        }
      },
      body: {
        base: ""
      },
      footer: {
        base: "flex flex-col border-t-0 p-4 gap-4 bg-gray-100 rounded-b-xl",
      }
    }
  };

  const updateIframeSrc = (src: string) => {
    const iframe = document.getElementById("iframe_root") as HTMLIFrameElement;
    if (iframe) {
      iframe.setAttribute("src", src);
      iframe.onload = () => console.log("iframe loaded");
    }
  };

  useEffect(() => {
    let checkoutLinkSelected = checkoutLink[locale];
    if (selectedPlan === "monthly") {
      checkoutLinkSelected = checkoutLink.monthly;
    }
    if (bestPrice && showBestPrice) {
      checkoutLinkSelected = checkoutLink.bestPrice;
      setShowBestPrice(false);
      setOpenModal(true);
    }
    updateIframeSrc(checkoutLinkSelected);
  }, [bestPrice, selectedPlan]);

  useEffect(() => {
    if (!bestPrice) {
      updateIframeSrc(checkoutLink[locale]);
    }
  }, [locale, bestPrice, checkoutLink]);

  useEffect(() => {
    document.body.style.overflow = openModal ? "hidden" : "auto";
  }, [openModal]);

  return (
    <>
      <Flowbite theme={{ theme: customTheme }}>
        <Modal show={openModal} onClose={() => {setBestPrice(true); setOpenModal(false)}}>
          <Modal.Header>{modalTitle}</Modal.Header>
          <Modal.Body>
          <iframe
            src={checkoutLink[locale]}
            id="iframe_root"
            className="w-full h-full rounded-b-xl"
            style={{ height: "calc(100vh - 100px)" }}
          />
          </Modal.Body>
        </Modal>
      </Flowbite>
    </>
  );
};

function SelectPlan() {
  const { t, i18n } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const [showOthersPlans, setShowOthersPlans] = useState<boolean>(false);
  const [selectedPlan, setselectedPlan] = useState<string>("yearly");
  const [currentFaq, setCurrentFaq] = useState<number>(0);

  const bestPriceState = useState<boolean>(false);
  const [bestPrice, setBestPrice] = bestPriceState;
  const modalState = useState(false);
  const [showModal, setShowModal] = modalState;

  const { offerType } = useParams();
  const [flowStrategy, setFlowStrategy] = useSessionStorage(
    "flow-strategy",
    ""
  );
  const isRet = sessionStorage.getItem("utm_source") === "ret";
  const isFromTwitter = sessionStorage.getItem("utm_source") === "twitter";

  // const newPricing = useFeatureIsOn("yearly-plan-pricing-feature");
  const removeMonthly = useFeatureIsOn("remove-monthly-plan-feature");
  // const checkout12minPremium = useFeatureIsOn("checkout-12min-premium-feature");
  const exp16Checkout = useFeatureIsOn("exp-16-checkout-feature");
  const exp17feature = useFeatureIsOn("exp-17-feature");
  const exp22feature = useFeatureIsOn("exp-22-billet-feature");
  const exp29feature = useFeatureIsOn("exp-29-product");
  const exp32button = useFeatureIsOn("exp-32-button-copy");
  const exp33Product = useFeatureIsOn("exp-33-product");
  const exp35fb = useFeatureIsOn("exp-35-higher-renew-exp-fb");
  const exp39TwoOffers = useFeatureIsOn("exp-39-pt-two-offers-checkout");
  const exp44CopyTest = useFeatureIsOn("exp-44-copy-test-select-plan-real");
  const newHeadline = exp44CopyTest;
  const isExp45Microbooks = useFeatureIsOn("exp-45-copy-test-microbooks");
  const exp51TwoOffers = useFeatureIsOn("exp-51-pt-two-offers");
  const exp53ArpaYt = useFeatureIsOn("exp-53-increase-arpa-yt");
  const exp55time = useFeatureIsOn("exp-55-test-a-new-checkout-screen");
  
  
  const isPostLeadFlowStrategy = flowStrategy === "lead-post-plan-selection";
  const [variant, _] = useSessionStorage("variant");
  
  const isDirectSale = offerType === "direct";
  const isRetFunnel = offerType === "ret";
  const isLtFunnel = offerType === "lt" || offerType === "ltf";
  const isYtFunnel = offerType === "yt";
  const isTwFunnel = offerType === "tw";
  const isAppFunnel = offerType === "app" || offerType === "appf";
  
  const noBillet = selectedPlan === "monthly"; // isYtFunnel || isDirectSale;
  const newPricing = (exp53ArpaYt && isYtFunnel);
  const isWithInterestVariant = variant === "with-interest" && isDirectSale;

  const activePlan = (isDirectSale || isRetFunnel) && selectedPlan === "yearly";

  const esPopup = screenData.co === "co" || screenData.co === "mx" || screenData.co === "pe";
  const enCheckout = i18n.language === "en" && isDirectSale;

  type Funnel = "direct" | "ret" | "lt" | "ltf" | "yt" | "tw";

  const i18nPrefix = {
    direct: "direct_sale_",
    ret: "ret_sale_",
    lt: "lifetime_sale_",
    ltf: "lifetime_sale_",
    yt: "youtube_sale_",
    tw: "twitter_sale_",
    app: "app_sale_",
  } as Record<Funnel, string>;

  const transPrefix = i18nPrefix[offerType as Funnel] || "";

  const checkoutIdListBR: Record<string, string> = {
    direct: exp39TwoOffers ? "P5327674P" : "E91856682Y",
    ret: "E91856682Y",
    lt: "L49428651O",
    ltf: "L49428651O",
    yt: "E91856682Y",
    tw: "E91856682Y",
    trial: "P5327674P",
  };

  const checkoutIdListMonthlyBR: Record<string, string> = {
    direct: "P5327674P",
    ret: "P5327674P",
    lt: "L49428651O",
    ltf: "L49428651O",
    yt: "P5327674P",
    tw: "P5327674P",
    trial: "P5327674P",
  };

  // activePlan ? "E91856682Y" : 
  //       isLtFunnel ? "L49428651O" : "P5327674P",
  const checkoutId: any = {
    br: selectedPlan === "yearly" && checkoutIdListBR[offerType || ""] || checkoutIdListBR.trial,
    en: "K16542811H",
    es: activePlan ? "Y92363487F" : "Y16613490E",
  }[i18n.language];

  const offerCodeList: Record<string, string> = {
    // twitter: "xrnblfqt",
    default: "7d18zm3s",
    twitter: "ed49wf1p",
    youtube: "n29njbks",
    direct: exp55time ? "xrnblfqt" : "n29njbks",
    // default: "ed49wf1p",
    // exp16: "6reyasj1",
    // exp17: "6reyasj1",
  };

  const offerCodeDirect: () => string = () => {
    if (isFromTwitter) return offerCodeList.twitter;
    if (isDirectSale) return offerCodeList.direct;
    return offerCodeList.default;
  };

  const discount = isRetFunnel  
    ? "promo75off" 
    : (newPricing 
        ? "PROMO70OFFMIDYEAR" 
        : "promo63off"
      );

  const discountTrial = (): string => {
    if (i18n.language === "en") return "30OFF7D";
    if (i18n.language === "es" && screenData.co === null) return "30OFF7D_es";
    return "70OFF7D";
  }

  const discountDirect = (): string => {
    if (isRetFunnel) return "promo75off";
    // if (newPricing) return "PROMO70OFFMIDYEAR";
    if (i18n.language === "es" && screenData.co === "sp") return "PROMO75OFFFBSP";
    if (i18n.language === "es" && isDirectSale) return "promo30off_es";
    if (i18n.language === "en") return "promo30off";
    if (isDirectSale) return exp55time ? "promo40off-top" : "promo40off";
    return "promo63off";
  }

  const offersTrial: any = {
    discounts: {
      base: discountTrial(), // "63OFF7D",
    },
    P5327674P: { // BR
      yearly: { offerCode: "8v2jt9vj" }, // 2zki1nl0
      monthly: { offerCode: "jx5nh72y" },
    },
    K16542811H: { // EN
      yearly: { offerCode: "z2y60k16" }, // z2y60k16
      monthly: { offerCode: "fys1w0n7" },
    },
    Y92363487F: { // ES
      yearly: { offerCode: "bp82ssup" }, // vng1dkxz
      monthly: { offerCode: "7lcaoc5b" },
    },
    Y16613490E: {
      yearly: { offerCode: "20ouyi5g" }, // bp82ssup / vng1dkxz
      monthly: { offerCode: "eptks9rt" },
    },
  };

  type Co = "co" | "mx" | "pe" | "ve" | "py" | "ar" | "hn" | "cl" | "sp";

  const offersDirectSale: any = {
    discounts: {
      base: discountDirect(),
    },
    P5327674P: {
      yearly: {
        offerCode: exp39TwoOffers ? "8ha7xsa1" : "suxzbi7s" 
      },
      monthly: { offerCode: "jx5nh72y" },
    },
    E91856682Y: {
      yearly: { offerCode: offerCodeDirect() },
      monthly: { offerCode: "kppi78af" },
    },
    K16542811H: {
      yearly: { offerCode: "lixyz1as" },
      monthly: { offerCode: "b3i96d7z" },
    },
    Y16613490E: {
      yearly: { offerCode: "oqvwtnza" },
      monthly: { offerCode: "eptks9rt" },
    },
    Y92363487F: {
      yearly: { offerCode: "56n11qjw" }, // 98fq4cvr
      monthly: { offerCode: "prlt2kyv" },

      co: {
        yearly: { offerCode: "2earfcgt" },
        monthly: { offerCode: "an79xa2b" },
      },
      mx: {
        yearly: { offerCode: "5aydlce2" },
        monthly: { offerCode: "eptks9rt" },
      },
      pe: {
        yearly: { offerCode: "f2q21x2l" },
        monthly: { offerCode: "kpzyvt39" },
      },
      ar: {
        yearly: { offerCode: "pk09dmu5" },
        monthly: { offerCode: "v4kfjdja" },
      },
      cl: {
        yearly: { offerCode: "ck7gszd7" },
        monthly: { offerCode: "8jltpjdm" },
      },
      sp: {
        yearly: { offerCode: "qmmef3nt" },
        monthly: { offerCode: "kczk5szf" },
      },
    },
  };

  const offersRetSale: any = {
    discounts: {
      base: "retpromo60off",
    },
    E91856682Y: {
      yearly: { offerCode: "n29njbks" },
      monthly: { offerCode: "kppi78af" },
    },
    P5327674P: {
      yearly: { offerCode: "2zki1nl0" },
      monthly: { offerCode: "jx5nh72y" },
    },
  };

  const offersLifetime: any = {
    discounts: {
      base: "",
    },
    L49428651O: {
      yearly: { offerCode: "atfd8gmh" },
    },
  };

  const offersYtSale: any = {
    discounts: {
      base: "promo20off",
    },
    E91856682Y: {
      yearly: { offerCode: "ho87a6b2" },
      monthly: { offerCode: "kppi78af" },
    },
    P5327674P: {
      yearly: { offerCode: "2zki1nl0" },
      monthly: { offerCode: "fbixgxyd" },
    },
  };

  const offersTwSale: any = {
    discounts: {
      base: "promo30off",
    },
    E91856682Y: {
      yearly: { offerCode: "asqclss0" }, // 882h5zlc
      monthly: { offerCode: "rjkuz3j9" },
    },
    P5327674P: {
      yearly: { offerCode: "2zki1nl0" },
      monthly: { offerCode: "rjkuz3j9" },
    },
  };

  const offersOptions: any = () => {
    if (isDirectSale) return offersDirectSale;
    if (isRetFunnel) return offersRetSale;
    if (isLtFunnel) return offersLifetime;
    if (isYtFunnel) return offersYtSale;
    if (isTwFunnel) return offersTwSale;
    return offersTrial;
  };

  const offers: any = offersOptions();
  const currentCheckout = offers[checkoutId];

  interface CheckoutPopupLinks {
    co: string;
    mx: string;
    pe: string;
    bestPrice: string;
    monthly: string;
  }

  /**
   * Generates the Hotmart checkout popup link with the provided parameters.
   * @param id - The ID of the product.
   * @param off - The off parameter value.
   * @param offDiscount - The offDiscount parameter value.
   * @returns The generated checkout popup link.
   */
  const checkoutPopupLink = (id: string, off: string, offDiscount: string) => {
    const baseUrl = `https://pay.hotmart.com/${id}`;
    const urlParams = !isPostLeadFlowStrategy ? screenData.urlParams : {};

    return (
      baseUrl +
      "?" +
      converObjectToParams({
        ...urlParams,
        off: off,
        offDiscount: offDiscount,
        sck: rudderanalytics.getAnonymousId(),
        checkoutMode: "10",
        hidePix: "0",
        hideBillet: "0"
      })
    );
  };

  const checkoutPopupLinks: CheckoutPopupLinks = {
    co: checkoutPopupLink("Y92363487F", "44sdfdbq", "promo63off"),
    mx: checkoutPopupLink("Y92363487F", "5aydlce2", "PROMO75OFFFBMX"),
    pe: checkoutPopupLink("Y92363487F", "qmj3p7r2", "promo63off"),
    bestPrice: "https://form.typeform.com/to/EtDd1wOm#email=" + screenData.urlParams.email,
    monthly: checkoutPopupLink("Y16613490E", "eptks9rt", "")
  }

  const checkoutStripePopupLink = (id: string, off: string, offDiscount: string) => {
    const baseUrl = `https://pay.hotmart.com/${id}`;
    const urlParams = !isPostLeadFlowStrategy ? screenData.urlParams : {};

    return (
      baseUrl +
      "?" +
      converObjectToParams({
        ...urlParams,
        off: off,
        offDiscount: offDiscount,
        sck: rudderanalytics.getAnonymousId(),
        checkoutMode: "10",
        hidePix: "0",
        hideBillet: "0"
      })
    );
  };

  const checkoutStripePopupLinks: CheckoutPopupLinks = {
    co: checkoutPopupLink("Y92363487F", "44sdfdbq", "promo63off"),
    mx: checkoutPopupLink("Y92363487F", "5aydlce2", "PROMO75OFFFBMX"),
    pe: checkoutPopupLink("Y92363487F", "qmj3p7r2", "promo63off"),
    bestPrice: "https://form.typeform.com/to/EtDd1wOm#email=" + screenData.urlParams.email,
    monthly: checkoutPopupLink("Y16613490E", "eptks9rt", "")
  }

  /**
   * Retrieves the price list for a given country.
   * @param country - The country code.
   * @returns The price list object for the specified country.
   */
  const priceListByCountry = (country: string) => {
    const prices: {
      [key: string]: {
        fullPrice: string;
        discountedPrice: string;
        installmentPrice: string; 
        monthlyPrice: string;
        saveOffer?: string;
      }
    } = {
      co: {
        fullPrice: "310.329 COP",
        discountedPrice: "114.821 COP",
        installmentPrice: "9.568 COP",
        monthlyPrice: "32.424 COP"
      },
      mx: {
        fullPrice: "1485 MXN",
        discountedPrice: "371 MXN",
        installmentPrice: "",
        monthlyPrice: "156 MXN",
        saveOffer: "-75% OFF"
      },
      pe: {
        fullPrice: "300 PEN",
        discountedPrice: "111 PEN",
        installmentPrice: "9,33 PEN",
        monthlyPrice: "32 PEN"
      },
      ve: {
        fullPrice: "77 US$",
        discountedPrice: "29 US$",
        installmentPrice: "",
        monthlyPrice: "9 US$"
      },
      py: {
        fullPrice: "589.085 PYG",
        discountedPrice: "217.964 PYG",
        installmentPrice: "",
        monthlyPrice: "61.374 PYG"
      },
      ar: {
        fullPrice: "71.869 ARS",
        discountedPrice: "26.592 ARS",
        installmentPrice: "",
        monthlyPrice: "7.500 ARS"
      },
      hn: {
        fullPrice: "1967 HNL",
        discountedPrice: "729 HNL",
        installmentPrice: "",
        monthlyPrice: "205 HNL"
      },
      cl: {
        fullPrice: "90.466 CLP",
        discountedPrice: "33.472 CLP",
        installmentPrice: "2.789 CLP",
        monthlyPrice: "9.450 CLP"
      },
      sp: {
        fullPrice: "70 €",
        discountedPrice: "17,50 €",
        installmentPrice: "",
        monthlyPrice: "12 €",
        saveOffer: "-75% OFF"
      }
    };

    return prices[country] || undefined;
  };

  const buildCheckoutLink = () => {
    const baseUrl = () => {
      if (enCheckout && selectedPlan === "yearly") return "https://checkout-wizard.12min.com/b/9AQ01f2LL9yN7iU005";
      if (enCheckout && selectedPlan === "monthly") return "https://checkout-wizard.12min.com/b/eVabJXfyxfXbdHieV0";
      return `https://pay.hotmart.com/${checkoutId}`;
    }
    const urlParams = !isPostLeadFlowStrategy ? screenData.urlParams : {};

    function discount (offDiscount: string | null) {
      if (selectedPlan === "yearly") {
        return offDiscount ? offDiscount : offers.discounts.base;
      }

      return false;
    }

    if (enCheckout) {
      if (selectedPlan === "yearly") {
        return baseUrl() + "?" + converObjectToParams({
          ...urlParams,
          prefilled_promo_code: "promo30off"
        });
      }

      return baseUrl() + "?" + converObjectToParams(urlParams);
    }

    return (
      baseUrl() +
      "?" +
      converObjectToParams({
        ...urlParams,
        off: screenData.co as Co ? currentCheckout[screenData.co][selectedPlan].offerCode : currentCheckout[selectedPlan].offerCode,
        offDiscount: discount(screenData.offDiscount),
        sck: rudderanalytics.getAnonymousId(),
        checkoutMode: "10",
        hidePix: "0",
        hideBillet: noBillet ? "1" : "0"
      })
    );
  };

  const buildNextPath = () => {
    const checkoutLink = buildCheckoutLink();
    return !isPostLeadFlowStrategy
      ? checkoutLink
      : "form?redirectUrl=" + encodeURIComponent(checkoutLink);
  };

  const stepNumber = useStepNumberManager(24);

  useEffect(() => {
    const continueBtnCaption = (exp32button || exp33Product) ? "Desbloqueie +3000 títulos" : t("layout.cta_continue");
    setScreenData(() => ({
      ...screenData,
      submitLabel: (!esPopup || !enCheckout) && continueBtnCaption,
      disabledButton: false,
      nextPath: (!esPopup || !enCheckout) && buildNextPath(),
      progress: stepNumber,
      step: stepNumber,
      bestPrice: bestPrice
    }));
  }, [selectedPlan, bestPrice, exp32button]);

  return (
    <>
      { esPopup 
        && <PopupCheckout
          checkoutLink={checkoutPopupLinks}
          modalState={modalState}
          bestPriceState={bestPriceState}
          selectedPlan={selectedPlan}
          locale={screenData.co}
        />
      }
      <div>

        <h1 className="mb-4 mt-5 text-3xl font-bold tracking-tight text-gray-900  md:text-5xl lg:text-6xl">
          {newHeadline
            ? ("Acesse as principais ideias de 2500+ bestsellers")
            : t("select_plan.headline-transformation")}
        </h1>

        <p>{newHeadline ?
          <>Receba recomendações personalizadas com base nos seus objetivos e aprenda algo novo todos os dias.</> : 
          t("select_plan.subheadline-transformation")}
        </p>

        <p className="pt-5">
          {isLtFunnel ? 
            <TimerBox text={"Oferta por tempo limitado: 12min Vitalício com um desconto exclusivo de 93%"} /> : 
            exp55time ? <TimerBox text={t("select_plan.limited_time")} customTime={4} />
                          : <TimerBox text={t("select_plan.limited_time")} />
          }
        </p>
        <div>
          <div
            onClick={() => setselectedPlan("yearly")}
            className={
              "mt-10 cursor-pointer border-4 text-gray-900 " +
              (selectedPlan === "yearly"
                ? "border-green-400"
                : "border-gray-400")
            }
          >
            <div className="flex bg-gray-100 p-4 py-6 text-left">
              <div className="pr-4">
                <div className="mt-1.5 rounded-full border-2 border-gray-900 bg-white p-0.5">
                  <div
                    className={
                      "h-2 w-2 rounded-full  bg-gray-900 " +
                      (selectedPlan === "yearly" ? "bg-gray-900" : "bg-white")
                    }
                  ></div>
                </div>
              </div>
              <div>
                <div className="-ml-11 -mt-10 mb-2 w-28  bg-red-600 py-1 text-center text-xs text-white">
                  {(screenData.co && priceListByCountry(screenData.co)?.saveOffer) ? (
                      priceListByCountry(screenData.co).saveOffer
                    ) :
                    t(
                    `select_plan.yearly.${transPrefix}save_offer${newPricing ? "_variant" : ""}`
                  )}
                </div>
                <strong className="mt-5 block text-sm">
                  {t(
                    `select_plan.yearly.${transPrefix}plan_name${
                      newPricing ? "_variant" : ""
                    }`
                  )}
                </strong>
                <div className="py-2">
                  <strong className="block text-xs font-normal text-red-600 line-through">
                    {(screenData.co && priceListByCountry(screenData.co)) ? (
                      priceListByCountry(screenData.co).fullPrice
                    ) : (
                      t(
                        `select_plan.yearly.${transPrefix}plan_price_per_month_without_discount${
                          newPricing ? "_variant" : ""
                        }`
                      )
                    )}
                  </strong>

                  {bestPrice && (
                    <strong className="block text-sm font-bold text-red-600 line-through">
                    {}
                    </strong>
                  )}

                  <b className="block">
                    {(screenData.co && priceListByCountry(screenData.co)) ? (
                      priceListByCountry(screenData.co).discountedPrice
                    ) : (
                      t(
                        `select_plan.yearly.${transPrefix}plan_price_per_month${newPricing ? "_variant" : ""}`
                      )
                    )}
                  </b>
                </div>
                <b className="block text-sm text-blue-700">
                  {t(
                    `select_plan.yearly.${transPrefix}free_trial`
                  )}
                </b>
              </div>
            </div>
            <div className="ml-8 p-4 text-left">
              <b className="text-sm">
                {t(
                  `select_plan.yearly.${transPrefix}how_trial_works`
                )}
                :
              </b>
              <div className="mt-2 text-left text-sm">
                <div className="mb-3">
                  <b className="text-blue-700">
                    {t("book_list.trial_box.today.headline")}
                  </b>
                  <p className="font-light">
                    {t(
                      `book_list.trial_box.today.${
                        (isDirectSale || isRetFunnel || isLtFunnel || isTwFunnel || isYtFunnel) ? "direct_sale_" : ""
                      }text`
                    )}
                  </p>
                </div>
                <div className="mb-3">
                  <b className="text-blue-700">
                    {t("book_list.trial_box.day5.headline")}
                  </b>
                  <p className="font-light">
                    {t(
                      `book_list.trial_box.day5.${
                        (isDirectSale || isRetFunnel || isLtFunnel || isTwFunnel || isYtFunnel) ? "direct_sale_" : ""
                      }text`
                    )}
                  </p>
                </div>
                <div className="mb-3">
                  <b className="text-blue-700">
                    {t("book_list.trial_box.day7.headline")}
                  </b>
                  <p className="font-light">
                    {t("book_list.trial_box.day7.text")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {(!removeMonthly && !isLtFunnel) && (
            <div
              className="py-3 text-sm text-blue-600"
              onClick={() => setShowOthersPlans(!showOthersPlans)}
            >
              <span className="cursor-pointer">
                {t("select_plan.show_others_plans")}
              </span>
            </div>
          )}

          {showOthersPlans && (
            <div
              onClick={() => setselectedPlan("monthly")}
              className={
                "cursor-pointer border-4  text-gray-900 " +
                (selectedPlan === "monthly"
                  ? "border-green-400"
                  : "border-gray-400")
              }
            >
              <div className="flex bg-gray-100 p-4 py-6 text-left">
                <div className="pr-4">
                  <div className="mt-0.5 rounded-full border-2 border-gray-900 bg-white p-0.5">
                    <div
                      className={
                        "h-2 w-2 rounded-full  bg-gray-900 " +
                        (selectedPlan === "monthly"
                          ? "bg-gray-900"
                          : "bg-white")
                      }
                    ></div>
                  </div>
                </div>
                <div>
                  <b className="block text-sm">
                    {t("select_plan.monthly.plan_name")}
                  </b>
                  <div className="py-2">
                    <b className="block">
                      {(screenData.co && priceListByCountry(screenData.co)) ? (
                        priceListByCountry(screenData.co).monthlyPrice
                      ) : (
                        t(
                          `select_plan.monthly.${transPrefix}plan_price_per_month${
                            isWithInterestVariant ? "_with_interest" : ""
                          }`
                        )
                      )}
                    </b>
                  </div>
                  <b className="block text-sm text-blue-700">
                    {t(
                      `select_plan.monthly.${
                        (isDirectSale || isRetFunnel) ? "direct_sale_" : ""
                      }no_trial`
                    )}
                  </b>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {variant === "select-plan-green-box" && (
        <div>
          <div className="my-5 rounded bg-green-200 px-5 pt-1 pb-4">
            <h2 className="mb-4 mt-5 text-xl font-bold tracking-tight text-gray-900  md:text-2xl lg:text-3xl">
              {t("select_plan.green_box.headline")}
            </h2>
            <div className="text-left">
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="mb-1 mr-1 inline-block h-5 w-5 text-green-700"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                  />
                </svg>
                {
                  isExp45Microbooks ? 
                  t("select_plan.green_box.item1_variant") :
                  t("select_plan.green_box.item1")
                }
              </p>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="mb-1 mr-1 inline-block h-5 w-5 text-green-700"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                  />
                </svg>
                {t("select_plan.green_box.item2")}
              </p>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="mb-1 mr-1 inline-block h-5 w-5 text-green-700"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                  />
                </svg>
                {t("select_plan.green_box.item3")}
              </p>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="mb-1 mr-1 inline-block h-5 w-5 text-green-700"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                  />
                </svg>
                {t("select_plan.green_box.item4")}
              </p>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="mb-1 mr-1 inline-block h-5 w-5 text-green-700"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                  />
                </svg>
                {
                  isExp45Microbooks ?
                  t("select_plan.green_box.item5_variant") :
                  t("select_plan.green_box.item5")
                }
              </p>
            </div>
          </div>
        </div>
      )}

      {exp33Product && (
        <>
          <h2 className="mb-4 mt-5 text-2xl font-bold tracking-tight text-gray-900  md:text-3xl lg:text-4xl">
          O que você ganha?
          </h2>
          <h3>+3.000 títulos disponíveis em áudio e texto</h3>
          
          <ProductComponent />
        </>
      )}

      {/* FAQ */}
      <div>
        <h2 className="mb-4 mt-5 text-2xl font-bold tracking-tight text-gray-900  md:text-3xl lg:text-4xl">
          {t("select_plan.faq.headline")}
        </h2>

        <div className="text-left">
          <div className="border-b border-gray-500">
            <div
              className="flex cursor-pointer"
              onClick={() => setCurrentFaq(1)}
            >
              <div className="mt-3 pr-3">
                {currentFaq === 1 ? <CloseIcon /> : <OpenIcon />}
              </div>
              <div>
                <h3 className="py-3 font-bold">
                  {t("select_plan.faq.q1.question")}
                </h3>
              </div>
            </div>
            {currentFaq === 1 && (
              <div className="pb-3">
                <p className="text-sm font-light text-gray-700 ">
                  {t("select_plan.faq.q1.answer")}
                </p>
              </div>
            )}
          </div>

          <div className="border-b border-gray-500">
            <div
              className="flex cursor-pointer"
              onClick={() => setCurrentFaq(2)}
            >
              <div className="mt-3 pr-3">
                {currentFaq === 2 ? <CloseIcon /> : <OpenIcon />}
              </div>
              <div>
                <h3 className="py-3 font-bold">
                  {t(
                    `select_plan.faq.${
                      (isDirectSale || isRetFunnel || isLtFunnel || isTwFunnel || isYtFunnel) ? "direct_sale_" : ""
                    }q2.question`
                  )}
                </h3>
              </div>
            </div>
            {currentFaq === 2 && (
              <div className="pb-3">
                <p className="text-sm font-light text-gray-700 ">
                  {t(
                    `select_plan.faq.${
                      (isDirectSale || isRetFunnel || isLtFunnel || isTwFunnel || isYtFunnel) ? "direct_sale_" : ""
                    }q2.answer`
                  )}
                </p>
              </div>
            )}
          </div>
          
          {!isLtFunnel && (
          <div className="border-b border-gray-500">
            <div
              className="flex cursor-pointer"
              onClick={() => setCurrentFaq(3)}
            >
              <div className="mt-3 pr-3">
                {currentFaq === 3 ? <CloseIcon /> : <OpenIcon />}
              </div>
              <div>
                <h3 className="py-3 font-bold">
                  {t("select_plan.faq.q3.question")}
                </h3>
              </div>
            </div>
            {currentFaq === 3 && (
              <div className="pb-3">
                <p className="text-sm font-light text-gray-700 ">
                  {t("select_plan.faq.q3.answer")}
                </p>
              </div>
            )}
          </div>
          )}

          <div className="border-b border-gray-500">
            <div
              className="flex cursor-pointer"
              onClick={() => setCurrentFaq(4)}
            >
              <div className="mt-3 pr-3">
                {currentFaq === 4 ? <CloseIcon /> : <OpenIcon />}
              </div>
              <div>
                <h3 className="py-3 font-bold">
                  {t(
                    `select_plan.faq.${
                      (isLtFunnel) ? "lifetime_" : ""
                    }q4.question`
                  )}
                </h3>
              </div>
            </div>
            {currentFaq === 4 && (
              <div className="pb-3">
                <p className="text-sm font-light text-gray-700 ">
                  {t("select_plan.faq.q4.answer")}
                </p>
              </div>
            )}
          </div>

          <div className="border-b border-gray-500">
            <div
              className="flex cursor-pointer"
              onClick={() => setCurrentFaq(5)}
            >
              <div className="mt-3 pr-3">
                {currentFaq === 5 ? <CloseIcon /> : <OpenIcon />}
              </div>
              <div>
                <h3 className="py-3 font-bold">
                  {t(
                    `select_plan.faq.${
                      (isDirectSale || isRetFunnel || isTwFunnel || isYtFunnel) 
                        ? "direct_sale_" 
                        : isLtFunnel ? "lifetime_" : ""
                    }q5.question`
                  )}
                </h3>
              </div>
            </div>
            {currentFaq === 5 && (
              <div className="pb-3">
                <p className="text-sm font-light text-gray-700 ">
                  {t(
                    `select_plan.faq.${
                      (isDirectSale || isRetFunnel || isTwFunnel || isYtFunnel) 
                        ? "direct_sale_" 
                        : isLtFunnel ? "lifetime_" : ""
                    }q5.answer`
                  )}
                </p>
              </div>
            )}
          </div>

          <div className="border-gray-500 pb-20">
            <div
              className="flex cursor-pointer"
              onClick={() => setCurrentFaq(6)}
            >
              <div className="mt-3 pr-3">
                {currentFaq === 6 ? <CloseIcon /> : <OpenIcon />}
              </div>
              <div>
                <h3 className="py-3 font-bold">
                  {t("select_plan.faq.q6.question")}
                </h3>
              </div>
            </div>
            {currentFaq === 6 && (
              <div className="pb-3">
                <p className="text-sm font-light text-gray-700 ">
                  {t("select_plan.faq.q6.answer")}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      {esPopup && (
        <div className="fixed inset-x-0 bottom-0 mx-auto max-w-screen-sm bg-gradient-to-t from-white via-white">
          <div className="mx-4">
            <button
              onClick={() => setShowModal(true)}
              className="hotmart-fb flex justify-center text-center text-md my-6 w-full rounded-md bg-blue-700 px-5 py-2.5 font-normal text-white hover:bg-blue-800 focus:ring-blue-300"
            >Continuar</button>
          </div>
        </div>
      )}

      {enCheckout && (
        <div className="fixed inset-x-0 bottom-0 mx-auto max-w-screen-sm bg-gradient-to-t from-white via-white">
          <div className="mx-4">
            <button
              onClick={() => setShowModal(true)}
              className="hotmart-fb flex justify-center text-center text-md my-6 w-full rounded-md bg-blue-700 px-5 py-2.5 font-normal text-white hover:bg-blue-800 focus:ring-blue-300"
            >Continue</button>
          </div>
        </div>
      )}
    </>
  );
}

export default SelectPlan;
