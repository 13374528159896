import { useEffect } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { useScreenContext } from "../../Layout";
import resultInage from "../../../assets/images/result.png";
import { useTranslation, Trans } from "react-i18next";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { useParams } from "react-router-dom";

function LoadingResult() {
  const { t } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const { offerType } = useParams();

  type StepType = {
    [key: string]: number;
  };

  const step: StepType = {
    sl: 15,
    default: 15,
  };

  function getStep(funnel: string) {
    return step[funnel] || step.default;
  }

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: t("layout.cta_continue"),
      nextPath: "about-sl",
      progress: getStep(offerType ?? ""),
      step: getStep(offerType ?? ""),
    }));
  }, [screenData]);

  return (
    <main className="flex flex-col h-full justify-between gap-10">
      <header>
        <ConfettiExplosion />
        <h1 className="mb-4 mt-5 text-3xl font-semibold leading-tight tracking-normal text-gray-900 md:text-4xl">
          <Trans i18nKey="result_sl.headline">
            O curso de leitura <strong className="font-bold text-blue-600">Super Leitura</strong> é a solução para você!
          </Trans>
        </h1>
        <p className="font-light">{t("result_sl.subheadline")}</p>
        <div className="px-10 py-5 ">
        </div>
        <h2 className="text-left text-xl font-normal leading-tight tracking-normal text-gray-900 mb-1 md:text-1xl">
          {t("result_sl.list.title")}
        </h2>
        <ul className="list-disc list-inside text-left font-light">
          <li>{t("result_sl.list.item_1")}</li>
          <li>{t("result_sl.list.item_2")}</li>
        </ul>
      </header>
      <img
        className="mx-auto w-full max-w-sm px-10"
        alt="Chart showing your progress"
        src={resultInage}
      />
    </main>
  );
}

export default LoadingResult;
