import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLoaderData, useParams, useNavigate } from "react-router-dom";
import { useScreenContext, useStepNumberManager } from "../Layout";
import * as rudderanalytics from "../../lib/rudderstack";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

function BookSuggestion() {
  type BookParams = {
    bookId: string;
    offerType: string;
  };
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const { bookRecommendations } = screenData;
  const [voted, setVoted] = useState(false);

  const { bookId, offerType } = useParams<BookParams>() as BookParams;
  const nextBook = Number(bookId) + 1;
  const nextPath =
    Number(bookId) < 5
      ? `/${offerType ? offerType + "/" : ""}book-suggestion/${nextBook}`
      : `/${offerType ? offerType + "/" : ""}info`;

  // function to verify if the nextPath have /book-suggestion/:bookId/book-suggestion/:bookId instead of /book-suggestion/:bookId and fix it
  const fixNextPath = (nextPath: string) => {
    const path = nextPath.split("/");
    if (path.includes("book-suggestion")) {
      const index = path.indexOf("book-suggestion");
      path.splice(index, 3);
      return path.join("/");
    }
    return nextPath;
  };

  // console.log("nextPath", nextPath);
  // console.log("fixNextPath", fixNextPath(nextPath));

  // console.log("bookRecommendations", bookRecommendations);
  if (!bookRecommendations) {
    // redirect to the start page if there are no book recommendations
    const offerPath = offerType ? offerType + "/" : "";
    window.location.href = "/" + offerPath;
  }
  const book = bookRecommendations[Number(bookId) - 1];
  // console.log("book", book);

  const stepNumber = useStepNumberManager(16);

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: '',//t("layout.cta_continue"),
      nextPath: nextPath,
      progress: stepNumber,
      step: stepNumber,
      votting: true,
    }));
  }, [nextPath]);

  const handleUnlike = (event: { stopPropagation: () => void; }) => {
    event.stopPropagation();
    rudderanalytics.unlikeBook(book.id);

    setScreenData(() => ({
      ...screenData,
      votting: true,
      booksUnliked: [...screenData.booksUnliked, book.id],
    }));
  };

  const handleNextPage = (event: any) => {
    event.preventDefault();
    navigate(nextPath);
  }

  return (
    <div>
      <h1 className="mx-5 mb-4 mt-5 text-2xl font-bold tracking-tight text-gray-900  md:text-4xl">
        {t("book_suggestion.headline")}
      </h1>

      <div
        style={{
          backgroundImage: `url(${book.coverImage})`,
          backgroundSize: "cover",
        }}
        className="mx-auto h-80 w-60 max-w-sm md:mt-20"
      >
        <div className="flex h-full flex-col bg-gradient-to-t from-black p-5  text-left text-white">
          <b className="mt-auto block text-3xl">{book.title}</b>
          <span className=" mt-automt-3">{book.author}</span>
        </div>
      </div>

      <div>
        <button
          type="button"
          className="my-6 mr-2 -scale-x-100 transform rounded-md bg-blue-500 px-10 py-1 text-xl font-normal text-white "
          onClick={(event) => {
            handleUnlike(event);
            handleNextPage(event);
          }}
        >
          👎
        </button>
        <button
          type="button"
          className="my-6 rounded-md bg-blue-500 px-10 py-1 text-xl font-normal text-white  "
          onClick={(event) => {
            rudderanalytics.likeBook(book.id);
            handleNextPage(event);
          }}
        >
          👍
        </button>
      </div>
    </div>
  );
}

export default BookSuggestion;
