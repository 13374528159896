import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { relativePath, useScreenContext, useStepNumberManager } from "../Layout";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { useParams } from "react-router-dom";

function TalkingAbout2() {
  const { t, i18n } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const { offerType } = useParams();

  const mentionsLogos = {
    techcrunch: require("../../assets/images/mentions/tech-crunch.png"),
    forbes: require("../../assets/images/mentions/forbes.png"),
    muo: require("../../assets/images/mentions/muo.png"),
    entrepreneur: require("../../assets/images/mentions/entrepreneur.png"),
    yahoo: require("../../assets/images/mentions/yahoo.png"),
    hackernoon: require("../../assets/images/mentions/hackernoon.png"),
    techtudo: require("../../assets/images/mentions/techtudo.png"),
    exame: require("../../assets/images/mentions/exame.png"),
  };

  const stepNumber = useStepNumberManager(2);

  useEffect(() => {

    setScreenData(() => ({
      ...screenData,
      submitLabel: t("layout.cta_continue"),
      nextPath: relativePath("/gender", offerType),
      progress: stepNumber,
      step: stepNumber,
    }));
  }, []);

  return (
    <div className="mb-[6rem]">
      <h1 className="text-center mb-16 font-bold text-2xl">
        <span className="text-blue-600">+5 milhões de pessoas</span>
        <br/>
        estão usando o 12min App
      </h1>

      <div className="relative bg-blue-100 rounded-md px-4 py-8 mb-10">
        <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="absolute left-0 -top-8 w-16">
          <path fillRule="evenodd" clipRule="evenodd" d="M10.005 4.907c-3.707 1.027-6.12 3.173-6.72 6.907-.163 1.016.065.249.755-.267 2.254-1.683 6.686-1.087 7.3 2.757 1.238 6.807-7.29 7.985-9.814 4.226A6.912 6.912 0 01.41 15.516c-.608-4.871 1.894-9.21 6.288-11.248.813-.377 1.67-.672 2.592-.889.462-.093.797.158.952.582.131.362.176.761-.237.946zm12.217 0c-3.707 1.027-6.12 3.173-6.72 6.907-.163 1.016.066.249.756-.267 2.254-1.683 6.685-1.087 7.3 2.757 1.238 6.807-7.29 7.985-9.814 4.226a6.913 6.913 0 01-1.116-3.014c-.608-4.871 1.893-9.21 6.288-11.248.813-.377 1.67-.672 2.592-.889.462-.093.797.158.952.582.13.362.175.761-.238.946z" fill="#03314B"></path>
        </svg>
        <p className="text-midnight text-h35 italic font-tisa-pro mb-4">
          Microbooks como os da biblioteca do 12min são uma dádiva total.
        </p>
        <picture>
          <source srcSet={mentionsLogos.forbes} type="image/webp"/>
          <img src={mentionsLogos.forbes} alt="Forbes" className="mx-auto w-40 opacity-60"/>
        </picture>
      </div>

      <div className="max-w-[343px] flex flex-col justify-center align-center text-center m-auto">
        <span className="text-sm text-mid-grey mb-4 uppercase">DESTAQUE EM</span>
        <div className="flex flex-row flex-wrap justify-center items-center gap-6">
          <picture>
            <source srcSet={mentionsLogos.techcrunch} type="image/webp"/>
            <img src={mentionsLogos.techcrunch} alt="TechCrunch" className="max-w-[9rem]" loading="lazy"/>
          </picture>
          <picture>
            <source srcSet={mentionsLogos.muo} type="image/webp"/>
            <img src={mentionsLogos.muo} alt="Make Use Of" className="max-w-[9rem]" loading="lazy"/>
          </picture>
          <picture>
            <source srcSet={mentionsLogos.entrepreneur} type="image/webp"/>
            <img src={mentionsLogos.entrepreneur} alt="Entrepreneur" className="max-w-[9rem]" loading="lazy"/>
          </picture>
          <picture>
            <source srcSet={mentionsLogos.yahoo} type="image/webp"/>
            <img src={mentionsLogos.yahoo} alt="Yahoo" className="max-w-[9rem]" loading="lazy"/>
          </picture>
          <picture>
            <source srcSet={mentionsLogos.hackernoon} type="image/webp"/>
            <img src={mentionsLogos.hackernoon} alt="HackerNoon" className="max-w-[9rem]" loading="lazy"/>
          </picture>
          <picture>
            <source srcSet={mentionsLogos.techtudo} type="image/webp"/>
            <img src={mentionsLogos.techtudo} alt="Techtudo" className="max-w-[9rem]" loading="lazy"/>
          </picture>
          <picture>
            <source srcSet={mentionsLogos.exame} type="image/webp"/>
            <img src={mentionsLogos.exame} alt="Exame" className="max-w-[9rem]" loading="lazy"/>
          </picture>
        </div>
      </div>

    </div>
  );
}

export default TalkingAbout2;
