import { RangeSlider } from 'flowbite-react';
import { Flowbite } from 'flowbite-react';
import { useEffect } from 'react';
import { useScreenContext } from '../Layout';
import { useTranslation } from 'react-i18next';

const customTheme = {
    rangeSlider: {
        field: {
            input: {
                base: 'w-full bg-gradient-to-r from-red-500 via-yellow-500 via-yellow-300 to-green-500 rounded-lg appearance-none cursor-pointer',
            },
        },
    }

};

function ReaderProfile() {
    const { t, i18n } = useTranslation();
    const [screenData, setScreenData] = useScreenContext();
    
    useEffect(() => {
        setScreenData(() => ({
          ...screenData,
          submitLabel: t("layout.cta_continue"),
          nextPath: "development-plan",
          progress: 100,
        }));
      }, []);

  return (
    <div>
        <h1 className="mb-4 mt-5 text-4xl font-bold tracking-tight text-blue-600  text-gray-900  md:text-5xl lg:text-6xl">
            Análise do seu perfil de aprendizado
        </h1>
        <div className="rounded-lg bg-blue-100 p-5">
            <div>
                <Flowbite theme={{ theme: customTheme }}>
                    <RangeSlider disabled id="default-range" value={55} />
                </Flowbite>
                <div className="flex">
                    <div className="w-1/3 text-left">Baixo</div>
                    <div className="w-1/3 text-center">Intermediário</div>
                    <div className="w-1/3 text-right">Alto</div>
                </div>
            </div>
            <div>
                <div className="text-sm font-thin mt-2" >&#9888; Não é uma pontuação ruim, mas pode melhorar!</div>
                <div className="bg-slate-50 p-2 rounded-md mt-5" >
                    <div>De acordo com o <b>Statista</b>, pessoas do Top 1% de renda no Brasil lêem 1 livro por semana.</div>
                </div>
            </div>
            <div>
                <div className="grid grid-cols-2 gap-2 text-md mt-5">
                    <div className="flex text-left bg-blue-50 rounded-md" >
                        <div className="w-1/5 text-center text-lg">
                            <span className="pt-1 block" >🎯</span>
                        </div>
                        <div className="w-4/5 text-sm">
                            <div>Motivação</div>
                            <b>Alta</b>
                        </div>
                    </div>
                    <div className="flex text-left bg-blue-50 rounded-md" >
                        <div className="w-1/5 text-center text-lg">
                            <span className="pt-1 block" >⭐</span>
                        </div>
                        <div className="w-4/5 text-sm">
                            <div>Renda Potencial</div>
                            <b>Alta</b>
                        </div>
                    </div>
                    <div className="flex text-left bg-blue-50 rounded-md" >
                        <div className="w-1/5 text-center text-lg">
                            <span className="pt-1 block" >⏳</span>
                        </div>
                        <div className="w-4/5 text-sm">
                            <div>Tempo Disponível</div>
                            <b>Baixo</b>
                        </div>
                    </div>
                    <div className="flex text-left bg-blue-50 rounded-md" >
                        <div className="w-1/5 text-center text-lg">
                            <span className="pt-1 block" >📚</span>
                        </div>
                        <div className="w-4/5 text-sm">
                            <div>Conhecimento</div>
                            <b>Intermediário</b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default ReaderProfile;
