import { useEffect } from "react";
import { useScreenContext } from "../Layout";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import * as rudderanalytics from "../../lib/rudderstack";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

const Star = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className="inline-block h-4 w-4"
  >
    <path
      fillRule="evenodd"
      d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
      clipRule="evenodd"
    />
  </svg>
);

const buildLink = (
  isDirectSale: boolean,
  isRetSale: boolean,
  isAppFunnel: boolean,
  code: string,
  productId: string = "",
  anonymousId: string,
  hidePix: any,
  hideBillet: any
) => {
  return (isDirectSale || isRetSale || isAppFunnel)
    ? `https://pay.hotmart.com/${productId}?off=${code}&sck=${anonymousId}&checkoutMode=10${
        hidePix ? "&hidePix=1" : ""
      }${hideBillet ? "&hideBillet=1" : ""}`
    : `https://pay.hotmart.com/${productId}?off=${code}&sck=${anonymousId}&hidePix=1&hideBillet=1&checkoutMode=10`;
};

function Landing() {
  const { t } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const [searchParams] = useSearchParams();
  const { offerType } = useParams();
  const newLifetimeCheckout = useFeatureIsOn("lifetime-checkout-feature");

  const isDirectSale = offerType === "direct";
  const isRetSale = offerType === "ret";
  const isAppFunnel = offerType === "app";

  const offerCode = searchParams.get("code");
  const headline = searchParams.get("headline");
  const subheadline = searchParams.get("subheadline");
  const fromPrice = searchParams.get("fromPrice");
  const toPrice = searchParams.get("toPrice");
  const save = searchParams.get("save");
  const productId = searchParams.get("productId");
  const currentCluster = searchParams.get("cluster");
  const hidePix = searchParams.get("hidePix");
  const hideBillet = searchParams.get("hideBillet");
  const token = searchParams.get("token");

  const anonymousId = rudderanalytics.getAnonymousId();

  const checkoutUrl = newLifetimeCheckout
    ? `https://checkout.12min.com/?tk=${token}&plan=lifetime&offer=93f`
    : buildLink(
        isDirectSale,
        isRetSale,
        isAppFunnel,
        offerCode || "03yukzfr",
        productId || "P5327674P",
        anonymousId,
        hidePix,
        hideBillet
      );

  const clusters = [
    "entrepreneurship",
    "emotional_intelligence",
    "corporate_ladder",
    "new_facts",
  ];

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: false,
      nextPath: "book-suggestion/1",
      progress: 100,
    }));
  }, []);

  return (
    <div className="mb-20">
      <div className="bg-red-100 p-5">
        <div className="mb-3 inline-block rounded-md bg-red-600 p-2 text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="mr-1 inline-block h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          {t("landing.badge")}
        </div>
        <h1 className="my-5 text-4xl font-semibold leading-tight text-red-600">
          {headline || t("landing.headline")}
        </h1>
        <p>{subheadline || t("landing.subheadline")}</p>

        <div className={"mt-10 border-4 border-blue-700 text-gray-900"}>
          <div className=" bg-gray-100 p-4 py-6">
            <div>
              <div className=" -mt-10 mb-2 w-28  bg-blue-700 py-1 text-center text-xs text-white">
                {t("landing.save_text")} {save || t("landing.save_offer")}
              </div>
              <b className="mt-5 block text-lg">12MIN PREMIUM</b>
              <div className="py-2">
                <span className="mr-2 inline-block font-light text-red-600 line-through">
                  {fromPrice || t("landing.old_price")}
                </span>
                <b className="inline-block text-xl">
                  {toPrice || t("landing.new_price")}
                </b>
              </div>
              <b className="block text-sm text-blue-700">
                {t(
                  `select_plan.yearly.${
                    (isDirectSale || isRetSale || isAppFunnel) ? "direct_sale_" : ""
                  }free_trial`
                )}
              </b>

              <Link
                to={checkoutUrl}
                onClick={() => rudderanalytics.checkoutStarted()}
              >
                <button
                  type="button"
                  className="text-md mt-5 w-full rounded-md bg-blue-700 px-5 py-2.5 font-normal text-white hover:bg-blue-800 focus:ring-blue-300"
                >
                  {t(
                    (isDirectSale || isRetSale || isAppFunnel)
                      ? "book_list.get_offer"
                      : "book_list.start_free_trial"
                  )}
                </button>
              </Link>
            </div>
          </div>

          <div className="bg-white p-4 text-left">
            <div className="mt-2 text-left text-sm">
              <div className="mb-3">
                <p className="align-middle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="inline-block h-4 w-5 text-blue-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("landing.feature_1", {
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  ></span>
                </p>
              </div>

              <div className="mb-3">
                <p className="align-middle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="inline-block h-4 w-5 text-blue-700 "
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("landing.feature_2", {
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  ></span>
                </p>
              </div>

              <div className="mb-3">
                <p className="align-middle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="inline-block h-4 w-5 text-blue-700 "
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("landing.feature_3", {
                        interpolation: { escapeValue: false },
                      }),
                    }}
                  ></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {clusters.includes(currentCluster || "") && (
        <div className="py-5">
          <h1 className="mb-4 mt-5 text-2xl font-semibold leading-tight tracking-normal text-gray-900  md:text-3xl">
            {t(`landing.clusters.${currentCluster}.headline`)}
          </h1>
          <div className="text-left">
            <div className="flex pb-5">
              <img
                width={60}
                height={60}
                className="mr-3"
                src={require(`../../assets/images/${currentCluster}-01.png`)}
              />
              <div className="m-auto">
                <b className="text-blue-700">
                  {t(`landing.clusters.${currentCluster}.bullet_01.headline`)}
                </b>
                <p>
                  {t(
                    `landing.clusters.${currentCluster}.bullet_01.subheadline`
                  )}
                </p>
              </div>
            </div>
            <div className="flex pb-5">
              <img
                width={60}
                height={60}
                className="mr-3"
                src={require(`../../assets/images/${currentCluster}-02.png`)}
              />
              <div className="m-auto">
                <b className="text-blue-700">
                  {t(`landing.clusters.${currentCluster}.bullet_02.headline`)}
                </b>
                <p>
                  {t(
                    `landing.clusters.${currentCluster}.bullet_01.subheadline`
                  )}
                </p>
              </div>
            </div>
            <div className="flex pb-5">
              <img
                width={60}
                height={60}
                className="mr-3"
                src={require(`../../assets/images/${currentCluster}-03.png`)}
              />
              <div className="m-auto">
                <b className="text-blue-700">
                  {t(`landing.clusters.${currentCluster}.bullet_03.headline`)}
                </b>
                <p>
                  {t(
                    `landing.clusters.${currentCluster}.bullet_01.subheadline`
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      <h1 className="mb-4 mt-5 text-3xl font-semibold leading-tight tracking-normal text-gray-900  md:text-4xl">
        {t("book_list.reviews.headline")}
      </h1>
      <div className="mb-5 rounded-md bg-amber-100 p-5 text-left">
        <p>{t("book_list.reviews.review_1.name")}</p>
        <div className="my-1">
          {[1, 2, 3, 4, 5].map((i) => (
            <Star />
          ))}
        </div>
        <p>{t("book_list.reviews.review_1.text")}</p>
      </div>
      <div className="mb-5 rounded-md bg-amber-100 p-5 text-left">
        <p>{t("book_list.reviews.review_2.name")}</p>
        <div className="my-1">
          {[1, 2, 3, 4, 5].map((i) => (
            <Star />
          ))}
        </div>
        <p>{t("book_list.reviews.review_2.text")} </p>
      </div>
      <div className="mb-5 rounded-md bg-amber-100 p-5 text-left">
        <p>{t("book_list.reviews.review_3.name")}</p>
        <div className="my-1">
          {[1, 2, 3, 4, 5].map((i) => (
            <Star />
          ))}
        </div>
        <p>{t("book_list.reviews.review_3.text")}</p>
      </div>
      <Link to={checkoutUrl}>
        <button
          type="button"
          className="text-md my-6 w-full rounded-md bg-blue-700 px-5 py-2.5 font-normal text-white hover:bg-blue-800 focus:ring-blue-300"
        >
          {t(
            (isDirectSale || isRetSale || isAppFunnel) ? "book_list.get_offer" : "book_list.start_free_trial"
          )}
        </button>
      </Link>
      <h1 className="my-10 text-3xl font-semibold leading-tight tracking-normal text-gray-900  md:text-4xl">
        {t("book_list.statistics.headline")}
      </h1>
      <div className="mb-5 rounded-md bg-blue-100 p-8">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="inline-block h-10 w-10"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0"
          />
        </svg>
        <h2 className="my-4 text-3xl font-bold leading-tight tracking-normal text-gray-900  md:text-4xl">
          {t("book_list.statistics.block_1.headline")}
        </h2>
        <p>{t("book_list.statistics.block_1.text")}</p>
      </div>
      <div className="mb-5 rounded-md bg-blue-100 p-8">
        <div className="my-1">
          {[1, 2, 3, 4, 5].map((i) => (
            <Star />
          ))}
        </div>
        <h2 className="my-4 text-3xl font-bold leading-tight tracking-normal text-gray-900  md:text-4xl">
          {t("book_list.statistics.block_2.headline")}
        </h2>
        <p>{t("book_list.statistics.block_2.text")}</p>
      </div>
      <div className="mb-5 rounded-md bg-blue-100 p-8">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="inline-block h-10 w-10"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
          />
        </svg>

        <h2 className="my-4 text-3xl font-bold leading-tight tracking-normal text-gray-900  md:text-4xl">
          {t("book_list.statistics.block_3.headline")}
        </h2>
        <p>{t("book_list.statistics.block_3.text")}</p>
      </div>
      <p className="text-sm text-gray-400">{t("book_list.statistics.info")}</p>
      <Link to={checkoutUrl}>
        <button
          type="button"
          className="text-md my-6 w-full rounded-md bg-blue-700 px-5 py-2.5 font-normal text-white hover:bg-blue-800 focus:ring-blue-300"
        >
          {t(
            (isDirectSale || isRetSale || isAppFunnel) ? "book_list.get_offer" : "book_list.start_free_trial"
          )}
        </button>
      </Link>
      <div className="my-10 text-left">
        <p className="mb-2 text-xl font-bold leading-tight tracking-normal text-gray-500">
          {t("book_list.feed_your_brain.headline")}
        </p>
        <p className="mb-2 text-xl font-bold leading-tight tracking-normal text-gray-900">
          {t("book_list.feed_your_brain.drive")}
        </p>
        <p className="mb-2 text-xl font-bold leading-tight tracking-normal text-gray-900">
          {t("book_list.feed_your_brain.work")}
        </p>
        <p className="mb-2 text-xl font-bold leading-tight tracking-normal text-gray-900">
          {t("book_list.feed_your_brain.house")}
        </p>
        <p className="mb-2 text-xl font-bold leading-tight tracking-normal text-gray-900">
          {t("book_list.feed_your_brain.workout")}
        </p>
        <p className="mb-2 text-xl font-bold leading-tight tracking-normal text-gray-900">
          {t("book_list.feed_your_brain.chill")}
        </p>
        <Link to={checkoutUrl}>
          <button
            type="button"
            className="text-md my-6 w-full rounded-md bg-blue-700 px-5 py-2.5 font-normal text-white hover:bg-blue-800 focus:ring-blue-300"
          >
            {t(
              (isDirectSale || isRetSale || isAppFunnel)
                ? "book_list.get_offer"
                : "book_list.start_free_trial"
            )}
          </button>
        </Link>
      </div>
      <h1 className="my-10 text-3xl font-semibold leading-tight tracking-normal text-gray-900  md:text-4xl">
        {t("book_list.features.headline")}
      </h1>
      <div className="flex flex-row py-2">
        <div className="basis-1/5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="m-auto mt-3 h-10 w-10"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"
            />
          </svg>
        </div>
        <div className="book-info basis-4/5 text-left ">
          <p className="font-bold">
            {t("book_list.features.block_1.headline")}
          </p>
          <p className="">{t("book_list.features.block_1.text")}</p>
        </div>
      </div>
      <div className="flex flex-row py-2">
        <div className="basis-1/5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="m-auto mt-3 h-10 w-10"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"
            />
          </svg>
        </div>
        <div className="book-info basis-4/5 text-left ">
          <p className="font-bold">
            {t("book_list.features.block_2.headline")}
          </p>
          <p className="">{t("book_list.features.block_2.text")}</p>
        </div>
      </div>
      <div className="flex flex-row py-2">
        <div className="basis-1/5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="m-auto mt-3 h-10 w-10"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
            />
          </svg>
        </div>
        <div className="book-info basis-4/5 text-left ">
          <p className="font-bold">
            {t("book_list.features.block_3.headline")}
          </p>
          <p className="">{t("book_list.features.block_3.text")}</p>
        </div>
      </div>
      <Link to={checkoutUrl}>
        <button
          type="button"
          className="text-md my-10 w-full rounded-md bg-blue-700 px-5 py-2.5 font-normal text-white hover:bg-blue-800 focus:ring-blue-300"
        >
          {t(
            (isDirectSale || isRetSale || isAppFunnel) ? "book_list.get_offer" : "book_list.start_free_trial"
          )}
        </button>
      </Link>
    </div>
  );
}

export default Landing;
