import { useEffect, useState } from "react";
import { useScreenContext } from "../Layout";
import { useTranslation } from "react-i18next";
import data from '../../data.json';

function ReadingList() {
  const { t } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const [selectedBooks, setSelectedBooks] = useState<any>([])

  useEffect(() => {
    setScreenData(() => ({
      ...screenData,
      submitLabel: t("layout.cta_continue", {
        interpolation: { escapeValue: false },
      }),
      nextPath:  "usage",
      progress: 7,
    }));
  }, []);

  const handleBookClick = (currentId: any) => {
    if(!selectedBooks.includes(currentId)) {
        setSelectedBooks([...selectedBooks, currentId])
    } else {
        setSelectedBooks(selectedBooks.filter((id: any) => id != currentId))
    }
  }
  
  return (
    <div>
      <h1 className="mb-4 mt-5 text-3xl font-bold tracking-tight text-gray-900  md:text-5xl lg:text-6xl">
        {t("start.headline")}
      </h1>
      <p className="mb-5 " >
        <b>Cada pessoa é única.</b> Queremos <b>personalizar</b> a sua experiência de <b>aprendizagem</b>. Por favor, selecione os livros que mais lhe interessam:
      </p>
      <div className="container">
      <div className="mx-auto max-w-sm px-2 mb-20">
            {data.suggestionBooks.br.map((book: any) => (
              <div key={book.id} onClick={() => handleBookClick(book.id)} className="flex flex-row py-4 bg-slate-200 mb-2 rounded-sm block hover:opacity-75">
                <div className="basis-1/5 mx-3">
                  <img
                    className="float-left"
                    alt={book.title}
                    src={book.coverimage}
                  />
                </div>
                <div className="book-info basis-4/5 text-left mr-2">
                  <span className="text-xs" >🎧 {book.reading_time}min</span>
                  <p className="font-bold">{book.title}</p>
                  <p className="text-sm font-semibold text-slate-700">{book.author}</p>
                  <p className="text-sm text-slate-700">{book.category}</p>
                </div>
                <div className="flex items-center" >
                    <div className="rounded-full text-white p-2"  >
                        { !selectedBooks.includes(book.id) ? (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-7 h-7 text-blue-800">
                                <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z" clipRule="evenodd" />
                            </svg>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-7 h-7 text-blue-400">
                                <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm3 10.5a.75.75 0 000-1.5H9a.75.75 0 000 1.5h6z" clipRule="evenodd" />
                            </svg>
                        ) }
                    </div>
                </div>
              </div>
            ))}
          </div>
      </div>
    </div>
  );
}

export default ReadingList;


