import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Funnel from "../../assets/images/funnel.png";
import { useScreenContext } from "../Layout";

function DevelopmentPlan() {
  const { t } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();

  useEffect(() => {
    setScreenData({
      ...screenData,
      submitLabel: 'Começar agora!',
      nextPath: "select-plan",
      progress: 100,
    });
  }, []);

  return (
    <div>
      <h1 className="mx-5 mb-4 mt-5 text-2xl font-bold tracking-tight text-gray-900  md:text-4xl">
        Plano de desenvolvimento pessoal
      </h1>
      <p>Baseado nas suas preferências e no seu perfil de aprendizado, você pode se tornar...</p>
      <div className="font-bold text-lg py-2" >Leitor Assíduo até 22 de abril</div>
      <div className="bg-slate-200 inline-block p-2 rounded-md" >Sua meta: 3 livros por semana</div>
      <img
        className="pt-10"
        alt="Chart showing your progress"
        src={Funnel}
      />
    </div>
  );
}

export default DevelopmentPlan;
