export function getCurrentLocale() {
  const language =
    localStorage.getItem("i18nextLng") === "br"
      ? "pt"
      : localStorage.getItem("i18nextLng");

  return language || "pt";
}

export async function getCategories() {
  const language = getCurrentLocale();

  return fetch("https://api.12min.com/api/v1/categories", {
    headers: {
      "Accept-Language": language,
    },
  });
}

export async function getRecommendations(categoriesId: Array<number> = []) {
  const language = getCurrentLocale();

  var headers = new Headers();
  headers.append("Accept-Language", language);
  headers.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    categories: categoriesId,
  });

  return fetch("https://api.12min.com/api/v1/book-recommendations", {
    method: "POST",
    headers: headers,
    body: raw,
    redirect: "follow",
  });
}
