import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { relativePath, useScreenContext, useStepNumberManager } from "../Layout";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { useParams } from "react-router-dom";

function SelectChallenge() {
  const { t, i18n } = useTranslation();
  const [screenData, setScreenData] = useScreenContext();
  const { offerType } = useParams();

  const mentionsLogos = {
    techcrunch: require("../../assets/images/mentions/tech-crunch.png"),
    forbes: require("../../assets/images/mentions/forbes.png"),
    muo: require("../../assets/images/mentions/muo.png"),
    entrepreneur: require("../../assets/images/mentions/entrepreneur.png"),
    yahoo: require("../../assets/images/mentions/yahoo.png"),
    hackernoon: require("../../assets/images/mentions/hackernoon.png"),
  };

  const exp20feature = useFeatureIsOn("exp-20-feature");
  const exp29feature = useFeatureIsOn("exp-29-product");
  const exp30feature = useFeatureIsOn("exp-30-feature");
  const exp38ptTwoOffers = useFeatureIsOn("exp-38-pt-two-offers");
  const exp43TwoOffersYt = useFeatureIsOn("exp-43-two-offers-yt");

  const isAppFunnel = offerType === "app" || offerType === "appf";

  const expSelectPlanQuarterly = exp38ptTwoOffers || exp43TwoOffersYt ? "select-plan-popup" : "select-plan";

  const selectPlanPath = exp29feature ? relativePath("/product", offerType) : expSelectPlanQuarterly;
  const newLocal = isAppFunnel ? "app-install" : selectPlanPath;

  const stepNumber = useStepNumberManager(23);


  useEffect(() => {

    setScreenData(() => ({
      ...screenData,
      submitLabel: t("layout.cta_continue"),
      nextPath: newLocal,
      progress: stepNumber,
      step: stepNumber,
    }));
  }, [exp29feature]);

  return (
    <div className="rounded-lg bg-blue-100 py-5 mb-[4rem]">
      <h1 className="mb-4 mt-5 text-4xl font-bold tracking-tight text-blue-600 text-gray-900  md:text-5xl lg:text-6xl">
        {t("talking_about.headline")}
      </h1>
      <p className="text-lg font-medium">{t("talking_about.subheadline")}</p>
      <div className="mx-5 mt-10 rounded-lg bg-white p-5 text-2xl">
        {t("talking_about.text_box")}
        <img
          className="inline-block max-w-[60%] px-5 py-2"
          src={mentionsLogos.forbes}
          alt="Forbes"
        />
      </div>
      <p className="py-5 font-medium">{t("talking_about.also_featured")}</p>
      <div>
        <img
          className="inline-block max-w-[40%] px-5 py-2"
          src={mentionsLogos.techcrunch}
          alt="TechCrunch"
        />
        <img
          className="inline-block max-w-[40%] px-5 py-2"
          src={mentionsLogos.muo}
          alt="Make Use Of"
        />
        <img
          className="inline-block max-w-[40%] px-5 py-2"
          src={mentionsLogos.entrepreneur}
          alt="Entrepreneur"
        />
        <img
          className="inline-block max-w-[40%] px-5 py-2"
          src={mentionsLogos.yahoo}
          alt="Yahoo"
        />
        <img
          className="inline-block max-w-[40%] px-5 py-2"
          src={mentionsLogos.hackernoon}
          alt="HackerNoon"
        />
      </div>
    </div>
  );
}

export default SelectChallenge;
